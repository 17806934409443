<template>
    <v-dialog
            :value="value"
            @input="$emit('input')"
            :content-class="'delete_release'"
            overlay-color="#E94E4E"
            max-width="546">

        <div class="heading">
            <h2>caution!</h2>
            <button class="close_modal" @click="dialog = false"></button>
        </div>
        <div class="description">
            You are about to delete <span class="alert_text">{{ deleteRelease.title }}</span> and all of its tracks
            <span class="important_text">This cannot be undone</span>
        </div>
        <div class="list" v-if="deleteRelease">
            <div class="item"> <!--  @click="checkDeleteRelease(deleteRelease.id)" -->
                <div class="item_info">
                    <div class="item_image">
                        <img v-if="deleteRelease.smallImage" :src="deleteRelease.smallImage" alt="">
                        <div class="default_user_avatar" v-else></div>
                    </div>
                    <div class="item_description">
                        <div class="name">{{ deleteRelease.title }}</div>
                        <!-- <div class="additional">12 Tracks</div> -->
                    </div>
                </div>
                <div class="actions">
                    <!--					<div class="check_round" :tabindex="4" :class="{checked: selectedReleaseID === deleteRelease.id}"></div>-->
                </div>
            </div>

        </div>
        <div class="type_the_name">
            <span class="type_description">Type the name of the release to delete</span>
            <input type="text" placeholder="Name of the release" v-model="confirmReleaseName">
        </div>
        <div class="action_modal">
            <button type="button" class="cancel" @click="cancel" v-ripple>Cancel</button>
            <button type="button" class="delete" @click="goodbye" v-ripple v-if="deleteValidation.name === true" :class="{loading: deleteReleaseLoader}"
                    @mouseover="handleHoverDeleteBtn(true)" @mouseleave="handleHoverDeleteBtn(false)">
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                {{ deleteBtnText }}
            </button>
            <div class="delete disabled"
                 v-else>
                Delete
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "deleteRelease",
    props: ['value', 'deleteRelease'],
    data() {
        return {
            confirmReleaseName: '',
            // selectedReleaseID: '',
            deleteValidation: {
                name: false,
                // selected: false,
            },
            hoverDeleteBtn: false,
            deleteReleaseLoader: false,
        }
    },
    watch: {
        confirmReleaseName() {
            this.deleteValidation.name = this.deleteRelease.title === this.confirmReleaseName;
        },
        // selectedReleaseID() {
        // 	this.deleteValidation.selected = this.selectedReleaseID === this.deleteRelease.id;
        // },
    },
    computed: {
        deleteBtnText() {
            return this.hoverDeleteBtn === true ? "Goodbye" : "Delete";
        },
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        ...mapActions({
            deleteMusicRelease: 'DELETE_MUSIC_RELEASE',
        }),
        // checkDeleteRelease(deleteReleaseID) {
        // 	if (this.selectedReleaseID === deleteReleaseID) {
        // 		this.selectedReleaseID = ''
        // 	} else {
        // 		this.selectedReleaseID = deleteReleaseID;
        // 	}
        // },
        cancel() {
            this.dialog = false;
        },
        handleHoverDeleteBtn(s) {
            this.hoverDeleteBtn = s;
        },
        goodbye() {
            if (this.deleteValidation.name === true) { // && this.deleteValidation.selected === true
                this.deleteReleaseLoader = true;

                setTimeout(() => {
                    this.deleteReleaseLoader = false;
                    this.dialog = false;

                    this.deleteMusicRelease(this.deleteRelease.id)
                        .then(() => {
                            if (this.$router.history.current.name === 'albumDetails') {
                                this.$router.push({name: 'releaseRemoved'});
                            }
                        })
                        .catch(err => {
                            console.error(`goodbye, deleteRelease, ${err}`);
                        });

                }, 600);
            }
        },
    }
}
</script>

<style scoped>

</style>