<template>
	<li v-if="basketItemsCounter">
		<div class="wrapper_shopping_cart" v-click-outside="closeCart">
			<!--        <v-overlay class="v-overlay-transition" :value="show"></v-overlay>-->

			<button type="button" class="basket" v-ripple @click="showCart" :class="{'loading': loader}">
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
				<span class="count_in_cart">{{ basketItemsCounter }}</span>
			</button>

			<transition name="slide-y-transition">
				<div class="shopping_cart" v-if="visibleShoppingCart === true && basketList && basketList.items && basketList.items.length">
					<h2>
						Shopping Cart
					</h2>
					<div class="wrap_subtotal">
						<div class="subtotal">Subtotal: <span>${{ basketList.total }}</span></div>
						<button type="button" @click="checkOut" v-ripple :class="{'loading': loaderGetClientSecret}">
							<v-progress-circular :width="1" indeterminate></v-progress-circular>
							Check Out
						</button>
					</div>
					<div class="wrap_list">
						<div class="item" v-for="item of basketList.items" :key="item.product_id">
							<div class="left_info">
								<div class="image">
									<template v-if="item.product_type === 'music_release'">
<!--										<router-link :to="{ name: 'albumDetails', params: {id: item.product_id, name: item.title}}">-->
											<img :src="item.image" alt="" @click="redirectToAlbum(item)">
<!--										</router-link>-->
									</template>
									<template v-if="item.product_type === 'song'">
										<img :src="item.image" alt="">
									</template>
								</div>
								<div class="description">
									<template v-if="item.product_type === 'music_release'">
										<div class="name" @click="redirectToAlbum(item)">{{item.title}}</div>
<!--										<router-link :to="{ name: 'albumDetails', params: {id: item.product_id, name: item.title}}" class="name">-->
<!--											{{ item.title }}-->
<!--										</router-link>-->
									</template>
									<div class="name" v-else>{{ item.title }}</div>

									<div class="artist" v-if="item.artist && item.artist.id">
										by
										<span class="album_artist" @click="redirectToArtistPage(item.artist.id, item.artist.identifier)">
											{{ item.artist.name }}
										</span>
									</div>
								</div>
							</div>
							<div class="right_price">
								<span class="price">${{ item.price }}</span>
								<button class="remove_item" @click.stop="remove(item.product_id, item.product_type)" v-ripple>Remove</button>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</li>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
	name: "shoppingCart",
	data: () => ({
		show: false,
		loader: false,
		loaderGetClientSecret: false,
	}),

	watch: {
        updateBasket() {
            if (this.updateBasket === true) {
                this.getDataCart();
            }
        },
        updateBasketCounter() {
            if (this.updateBasketCounter === true) {
                this.getCartItemsCount();
            }
        },
	},
	computed: {
		...mapGetters({
			isAdmin: 'isAdmin',
			visibleShoppingCart: 'HEADER_SHOPPING_CART',
			basketList: 'BASKET_LIST',
			updateBasket: 'UPDATE_BASKET',
			loaderBasket: 'LOADER_BASKET',
            updateBasketCounter: 'UPDATE_BASKET_COUNTER',
            basketItemsCounter: 'BASKET_ITEMS_COUNTER'
		}),
		totalPrice() {
			let sum = 0;
			for (let i = 0; i < this.basketList.length; i++) {
				sum += this.basketList[i].price;
			}
			return sum;
		}
	},
	created() {
		this.getCartItemsCount();
	},
	methods: {
		...mapActions({
			getBasket: 'GET_BASKET',
			removeFromBasket: 'REMOVE_FROM_BASKET',
			getPurchaseClientSecret: 'GET_PURCHASE_CLIENT_SECRET',
            getBasketCount: 'GET_BASKET_COUNTER'
		}),
		getDataCart() {
			this.loader = true;
			this.getBasket()
                .then(() => {
                    this.$store.commit('SET_HEADER_SHOPPING_CART', true);
                })
				.catch(err => console.error(`Error: getDataCart, ${err}`))
				.finally(() => {
                    this.loader = false;
				})
		},
        getCartItemsCount() {
            this.loader = true;
            this.getBasketCount()
                .catch(err => console.error(`Error: getCartItemsCount, ${err}`))
                .finally(() => {
                    this.loader = false;
                })
        },
		redirectToAlbum(musicRelease) {
			// const albumTitle = musicRelease.title.trim().replace(/ /g, '-');
			this.$router.push({name: 'albumDetails', params: {name: window.toHumanFormat(musicRelease.identifier), id:musicRelease.product_id}});
		},
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		checkOut() {
			if (this.basketList.items.length === 0) return;
			this.loaderGetClientSecret = true;
			this.getPurchaseClientSecret()
				.then(() => {
					this.$router.push({name: 'checkOut'});
					this.$store.commit('SET_HEADER_SHOPPING_CART', false);
				})
				.catch(err => console.error(`Error: checkOut, ${err}`))
				.finally(() => {
					this.loaderGetClientSecret = false;
				})
		},
		remove(id, type) {
			let index = this.basketList.items.findIndex((el) => el.product_id === id);
			if (index !== -1) {
                let removedPrice = this.basketList.items[index].price;
				this.basketList.items.splice(index, 1);

				if (!this.basketList.items.length) {
					this.$store.commit('SET_HEADER_SHOPPING_CART', false);
				}

                this.basketList.total -= removedPrice;
			}
			let album = this.$store.getters['FULL_ALBUM'];
			if (album && album.id === id) {
				album.inCart = false;
			}
			if (album.playList && album.playList.length) {
				for (let track of album.playList) {
					if (track.song.id === id) {
						track.song.inCart = false;
					}
				}
			}

			let data = {
				productId: id,
				type: type
			};

			this.removeFromBasket(data).catch(err => console.error(`Error: shippingCart - remove, ${err}`));
		},
		showCart() {
            if (this.visibleShoppingCart === true) {
                this.$store.commit('SET_HEADER_SHOPPING_CART', false);
                return;
            }

            this.getDataCart();
		},
		closeCart(event) {

			if (event && (event.target.id !== 'open-header-shopping-cart' && event.target.parentNode.id !== 'open-header-shopping-cart')) {
                this.$store.commit('SET_HEADER_SHOPPING_CART', false);
            }
            // custom rule for exclude v-click-outside logic for in cart btns
            if (event && (event.target.id === 'open-header-shopping-cart' || event.target.parentNode.id === 'open-header-shopping-cart')) {
                this.showCart();
            }
		}
	}
}
</script>

<style lang="scss">
.wrapper_shopping_cart {
	//position: relative;
	display: flex;
    .basket {
        &.loading {
            background-color: rgba(255, 255, 255, 0.2);

            .v-progress-circular {
                margin: 0 auto;
            }
        }
    }
}
</style>
