
export default class MediaT {
  id: number
  url: string
  content: string
  content: Object
  prefix: string

  constructor (data) {
    if (data) {
      this.id = data.id;
      this.url = data.contentName;
      this.content = data.contentUrl;

      if (data.thumbnail) {
          this.prefix = ('artist50x50' in data.thumbnail) ? 'artist' : ('user50x50' in data.thumbnail) ? 'user' : 'recordLabel';
          this.thumbnail = data.thumbnail;
      }
    }
  }
}

