// const initialState = {
//     gridAlbumScrollID: null,
//     trackScrollID: null,
// };

export default {
    state: {
        gridAlbumScrollID: null,
        trackScrollID: null
    },
    mutations: {
        // resetState(state) {
        //     Object.assign(state, initialState);
        // },
        SET_RESET_SCROLL: (state) => {
            state.gridAlbumScrollID = null;
            state.trackScrollID = null;
        },
        SET_GRID_ALBUM_SCROLL_ID: (state, data) => {
            console.log('SET_GRID_ALBUM_SCROLL_ID', data);
            state.gridAlbumScrollID = data;
        },
        SET_TRACK_SCROLL_ID: (state, data) => {
            state.trackScrollID = data;
        }
    },
    getters: {
        GRID_ALBUM_SCROLL_ID: state => state.gridAlbumScrollID,
        TRACK_SCROLL_ID: state => state.trackScrollID
    }
}