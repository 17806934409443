export default [
    {
        path: '/my-profile',
        name: 'private-profile',
        component: () => import('@/pages/profile/private-profile'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/user/:id',
        name: 'user-profile',
        component: () => import('@/pages/profile/user-profile'),
        props: true,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
            human: true
        }
    },
    {
        path: '/artist/:name',
        name: 'artist-profile',
        component: () => import('@/pages/profile/artist-profile'),
        props: true,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
            human: true,
        }
    },
    {
        path: '/record-label/:name',
        name: 'label-profile',
        component: () => import('@/pages/profile/label-profile'),
        props: true,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
            human: true
        }
    },
]

