import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            selectedAlbum: this.album,
        }
    },
    watch: {
        afterActionDialogNeedLogin() {
            this.selectedAlbum = this.albumDetails ? this.albumDetails : this.album;
            if (this.afterActionDialogNeedLogin === false) {
                this.removeMarkAlbum(this.selectedAlbum);
            }
        }
    },
    created() {

    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
            currentAlbum: 'CURRENT_ALBUM',
            currentTrack: 'CURRENT_TRACK',
            fullAlbum: 'FULL_ALBUM',
            releasesCollection: 'RELEASES_COLLECTION',
            afterActionDialogNeedLogin: 'AFTER_ACTION_DIALOG_NEED_LOGIN'
        }),
    },
    methods: {
        ...mapActions([
            'REMOVE_ALBUM_FROM_FAVORITES'
        ]),
        // removeLikeAlbumFromCollections(albumID) {
        //     if (albumID) {
        //         for (let collection of this.releasesCollection) {
        //             let getter = this.$store.getters[collection];
        //             if (getter && getter.length) {
        //                 let albumIndex = getter.findIndex((album) => +album.id === albumID);
        //                 if (albumIndex !== -1) {
        //                     getter[albumIndex].isFavorite = false;
        //                     if (getter[albumIndex].playList && getter[albumIndex].playList.length) {
        //                         for (let i = 0; i < getter[albumIndex].playList.length; i++) {
        //                             getter[albumIndex].playList[i].song.isFavorite = false;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     } else {
        //         console.log('addLikeAlbumFromCollections need music release id', albumID);
        //     }
        // },

        removeAlbumFromFavorites(album) {
            if (this.authData.accessToken) {
                let albumID = album.id;
                this.removeMarkAlbum(album);
                this.removeSelectedAlbumsInServer(albumID);
            } else {
                this.needLoginDialog = {
                    visible: true,
                    action: 'remove_album_from_favorites'
                };
            }
        },
        removeMarkAlbum(album) {
            // console.log('removeMarkAlbum album', album);
            // console.log('removeMarkAlbum this.selectedAlbum', this.selectedAlbum);
            if (album) {
                album.isFavorite = false;
                let albumID = album.id;

                if (album.playList && album.playList.length) {
                    for (let i = 0; i < album.playList.length; i++) {
                        album.playList[i].song.isFavorite = false;
                    }
                }
                if (this.fullAlbum && this.fullAlbum.id === albumID) {
                    this.fullAlbum.isFavorite = false;
                    if (this.fullAlbum.playList && this.fullAlbum.playList.length) {
                        for (let i = 0; i < this.fullAlbum.playList.length; i++) {
                            this.fullAlbum.playList[i].song.isFavorite = false;
                        }
                    }
                }


                if (this.currentAlbum && this.currentAlbum.id === albumID) {
                    this.currentAlbum.isFavorite = false;

                    this.currentTrack.isFavorite = false;
                    for (let i = 0; i < this.currentAlbum.playList.length; i++) {
                        this.currentAlbum.playList[i].song.isFavorite = false;
                    }
                }
            }
        },
        removeSelectedAlbumsInServer(albumID) {
            this.$store.commit('SET_REFRESH_RELEASES', true);
            this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
            this.REMOVE_ALBUM_FROM_FAVORITES({'music_release_id': albumID})
                .catch(err => console.log(`removeAlbumFromFavorites, ${err}`))
        }
    }
}