import axios from 'axios';

const initialState = () => ({
    // album
    album: {},
    editAlbumID: '',
    albumLoading: false,

    // favorite albums
    favoriteAlbums: [],
    loadedFavoriteAlbums: [],
    favoriteAlbumList: [],

    // other
    showColumns: [
        'title',
        'artist',
        'released'
    ],
    viewAlbums: 'grid',

    albumExpanded: '',
});

export default {
    state: initialState(),
    mutations: {  // sync
        resetState(state) {
            Object.assign(state, initialState());
        },
        // ALBUM
        SET_ALBUM_TO_STATE: (state, album) => {
            state.album = album;
        },
        SET_ALBUM_LOADING: (state, data) => {
            state.albumLoading = data;
        },


        SET_SHOW_COLUMNS: (state, columns) => {
            state.showColumns = columns;
        },
        SET_VIEW_ALBUMS: (state, view) => {
          state.viewAlbums = view;
        },
        SET_ALBUM_EXPANDED: (state, albumID) => {
            state.albumExpanded = albumID;
        },
    },
    actions: { // async
        // ALBUM
        GET_ALBUM({commit}, id) {
            commit('SET_ALBUM_LOADING', true);
            return axios.get(`music_releases/${id}`)
                .then((album) => {
                    commit('SET_ALBUM_TO_STATE', album.data);
                })
                .catch((error) => {
                    console.log(`GET_ALBUM ${error}`);
                })
                .finally(() => {
                    commit('SET_ALBUM_LOADING', false);
                })
        },
        GET_MUSIC_RELEASE_BY_TITLE({commit}, title) {
            commit('SET_ALBUM_LOADING', true);
            return axios.get(`music_releases/${title}`)
                .then((album) => {
                    commit('SET_ALBUM_TO_STATE', album.data);
                })
                .catch(error => console.log(`GET_ALBUM ${error}`))
                .finally(() => commit('SET_ALBUM_LOADING', false));
        },
        CHANGE_ALBUM_TAGS({commit}, params) {
            return axios.patch(`music_releases/${params.id}`,  params, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then(() => {
                    console.log('CHANGE_ALBUM_TAGS - success');
                })
                .catch(err => console.log(`CHANGE_ALBUM_TAGS ${err}`))
        },

    },
    getters: {
        // ALBUM
        ALBUM: state => state.album,
        ALBUM_LOADING: state => state.albumLoading,

        // other
        SHOW_COLUMNS: state => state.showColumns,
        VIEW_ALBUMS: state => state.viewAlbums,
        ALBUM_EXPANDED: state => state.albumExpanded,

    }
}
