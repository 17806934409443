<template>
	<exploreLayout>
	  <div class="explore">
		<div class="head_banner">
		  <v-container full-height class="full-height">
			<h3 v-if="!subgenre">explore psychedelic trance</h3>
			<h3 v-else>{{ SUBGENRE.name }}</h3>
			<nav class="explore_nav" ref="explore_nav" id="explore-nav-id">
			  <ul>
				<li>
				  <button type="button" @click="selectSubGenre({})" :class="{'active': !SUBGENRE}">All</button>
				</li>
				<li v-for="item in SUBGENRES" :key="item.id" :class="{'active': SUBGENRE.name === item.name}" ref="subgenrePosition">
				  <button type="button" @click="selectSubGenre(item)">{{ item.name }}</button>
				</li>
				<div class="animation_active_li" :style="{left: activeSubgenrePosition + 'px', width: activeSubgenreWidth + 'px'}"
					 :class="SUBGENRE">
				</div>
			  </ul>
			</nav>
		  </v-container>
		</div>
		<div class="container full_page">
		  <template v-if="mobile">
			<albumsNavMobile v-if="AUTH_DATA.accessToken"></albumsNavMobile>
			<albumsFilterMobile :actions="false" :subgenreFilter="'dropdown'" :keysFilter="true" :filterName="'explore'"></albumsFilterMobile>
			<albumsList :whatAlbums="'all'" v-if="showAlbumsOrTracks === 'albums'"></albumsList>
			<trackList v-if="showAlbumsOrTracks === 'tracks'"></trackList>
		  </template>
		  <template v-else>
			<albumsNav></albumsNav>
			<albumsFilter :actions="false" :subgenreFilter="'dropdown'" :keysFilter="true" :filterName="'explore'"></albumsFilter>
			<albumsList :whatAlbums="'all'" v-if="showAlbumsOrTracks === 'albums'"></albumsList>
			<trackList v-if="showAlbumsOrTracks === 'tracks'"></trackList>
			<googleAdsense></googleAdsense>
		  </template>
		</div>
	  </div>
	</exploreLayout>
  </template>
  
  <script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import exploreLayout from "@/layouts/exploreLayout.vue";
  import albumsNav from '@/components/musicReleases/albumsNav';
  import albumsFilter from '@/components/musicReleases/albumsFilter';
  import albumsList from "@/components/musicReleases/albumsList";
  const trackList = () => import('@/components/musicReleases/trackList');
  import googleAdsense from "@/components/googleAdsense";
  
  // mobile
  import mainSubgenres from "@/components/mobile/mainSubgenres";
  import isMobile from "@/mixins/isMobile";
  import albumsNavMobile from '@/components/musicReleases/albumsNavMobile';
  import albumsFilterMobile from '@/components/musicReleases/albumsFilterMobile';
  
  export default {
	name: 'explore',
	props: ['subgenre'],
	components: {
	  exploreLayout,
	  albumsNav,
	  albumsNavMobile,
	  albumsFilterMobile,
	  albumsFilter,
	  albumsList,
	  trackList,
	  googleAdsense,
	  mainSubgenres,
	},
	mixins: [isMobile],
	data: () => ({
	  showAlbumsOrTracks: 'albums',
	  activeSubgenrePosition: '',
	  activeSubgenreWidth: '',
	  mobileSubgenresLoading: false,
	  ogTitle: 'Psychurch',
	  ogDescription: 'Psychurch - Psychedelic Trance Music',
	  dynamicKey: 0,
	}),
	metaInfo() {
	  return {
		title: 'Psychurch',
		meta: [
		  { property: 'og:title', content: this.ogTitle },
		  { property: 'og:description', content: this.ogDescription },
		  { name: 'description', content: this.ogDescription },
		  { property: 'og:image', content: 'https://dev.edmverse.com/img/facebook/open_gaph_img.png.png' },
		  { property: 'og:site_name', content: 'Psychurch' },
		  { property: 'og:type', content: 'website' },
		  { name: 'robots', content: 'index' }
		]
	  }
	},
	watch: {
	  BPM() {
		console.log('[Watch] BPM changed:', this.BPM);
		this.visibleAlbumsOrTracks();
	  },
	  APPLY_KEYS() {
		console.log('[Watch] APPLY_KEYS changed:', this.APPLY_KEYS);
		this.visibleAlbumsOrTracks();
	  },
	  SUBGENRE() {
		console.log('[Watch] SUBGENRE changed:', this.SUBGENRE);
		if (this.SUBGENRES.length) {
		  this.$nextTick(() => {
			this.activeSubgenre();
		  });
		}
	  },
	  '$route' (to, from) {
		console.log(`[Watch] Route changed from ${from.fullPath} to ${to.fullPath}`);
		if (to.fullPath === '/') {
		  console.log('[Watch] Updating SUBGENRE to empty object');
		  this.$store.dispatch('UPDATE_SUBGENRE', {});
		} else if (to.name === 'favorites') {
		  console.log('[Watch] Updating UPPER_FILTER to favorite');
		  this.$store.commit('SET_UPPER_FILTER', 'favorite');
		}
	  },
	  UPPER_FILTER(newValue, oldValue) {
		console.log(`[Watch] UPPER_FILTER changed from ${oldValue} to ${newValue}`);
		if (newValue !== oldValue) {
		  console.log('[Watch] Resetting GRID_ALBUM_SCROLL_ID due to UPPER_FILTER change');
		  this.SET_GRID_ALBUM_SCROLL_ID(null);
		}
	  },
	},
	computed: {
	  ...mapGetters([
		'PLAYING',
		'SEARCH_STRING',
		'AUTH_DATA',
		'STATUS_FILTER',
		'COUNT_MUSIC_RELEASE', 'MUSIC_RELEASE', 'ALBUMS_CURRENT_PAGE',
		'SUBGENRE', 'BPM', 'KEYS', 'APPLY_KEYS', 'TAGS', 'ACTIVE_SAVED_FILTER', 'UPPER_FILTER',
		'ORDER_BY',
		'VIEW_ALBUMS', 'ALBUM_EXPANDED', 'SAVED_FILTERS', 'SUBGENRES',
		'CURRENT_PAGE', 'GRID_ALBUM_SCROLL_ID'
	  ])
	},
	mounted() {
	  console.log('[Lifecycle] explore component mounted');
	  this.visibleAlbumsOrTracks();
	  if (this.$route.name === 'favorites') {
		console.log('[Mounted] Favorites route detected, setting UPPER_FILTER');
		this.$store.commit('SET_UPPER_FILTER', 'favorite');
	  } else if (!this.SUBGENRES.length) {
		console.log('[Mounted] No subgenres, fetching subgenres');
		this.getSubgenres();
	  } else {
		console.log('[Mounted] Subgenres exist, activating subgenre');
		this.activeSubgenre();
		if (this.SUBGENRE) {
		  console.log('[Mounted] SUBGENRE exists, selecting subgenre');
		  this.selectSubGenre(this.SUBGENRE);
		}
	  }
	},
	methods: {
	  ...mapActions(['GET_MUSIC_RELEASE', 'CHANGE_FILTERS', 'GET_SUBGENRES', 'UPDATE_UPPER_FILTER']),
	  ...mapMutations(['SET_GRID_ALBUM_SCROLL_ID']),
	  visibleAlbumsOrTracks() {
		console.log('[Method] visibleAlbumsOrTracks called');
		if (this.BPM.length || this.APPLY_KEYS.length) {
		  console.log('[Method] Showing tracks');
		  this.showAlbumsOrTracks = 'tracks';
		} else if (!this.BPM.length && !this.APPLY_KEYS.length) {
		  console.log('[Method] Showing albums');
		  this.showAlbumsOrTracks = 'albums';
		}
	  },
	  selectSubGenre(item) {
		console.log(`[Method] selectSubGenre called with item:`, item);
		
		if (item === 'favorite') {
		  console.log('[Method] Favorite selected, updating UPPER_FILTER');
		  this.UPDATE_UPPER_FILTER('favorite');
		  this.navigateTo('/favorites');
		} else {
		  this.$store.commit('SET_SUBGENRE', item);
		  if (item.name) {
			const subgenre = item.name.trim().replace(/ /g, '-');
			console.log(`[Method] Navigating to subgenre: ${subgenre}`);
			this.navigateTo(`/explore/${subgenre}`);
		  } else {
			console.log('[Method] Navigating to root');
			this.navigateTo('/');
		  }
		}
		this.getSubgenres();
	  },
	  navigateTo(path) {
		if (this.$route.path !== path) {
		  this.$router.replace({ path }).catch(err => {
			if (err.name !== 'NavigationDuplicated') {
			  throw err;
			}
		  });
		}
	  },
	  getSubgenres() {
		console.log('[Method] getSubgenres called');
		this.mobileSubgenresLoading = true;
		const subgenresParams = {
		  genre: 2,
		  'order[musicReleasesCount]': 'DESC'
		}
		this.GET_SUBGENRES(subgenresParams)
		  .then(() => {
			console.log('[Method] Subgenres fetched successfully');
			this.dynamicKey += 1;
			if (this.SUBGENRES.length && this.subgenre) {
			  const subgenre = this.SUBGENRES.find(el => el.name.trim().replace(/ /g, '-') === this.subgenre);
			  if (subgenre) {
				console.log('[Method] Setting SUBGENRE:', subgenre);
				this.$store.commit('SET_SUBGENRE', subgenre);
			  } else {
				console.log('[Method] Subgenre not found, setting empty SUBGENRE');
				this.$store.commit('SET_SUBGENRE', {});
				this.navigateTo('/');
			  }
			  this.activeSubgenre();
			}
		  })
		  .catch(err => console.error(`[Method] GET_SUBGENRES error:`, err))
		  .finally(() => {
			console.log('[Method] getSubgenres finished');
			this.mobileSubgenresLoading = false;
		  });
	  },
	  activeSubgenre() {
		console.log('[Method] activeSubgenre called');
		if (!this.mobile) {
		  this.$nextTick(() => {
			console.log('[Method] $nextTick callback for activeSubgenre');
			let active = this.$refs['subgenrePosition'].find(el => el._prevClass === 'active');
			if (active) {
			  console.log('[Method] Active subgenre found');
			  this.activeSubgenrePosition = active.offsetLeft;
			  this.activeSubgenreWidth = active.offsetWidth;
			} else {
			  console.log('[Method] No active subgenre found, using default values');
			  this.activeSubgenrePosition = 6;
			  this.activeSubgenreWidth = 70;
			}
		  });
		}
	  }
	},
  };
  </script>
  
  <style scoped>
	/* Add any component-specific styles here */
  </style>