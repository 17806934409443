<template>
	<div>
		<!--        <v-overlay :value="mainMenu || dashboardMenu || accountMenu" @click="mainMenu = false" class="v-overlay-transition"></v-overlay>-->

		<button class="user" id="header-user-menu 2" v-ripple @click="showUserMenu" v-click-outside="closeMenu"
		        :class="{active: (PROFILE && PROFILE.photo || PROFILE && PROFILE.photo === null)}">
			<template v-if="PROFILE_LOADER">
				<v-skeleton-loader
					class="mx-auto border skeleton-zoom"
					max-width="40"
					type="card-avatar"
				></v-skeleton-loader>
			</template>
			<template v-else-if="!PROFILE_LOADER">
				<img v-if="PROFILE_LOGO['40x40']" :src="`${PROFILE_LOGO['40x40']}?cache=${cacheKey}`" v-bind:srcset="`${PROFILE_LOGO['40x40']}?cache=${cacheKey} 1x, ${PROFILE_LOGO['40x40@2x']}?cache=${cacheKey} 2x`" alt="user avatar" draggable="false">
				<div v-else class="default_user_avatar"></div>
			</template>
		</button>

		<!--        <transition name="slide-y-transition">-->
		<div class="wrapper_user_menu" :class="{'close-menu': closeAllMenu}" v-if="HEADER_USER_MENU === true">
			<div class="user_menu" :class="{show: mainMenu, hide: !dashboardMenu && !accountMenu}">

				<div class="menu_box user_link" v-ripple @click="redirectToProfile()" v-if="!PROFILE_LOADER">
					<div class="user_ava">
            <img v-if="PROFILE_LOGO['40x40']" :src="`${PROFILE_LOGO['40x40']}?cache=${cacheKey}`" v-bind:srcset="`${PROFILE_LOGO['40x40']}?cache=${cacheKey} 1x, ${PROFILE_LOGO['40x40@2x']}?cache=${cacheKey} 2x`" alt="user avatar" draggable="false">
						<div v-else class="default_user_avatar"></div>
					</div>
					<template v-if="PROFILE && PROFILE.fullName">{{ PROFILE.fullName }}</template>
				</div>

				<div class="list_links">
					<router-link :to="{name: 'library'}" v-ripple>Music Library</router-link>
					<button type="button" class="disabled" id="events-calendar-show-coming-soon">Events Calendar</button>
					<!-- <router-link :to="{name: 'events'}" v-ripple>Events Calendar</router-link>-->
					<router-link :to="{name: 'following'}" v-ripple>Following</router-link>
				</div>

				<button type="button" class="menu_box dashboard_link" id="btn-open-dashboard" v-ripple @click="dashboardMenu = true">Dashboard</button>
				<button type="button" class="menu_box account_settings" id="btn-open-account-settings" v-ripple @click="accountMenu = true">Account Settings</button>
				<button type="button" class="menu_box account_logout" @click="logout">Log out</button>
			</div>

			<transition name="slide-right">
				<div class="inside_dashboard_menu" :class="{show: dashboardMenu, hide: !mainMenu}">
					<button type="button" @click="dashboardMenu = false; mainMenu = true;" id="btn-open-main-menu">Dashboard</button>
					<div class="menu_box list_links">
						<!-- <router-link :to="{name: 'dashboard'}" v-ripple class="i-overview">Overview</router-link>-->
						<button type="button" class="disabled i-overview" id="overview-show-coming-soon">Overview</button>
						<router-link :to="{name: 'manageFollowers'}" v-ripple class="i-followers">Followers</router-link>
						<router-link :to="{name: 'earnings'}" v-ripple class="i-earnings">Earnings</router-link>
						<router-link :to="{name: 'accounts'}" v-ripple class="i-accounts">Accounts</router-link>
						<router-link :to="{name: 'uploads'}" v-ripple class="i-uploads">Uploads</router-link>
						<router-link :to="{name: 'manageEvents'}" v-ripple class="i-events">Events</router-link>
						<router-link :to="{name: 'merch'}" v-ripple class="i-merch">Merch</router-link>
						<router-link :to="{name: 'orders'}" v-ripple class="i-orders">Orders</router-link>
						<router-link :to="{name: 'promotions'}" v-ripple class="i-promotions">Promotions</router-link>
					</div>
				</div>
			</transition>

			<transition name="slide-right">
				<div class="inside_account_settings_menu" :class="{show: accountMenu, hide: !mainMenu}"> <!--  || !accountMenu -->
					<button type="button" @click="accountMenu = false; mainMenu = true;" id="btn-open-main-menu">Account settings</button>
					<div class="menu_box list_links">
						<router-link :to="{name: 'security'}" v-ripple class="i-security">Security</router-link>
						<router-link :to="{name: 'privacy'}" v-ripple class="i-privacy">Privacy</router-link>
						<router-link :to="{name: 'notifications'}" v-ripple class="i-notifications">Notifications</router-link>
						<router-link :to="{name: 'payments'}" v-ripple class="i-payments">Payments</router-link>
						<router-link :to="{name: 'subscriptions'}" v-ripple class="i-subscriptions">Subscriptions</router-link>
					</div>
				</div>
			</transition>
		</div>
		<!--        </transition>-->

		<comingSoonDialog v-if="comingSoonDialogVisible" v-model="comingSoonDialogVisible"></comingSoonDialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import comingSoonDialog from "@/components/dialogs/comingSoonDialog";

export default {
	name: 'userMenu',
	props: ['hideMenu'],
	components: {
		comingSoonDialog
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			userAvatarDefault: false,
			comingSoonDialogVisible: false,

			closeAllMenu: true,
			mainMenu: false,
			dashboardMenu: false,
			accountMenu: false,
      cacheKey: new Date().getTime(),
		};
	},
	watch: {
		'AUTH_DATA.accessToken'() {
			console.log('watch AUTH_DATA.accessToken');
			this.GET_PROFILE();
		},
		'$route'(to, from) {
			this.closeMenu();
		},
		CHANGE_USER_AVA() {
			this.userAvatarDefault = false;
			this.GET_PROFILE().then(() => {
				this.$store.commit('SET_CHANGE_USER_AVA', false);
        this.cacheKey += 1;
			})
		},
		userMenu() {
			if (!this.userMenu || this.userMenu) {
				this.dashboardMenu = false;
				this.accountMenu = false;
			}
		},
		HEADER_USER_MENU() {
			if (this.HEADER_USER_MENU === true) {
				this.$store.commit('SET_HEADER_SHOPPING_CART', false);
			}
		}
		// MAIN_USER_MENU() {
		//     this.mainMenu = false;
		//     if (this.mainMenu === false) {
		//         this.closeAllMenu = true;
		//     }
		// }
	},
	computed: {
		...mapGetters(['userID', 'AUTH_DATA', 'PROFILE', 'PROFILE_LOADER', 'YOUR_ARTISTS', 'CHANGE_USER_AVA', 'HEADER_USER_MENU', 'PROFILE_LOGO']),
	},
	created() {

	},
	mounted() {
		// if (this.PROFILE_LOADER === false && (this.PROFILE.id && this.PROFILE.photo === null)) {
		//     this.userAvatarDefault = true;
		// }
		// console.log('userAvatarDefault', this.userAvatarDefault);
	},
	methods: {
		...mapActions(['GET_PROFILE', 'GET_YOUR_ARTISTS']),

		redirectToProfile() {
			if (this.$router.history.current.name !== 'private-profile') {
				this.$router.push({name: 'private-profile'});
			}
		},

		showUserMenu() {
			this.$store.commit('SET_HEADER_USER_MENU', true);
			this.mainMenu = !this.mainMenu;
			this.closeAllMenu = false;
			if (this.accountMenu || this.dashboardMenu) {
				this.accountMenu = false;
				this.dashboardMenu = false;
			}
		},

		closeMenu: function (event) {
			if (event && event.target) {
				if (event.target.id === 'events-calendar-show-coming-soon' || event.target.id === 'overview-show-coming-soon') {
					this.comingSoonDialogVisible = true;
				}
				if (event.target.id !== 'btn-open-dashboard' && event.target.id !== 'btn-open-main-menu' && event.target.id !== 'btn-open-account-settings') {
					this.closeAllMenu = true;
					this.$store.commit('SET_HEADER_USER_MENU', false);
				}
				if (event.target.id !== 'btn-open-dashboard') {
					this.dashboardMenu = false;
				}
				if (event.target.id !== 'btn-open-main-menu') {
					this.mainMenu = false;
				}
				if (event.target.id !== 'btn-open-account-settings') {
					this.accountMenu = false;
				}
			}
		},
		logout() {
			console.log('userMenu SET_NEED_LOGIN_REDIRECT_PATH');
			this.$store.commit('SET_NEED_LOGIN_REDIRECT_PATH', '');
			console.log('userMenu logout');
			this.$store.dispatch('LOGOUT').then(() => {
				this.$router.push({name: 'logged-out'});
				// this.$store.dispatch('reset');
			});
		},
	}
}
</script>

<style lang="scss">
.slide-right-enter-active,
.slide-right-leave-active {
	transition: transform 0.1s ease-in;
}

.slide-right-enter,
.slide-right-leave-to {
	transform: translateX(-50px);
}

//////////////////////
.slide-y-enter-active,
.slide-y-leave-active {
	transition: transform 1s;
}

.slide-y-enter,
.slide-y-leave-to {
	transform: translateY(-10%);
}

.v-overlay-transition {
	transition: none !important;
}

.wrapper_user_menu {
  border-radius: 8px;
  overflow: visible;
}

.list_links, .menu_section, .account_logout {
  background: white;
  border-radius: 8px;
  margin: 8px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.menu_box {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    
    &:hover {
      background-color: transparent;
    }
  }
}

.account_logout {
  color: #dc3545;
  
  &:hover {
    background-color: rgba(220, 53, 69, 0.08);
  }
}

// Optional: Add a subtle animation when the menu opens
.user_menu {
  animation: menuSlideIn 0.2s ease-out;
}

@keyframes menuSlideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.skeleton-zoom {
  transform: scale(1.1);
}
</style>
