<template>
	<div class="wrap_filter_bar_albums" :class="{'full': filterName === 'explore'}">

		<div class="filter_bar_" v-if="filterName === 'library' || STATUS_FILTER" :class="filterName">

			<div class="filters_">

				<!-- GENRE -->
				<div class="genre">
					<div class="material_input">
						<input type="text" readonly required id="btn-open-genres" v-model="GENRE">
						<label>Genre</label>
					</div>
				</div>

				<!-- SUB-GENRE -->
				<div class="subgenre">
					<div class="material_input">
						<v-menu
							offset-y
							:close-on-content-click="true">

							<template v-slot:activator="{ on, attrs }">
								<template v-if="filterName && filterName === 'explore'">
									<input type="text" required v-bind="attrs" v-on="on" v-model="SUBGENRE.name" @click="viewKeysFilter = false">
								</template>
								<template v-if="filterName && filterName === 'library'">
									<input type="text" required v-bind="attrs" v-on="on" v-model="librarySubgenre.name" @click="viewKeysFilter = false">
								</template>
							</template>

							<v-list>
								<v-list-item v-for="item of SUBGENRES" :key="item.id" @click="selectSubgenre(item)">
									{{ item.name }}
									<span class="count" v-if="filterName === 'explore'">{{ item.musicReleasesCount }}</span>
								</v-list-item>
							</v-list>
						</v-menu>
						<label>Sub-Genre</label>
					</div>
					<div class="clear_subgenre" v-if="subgenreClear && librarySubgenre.name" @click="clearSubgenre">
						<span class="material-icons">cancel</span>
					</div>
				</div>

				<!-- BPM -->
				<div class="bpm">
					<v-menu v-model="bpmOptions.show"
					        :close-on-content-click="false"
					        :min-width="310"
					        offset-y
					        :menu-props="{ contentClass: 'bpm_menu' }">

						<template v-slot:activator="{ on, attrs }">
							<div class="material_input" v-bind="attrs" v-on="on">
								<input v-on:keypress="readonly"
								       v-if="BPM.length && filterName === 'explore'"
								       type="text"
								       required
								       v-model="BPM[0] +' - '+ BPM[1]"
								       @click="viewKeysFilter = false">

								<input v-on:keypress="readonly" v-if="libraryBPM.length && filterName === 'library'"
								       type="text"
								       required
								       v-model="libraryBPM[0] +' - '+ libraryBPM[1]"
								       @click="viewKeysFilter = false">

								<input v-on:keypress="readonly" v-else type="text" required @click="viewKeysFilter = false">
								<label>BPM</label>
							</div>
						</template>

						<v-card class="border_radius_12">
							<v-list>
								<div class="bpm_body">
									<div class="wrap_slider">
										<v-range-slider
											v-model="bpmRange"
											:min="0"
											:max="300"
											:step="1"
											hide-details
											:thumb-color="'#7481A6'"
											:thumb-size='20'
											:tick-size='100'
											class="align-center">
										</v-range-slider>
									</div>
									<div class="wrap_inputs">
										<input type="text" v-model="bpmRange[0]" v-on:keypress="numbersOnly" maxlength="3">
										<span>-</span>
										<input type="text" v-model="bpmRange[1]" v-on:keypress="numbersOnly" maxlength="3">
									</div>
								</div>
							</v-list>
						</v-card>
					</v-menu>

					<div class="bpm_action" v-if="bpmOptions.show">
						<button type="button" class="clear_close" @click="cancelBPM">
							<span class="material-icons">cancel</span>
						</button>
						<button type="button" class="apply_bpm" :class="{'active': bpmRange[0] && bpmRange[1]}" @click="saveBPM">
							<span class="material-icons">check_circle</span>
						</button>
					</div>

				</div>

				<!-- KEYS -->
				<div class="key">
					<div class="material_input" @click="showKeys" id="btn-open-keys">
						<input type="text" v-if="filterName === 'explore'" required v-model="combinedKeys">
						<input type="text" v-if="filterName === 'library'" required v-model="combinedLibraryKeys">
						<label>Key</label>
					</div>
					<!-- <template v-if="KEYS.length > 4">Multiple</template> -->
					<!-- <template v-else v-for="item in KEYS"> -->
					<div class="key_action" v-if="keysFilter && viewKeysFilter">
						<button type="button" class="clear_close" @click="clearCloseKeys">
							<span class="material-icons">cancel</span>
						</button>

						<button v-if="filterName === 'explore'" type="button" class="apply_keys" :class="{'active': KEYS.length}" @click="applyKeys">
							<span class="material-icons">check_circle</span>
						</button>
						<button v-if="filterName === 'library'" type="button" class="apply_keys" :class="{'active': libraryKeys.length}" @click="applyKeys">
							<span class="material-icons">check_circle</span>
						</button>
					</div>
				</div>
			</div>

			<!-- TAGS -->
			<template v-if="filterName === 'explore'">
				<div class="tags_input_" v-if="showTagsInput || actions === false || TAGS.length">
					<div class="tags">
						<v-chip
							v-for="(item, index) in TAGS"
							:key="index"
							close
							@click:close="TAGS.splice(index,1)">
							{{ item }}
						</v-chip>
						<input type="text" placeholder="#Tags" @keypress="updateTags($event)" v-model="newTag" @keydown.delete="removeTags($event)">
					</div>
				</div>
			</template>
			<template v-if="filterName === 'library'">
				<div class="tags_input_" v-if="showTagsInput || actions === false || libraryTags.length">
					<div class="tags">
						<v-chip
							v-for="(item, index) in libraryTags"
							:key="index"
							close
							@click:close="libraryTags.splice(index,1)">
							{{ item }}
						</v-chip>
						<input type="text" placeholder="#Tags" @keypress="updateTags($event)" v-model="newTag" @keydown.delete="removeTags($event)">
					</div>
				</div>
			</template>
			<keys v-if="viewKeysFilter" class="filter_keys" :filterName="filterName"></keys> <!--  v-click-outside="clickOutsideKeys" -->
		</div>

	</div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import keys from "../keys";
import eventBus from "@/eventBus";

export default {
	name: "albumsFilter",
	components: {
		keys
	},
	props: ['actions', 'subgenreFilter', 'keysFilter', 'filterName', 'subgenreClear'],
	data() {
		return {
			GENRE: 'Psychedelic Trance',
			bpmOptions: {
				show: false,
				min: 0,
				max: 300,
			},
			bpmRange: [0, 300],

			tags: [],
			newTag: '',

			actionsButtons: false,
			showTagsInput: false,
			filters: [],
			userID: null,
			dialogSaveFilterVisible: false,
			dialogNeedLogin: false,
			viewKeysFilter: false,
			bookmarkStatus: false,


			// saved filters
			menuSaveFilterVisible: false,
			newFilterName: '',
			activeSavedFilter: '',
			deleteSavedFilterStatus: false,
			saveSavedFilterStatus: true,
			discovery: false,
		};
	},
	watch: {
		TAGS() {
			if (!this.TAGS.length) {
				this.tags = [];
			}
		}
	},
	computed: {
		...mapGetters([
			'AUTH_DATA', 'PROFILE',
			'UPPER_FILTER',
			'GENRES', 'SUBGENRE', 'BPM', 'APPLY_KEYS', 'KEYS', 'TAGS',
			'CHANGE_FILTERS_STATUS', 'DISCOVERY',
			'STATUS_FILTER', 'ACTIVE_SAVED_FILTER', 'SAVED_FILTERS', 'SUBGENRES'
		]),
		...mapGetters({
			librarySubgenre: 'collection/SUBGENRE',
			libraryBPM: 'collection/BPM',
			libraryKeys: 'collection/KEYS',
			libraryTags: 'collection/TAGS',
		}),
		combinedKeys() {
			return this.KEYS.map(key => key.code).join(', ');
		},
		combinedLibraryKeys() {
			return this.libraryKeys.map(key => key.code).join(', ');
		}
	},
	mounted() {
		if (!this.SUBGENRES.length && this.filterName === 'library') {
			this.getSubgenres();
		}

		// bpm
		if (this.filterName === 'explore') {
			if (this.BPM.length) {
				this.bpmRange = this.BPM;
			}
			if (this.TAGS.length) {
				this.tags = this.TAGS;
				this.$store.commit('SET_STATUS_FILTER', true);
			}
		}
		if (this.filterName === 'library') {
			if (this.libraryBPM.length) {
				this.bpmRange = this.libraryBPM;
			}
			if (this.libraryTags.length) {
				this.tags = this.libraryTags;
			}
		}
	},
	methods: {
		...mapActions(['GET_PROFILE', 'CHANGE_FILTERS', 'UPDATE_DISCOVERY', 'GET_GENRES', 'GET_SUBGENRES', 'CLEAN_EXPLORE_GRID_ALBUMS']),
		toggle_discovery() {
			this.discovery = !this.discovery;
			this.$store.dispatch('UPDATE_DISCOVERY', this.discovery);
		},

		numbersOnly(evt) {
			console.log('click numbersOnly');
			// evt = (evt) ? evt : window.event;
			// const charCode = (evt.which) ? evt.which : evt.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			//     evt.preventDefault();
			// } else {
			//     return true;
			// }
		},
		readonly(evt) {
			evt.preventDefault();
		},

		// value filters
		getSubgenres() {
			const subgenresParams = {
				genre: 2, // Psychedelic Trance
				'order[musicReleasesCount]': 'DESC'
			}
			this.GET_SUBGENRES(subgenresParams);
		},
		selectSubgenre(subgenre) {
			if (this.filterName === 'explore') {
				this.$store.dispatch('UPDATE_SUBGENRE', subgenre);
				const subgenreName = subgenre.name.trim().replace(/ /g, '-');
				this.$router.replace({path: `/explore/${subgenreName}`});
			}
			if (this.filterName === 'library') {
				this.$store.commit('collection/SET_SUBGENRE', subgenre);
			}
		},

		saveBPM() {
			if (!this.bpmRange[0]) {
				this.bpmRange[0] = 0;
			}
			if (!this.bpmRange[1]) {
				this.bpmRange[1] = 300;
			}

			if (this.filterName === 'explore') {
				this.$store.commit('SET_BPM', this.bpmRange);
			}
      console.log('this.filterName', this.filterName);
      if (this.filterName === 'library') {
				this.$store.commit('collection/SET_BPM', this.bpmRange);
			}
			this.bpmOptions.show = false;
      this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);


      eventBus.emit('customEvent');
		},
		cancelBPM() {
			this.bpmOptions.show = false;
			if (this.filterName === 'explore') {
				this.$store.commit('SET_BPM', []);
			}
			if (this.filterName === 'library') {
				this.$store.commit('collection/SET_BPM', []);
			}
			this.bpmRange = [this.bpmOptions.min, this.bpmOptions.max];
		},

		updateTags(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;

			// Enter || Space || Comma
			if (charCode === 13 || charCode === 32 || charCode === 44) {
				evt.preventDefault();
				if (this.filterName === 'explore') {
					if (this.newTag.length >= 1) {
						this.tags.push(this.newTag);
						this.$store.commit('SET_TAGS', this.tags);
					}
				}
				if (this.filterName === 'library') {
					if (this.newTag.length >= 1) {
						this.libraryTags.push(this.newTag);
						this.$store.commit('collection/SET_TAGS', this.libraryTags);
					}
				}
				this.newTag = '';
			}

		},
		removeTags(ev) {
			if (ev.key === 'Backspace') {
				if (this.filterName === 'explore') {
					if (this.newTag.length) {
						return;
					} else if (!this.newTag.length) {
						this.tags.splice(-1, 1);
					}
				}
				if (this.filterName === 'library') {
					if (this.newTag.length) {
						return;
					} else if (!this.newTag.length) {
						this.libraryTags.splice(-1, 1);
					}
				}
			}
		},
		showKeys() {
			this.viewKeysFilter = true;
		},
		clearCloseKeys() {
			this.actionsButtons = false;
			this.viewKeysFilter = false;

			if (this.filterName === 'explore') {
				this.$store.dispatch('UPDATE_KEYS', []);
				this.$store.commit('SET_APPLY_KEYS', []);
			}
			if (this.filterName === 'library') {
				this.$store.commit('collection/SET_KEYS', []);
				this.$store.commit('collection/SET_APPLY_KEYS', []);
			}

			this.$store.commit('SET_ALBUM_EXPANDED', null);
		},
		applyKeys() {
			if (this.filterName === 'explore') {
				this.$store.commit('SET_APPLY_KEYS', this.KEYS);
			}
			if (this.filterName === 'library') {
				this.$store.commit('collection/SET_APPLY_KEYS', this.libraryKeys);
			}

			this.viewKeysFilter = false;
      eventBus.emit('customEvent');
		},
		clearSubgenre() {
			this.$store.commit('collection/SET_SUBGENRE', '');
		},
	}
};
</script>
