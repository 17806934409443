<template>
    <div>
        <div class="upload-img">
            <div class="select-img" @click="selectPhoto">
                <v-img
                    v-if="selectedPhotoPreviewUrl"
                    :src="selectedPhotoPreviewUrl"
                    min-width="100%"
                    min-height="100%"
                ></v-img>
                <div class="select-change">
                    {{ this.selectedPhotoPreviewUrl ? 'Change' : 'Select' }}
                </div>
            </div>
            <div class="description">
                <slot></slot>
            </div>
            <input
                type="file"
                multiple
                hidden
                ref="fileInput"
                accept="image/png, image/jpeg"
                autocomplete="off"
                @click="resetImageUploader"
                @change="photoSelected">
        </div>
        <v-dialog v-model="photoLimitDialog"
            max-width="600">
            <div class="need_login_dialog style_dialog">
                <h2 class="header">
                    Oops!
                </h2>
                <div class="cnt">
	                Please choose an image that's less than 10MB.
                </div>
                <div class="wrap_actions center">
                    <button type="button" class="cancel" @click="closeModal()" v-ripple>Ok</button>
                </div>
            </div>
        </v-dialog>
    </div>

</template>

<script>
export default {
    name: 'uploadImage',
    props: {},
    data() {
        return {
            selectedPhotoPreviewUrl: null,
            photoLimitDialog: false,
        }
    },
    methods: {
        resetImageUploader() {
            this.$refs.fileInput.value = null;
        },
        selectPhoto() {
            this.$refs.fileInput.click();
        },
        photoSelected(event) {
            try {
                const file = event.target.files[0];
                const limitImageSize = 10 * 1024 * 1024;

                if (file.type === "image/jpeg" || file.type === "image/png") {
                    if (file.size < limitImageSize) {
                        const fileReader = new FileReader();
                        fileReader.addEventListener("load", () => {
                            this.$emit("selected_photo", file);
                            this.selectedPhotoPreviewUrl = fileReader.result;
                        });
                        fileReader.readAsDataURL(file);
                    } else {
                        this.selectedPhotoPreviewUrl = null;
                        this.photoLimitDialog = true;
                    }
                } else {
                    this.selectedPhotoPreviewUrl = null;
                    this.photoLimitDialog = true;
                }
            } catch (error) {
                console.error(`uploadImage, photoSelected ${error}`);
            }
        },

        closeModal() {
            this.photoLimitDialog = false;
        }
    }
}
</script>

<style lang="scss">
@import 'src/assets/styles/components/uploadImage';
</style>
