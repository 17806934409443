export default [
    {
      path: '/calculator',
      name: 'calculator', component: () => import('@/pages/calculator'),
      meta: {requiresAuth: false}
    },
    {
        path: '/logged-out',
        name: 'logged-out', component: () => import('@/pages/after-actions/logged-out'),
        meta: {requiresAuth: false}
    },
    {
        path: '/password-changed',
        name: 'password-changed', component: () => import('@/pages/after-actions/password-changed'),
        meta: {requiresAuth: false}
    },
    {
        path: '/music-release-submitted',
        name: 'musicReleaseSubmitted', component: () => import('@/pages/after-actions/musicReleaseSubmitted'),
        meta: {requiresAuth: false}
    },
    {
        path: '/under-construction',
        name: 'underConstruction', component: () => import('@/pages/after-actions/underConstruction'),
        meta: {requiresAuth: false}
    },
    {
        path: '/verification-request-submitted',
        name: 'verification-request-submitted', component: () => import('@/pages/after-actions/verificationRequestSubmitted'),
        meta: {requiresAuth: false}
    },
    {
        path: '/release-removed',
        name: 'releaseRemoved', component: () => import('@/pages/after-actions/releaseRemoved'),
        meta: {requiresAuth: false}
    },
    {path: '/404', name: '404', component: () => import('@/pages/404')},
    {path: '*', redirect: '/404'}
]
