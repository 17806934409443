import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            song: this.song,
        }
    },
    watch: {
        afterActionDialogNeedLogin() {
            if (this.afterActionDialogNeedLogin === false) {
                if (this.song && this.song.isFavorite) {
                    this.song.isFavorite = false;
                }
            }
        }
    },
    created() {

    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
            currentAlbum: 'CURRENT_ALBUM',
            currentTrack: 'CURRENT_TRACK',
            fullAlbum: 'FULL_ALBUM',
            fullAlbumLoading: 'FULL_ALBUM_LOADING',
            RELEASES_COLLECTION: 'RELEASES_COLLECTION',
            TRACKS_COLLECTION: 'TRACKS_COLLECTION',
            afterActionDialogNeedLogin: 'AFTER_ACTION_DIALOG_NEED_LOGIN'
        }),
    },
    methods: {
        ...mapActions([
            'ADD_TRACK_TO_FAVORITES'
        ]),
        addLikeAlbumFromCollections(albumID) {
            if (albumID) {
                for (let collection of this.RELEASES_COLLECTION) {
                    let getter = this.$store.getters[collection];
                    if (getter && getter.length) {
                        let albumIndex = getter.findIndex((album) => +album.id === albumID);
                        if (albumIndex !== -1) {
                            getter[albumIndex].isFavorite = true;
                            if (getter[albumIndex].playList && getter[albumIndex].playList.length) {
                                for (let i = 0; i < getter[albumIndex].playList.length; i++) {
                                    getter[albumIndex].playList[i].song.isFavorite = true;
                                }
                            }
                        }
                    }
                }
            } else {
                console.log('addLikeAlbumFromCollections need music release id', albumID);
            }
        },
        addLikeTrackFromCollections(track) {
            if (track) {
                let trackID = track.id;
                for (let collection of this.TRACKS_COLLECTION) {
                    let getter = this.$store.getters[collection];
                    if (getter && getter.length) {

                        if (track.song) {
                            let trackSongIndex = getter.findIndex((track) => +track.song.id === trackID);
                            if (trackSongIndex !== -1) {
                                getter[trackSongIndex].isFavorite = true;
                            }
                        } else {
                            let trackIndex = getter.findIndex((track) => +track.id === trackID);
                            if (trackIndex !== -1) {
                                getter[trackIndex].isFavorite = true;
                            } else {
                                let trackIndex = getter.findIndex((track) => {
                                    if (!track?.song) {
                                        return +track.id === trackID;
                                    }

                                    return +track.song.id === trackID;
                                });
                                if (trackIndex !== -1) {
                                    getter[trackIndex].isFavorite = true;
                                    getter[trackIndex].song.isFavorite = true;
                                }
                            }
                        }
                    }
                }
            }
        },
        checkNoFavTracks() {
            let isFavorite = this.albumPlayList.find(track => track.song.isFavorite === false);
            // let isFavorite = this.fullAlbum.playList.find(track => track.song.isFavorite === false);
            return !!isFavorite;
        },
        // checkNoFavTracks() {
        //     let isFavorite = this.currentAlbum.playList.find(track => track.song.isFavorite === false);
        //     return !!isFavorite;
        // },
        checkNoFavTracksAlbumDetails() {
            let isFavorite = this.fullAlbum.playList.find(track => track.song.isFavorite === false);
            return !!isFavorite;
        },
        addTrackToFavorites(song, album) {
            if (this.authData.accessToken) {
                this.markTrackAsSelected(song, album);
                this.sendSelectedTrackToServer(song, album);

            } else {
                // console.log('markTrackAsSelected', song, album);
                this.markTrackAsSelected(song, album);
                localStorage.setItem('add_track_to_favorites', JSON.stringify({song: song, album: album}));

                this.needLoginDialog = {
                    visible: true,
                    action: 'add_album_to_favorites'
                };
                this.song = song;
                // this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', null);
                // if (this.afterActionDialogNeedLogin === false) {
                //     console.log(1111);
                //     this.removeMarkTrack(song);
                // }
                if (this.drawerPlayer === true) {
                    this.drawerPlayer = false;
                }
            }
        },

        markTrackAsSelected(song, album){
            let songID = song.id;
            song.isFavorite = true;

            if (this.currentTrack && this.currentTrack.id === songID) {
                this.currentTrack.isFavorite = true;
            }
            if (this.fullAlbum && this.fullAlbum.playList) {
                let favTrack = this.fullAlbum.playList.find(el => el.song.id === songID);
                if (favTrack && favTrack.song) {
                    favTrack.song.isFavorite = true;
                }
            }

            // let favTrack;
            if (album.playList) {
                let favTrack = album.playList.find(el => el.song.id === songID);
                if (favTrack && favTrack.song) {
                    favTrack.song.isFavorite = true;
                }
                if (this.checkNoFavTracksAlbumDetails() === false) {
                    album.isFavorite = true;
                    this.addLikeAlbumFromCollections(album.id);
                }
            }



            // all tracks favorites - for the album playlist
            if (this.albumPlayList) {
                if (this.checkNoFavTracksAlbumDetails() === false) {
                    album.isFavorite = true;
                    this.btnShowAllTracks = false;
                } else if ((this.UPPER_FILTER === 'favorite' && this.CURRENT_PAGE.name === 'explore') || this.$route.params.favourite === 'favourite' || this.CURRENT_PAGE.name === 'library') {
                    this.btnShowAllTracks = true;
                } else {
                    console.log(3333333);
                }
            }
        },
        sendSelectedTrackToServer(song, album) {
            this.addLikeTrackFromCollections(song);
            this.$store.commit('SET_REFRESH_RELEASES', true);
            this.ADD_TRACK_TO_FAVORITES({'song_id': song.id, 'album_id': album.id})
                .then(() => {
                })
                .catch(err => {
                    console.error(`addTrackToFavorites, ${err}`);
                })
        }


    }
}
