export default {
    data() {
        return {
            mobile: false,
        }
    },
    created() {
        this.mobile = screen.width <= 767;
    },
}
