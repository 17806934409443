export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/auth/login'),
        meta: {requiresAuth: false}
    },
    {
        path: '/registration',
        name: 'registration',
        component: () => import('@/pages/auth/registration'),
        meta: {requiresAuth: false}
    },
    // forgot password
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/pages/auth/forgot-password'),
        meta: {requiresAuth: false}
    },
    {
        path: '/reset-password/reset/:token',
        name: 'password-recovery',
        component: () => import('@/pages/auth/password-recovery'),
        props: true,
        meta: {requiresAuth: false}
    },
    // end forgot password
    {
        path: '/email-verification',
        name: 'email-verification',
        component: () => import('@/pages/auth/email-verification'),
        meta: {requiresAuth: false},
        beforeEnter: (to, from, next) => {
            // We check from which page the user wants to navigate.
            localStorage.setItem('IS_MOVE_FROM_LOGIN', from.name === 'login' ? 'true' : 'false');

            next();
        },
    },
    {
        path: '/email-congratulations',
        name: 'email-congratulations',
        component: () => import('@/pages/auth/email-congratulations'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile-basics',
        name: 'profile-basics',
        component: () => import('@/pages/auth/profile-basics'),
        meta: {requiresAuth: true}
    },
]
