<template>
  <searchLayout class="page-search">
    <div class="p-search full_page"
         :class="{'nothing_found':
         !SEARCH_ARTISTS_RESULT.length &&
         !SEARCH_ALBUMS_RESULT.length &&
         !SEARCH_TRACKS_RESULT.length &&
         !SEARCH_RECORD_LABELS_RESULT.length &&
         SEARCH_STRING && !SEARCH_LOADER,
         'empty_page': !SEARCH_STRING,
         'loading':  SEARCH_LOADER}">
      <div class="overlay">
        <v-container fluid>
          <!-- Form to handle submit -->
          <form @submit.prevent="runSearch">
            <div class="box_input_search" :class="{'focus': isFocus}" v-click-outside="removeFocus" @click="addFocus" ref="box_input_search">
              <input type="text" ref="inputSearch" placeholder="Search..." v-model="searchText" name="search" autofocus enterkeyhint="search">
              <button type="button" class="clear_search" v-if="SEARCH_STRING" @click="clearSearch"></button>
              <button type="submit" class="run_search" :class="{'active': searchText.length}" v-if="!SEARCH_STRING"></button>
            </div>
          </form>

          <div v-if="SEARCH_LOADER" class="box_loading">
            <v-progress-circular :size="100" :width="3" color="#20C4F5" indeterminate></v-progress-circular>
          </div>

          <template v-if="SEARCH_STRING && (SEARCH_ARTISTS_RESULT.length || SEARCH_ALBUMS_RESULT.length || SEARCH_TRACKS_RESULT.length || SEARCH_RECORD_LABELS_RESULT.length)">
            <div class="wrap_box_results">
              <div class="box_result artists" v-if="SEARCH_ARTISTS_RESULT.length">
                <div class="_head">
                  <h3>Artists <span v-if="SEARCH_COUNT_ARTISTS">{{ SEARCH_COUNT_ARTISTS }}</span></h3>
                  <router-link v-if="SEARCH_ARTISTS_RESULT.length > 4" class="view-all x_small" v-ripple
                               :to="{name: 'searchDetails', params: { 'whatContent': 'artists' }}">
                    View All
                    <span class="material-icons">navigate_next</span>
                  </router-link>
                </div>
                <div class="result">
                  <div class="item_artist"
                       v-for="artist of SEARCH_ARTISTS_RESULT.slice(0, 4)"
                       :key="artist.id"
                       @click="(event)=>redirectToArtistPage(event,artist.id, artist.identifier)">
                    <div class="left_box" style="width: inherit;">
                      <div class="img">
                        <img v-if="artist.avatar && artist.avatar.thumbnail"
                             :src="artist.avatar.thumbnail['50x50']"
                             v-bind:srcset="`${artist.avatar.thumbnail['50x50']} 1x, ${artist.avatar.thumbnail['50x50@2x']} 2x`"
                             :alt="artist.name">
                        <img v-else :src="artistNoImage" alt="" class="default_artist_ava">
                      </div>
                      <div class="descript">
                        <div class="name">{{ artist.name }}</div>
                      </div>
                    </div>
                    <div class="right_box actions">
                      <v-skeleton-loader v-if="isLoading(artist.id)" max-width="300" height="34" type="button"></v-skeleton-loader>
                      <button type="button" class="follow_btn" v-if="artist.hasFollow === false && !isLoading(artist.id)" @click.stop="follow(artist)">
                        <span class="icon"></span>
                        <p class="text">Follow</p>
                      </button>
                      <button type="button" class="follow_btn following" v-else-if="!isLoading(artist.id)" @click.stop="unfollow(artist)"
                              @touchend="hoverFollowingButton = false"
                              @mouseover="hoverFollowingButton = artist.id"
                              @mouseleave="hoverFollowingButton = false">
                        <span class="icon"></span>
                        <template v-if="hoverFollowingButton === artist.id"><p class="text">Unfollow</p></template>
                        <template v-else><p class="text">Following</p></template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box_result albums" v-if="SEARCH_ALBUMS_RESULT.length">
                <div class="_head">
                  <h3>Albums <span v-if="SEARCH_COUNT_ALBUMS">{{ SEARCH_COUNT_ALBUMS }}</span></h3>
                  <router-link v-if="SEARCH_ALBUMS_RESULT.length > 5" class="view-all x_small" v-ripple
                               :to="{name: 'searchDetails', params: { 'whatContent': 'albums' }}">
                    View All
                    <span class="material-icons">navigate_next</span>
                  </router-link>
                </div>
                <div class="result">
                  <template v-for="item of SEARCH_ALBUMS_RESULT.slice(0, 5)">
                    <albumGrid
                      :album="item"
                      :key="item.id"
                      :playZone="SEARCH_ALBUMS_RESULT.slice(0, 5)"
                    ></albumGrid>
                  </template>
                </div>
              </div>

              <div class="box_result tracks" v-if="SEARCH_TRACKS_RESULT.length">
                <div class="_head">
                  <h3>Tracks <span v-if="SEARCH_COUNT_TRACKS">{{ SEARCH_COUNT_TRACKS }}</span></h3>
                  <router-link v-if="SEARCH_TRACKS_RESULT.length > 3" class="view-all x_small" v-ripple
                               :to="{name: 'searchDetails', params: { 'whatContent': 'tracks' }}">
                    View All
                    <span class="material-icons">navigate_next</span>
                  </router-link>
                </div>
                <div class="result track_list">
                  <itemTrack
                    v-for="(track, index) in SEARCH_TRACKS_RESULT.slice(0, 3)"
                    :key="track.id"
                    :track="track"
                    :index="index"
                  ></itemTrack>
                </div>
              </div>

              <div class="box_result artists" v-if="SEARCH_RECORD_LABELS_RESULT.length">
                <div class="_head">
                  <h3>Labels <span v-if="SEARCH_COUNT_RECORD_LABELS">{{ SEARCH_COUNT_RECORD_LABELS }}</span></h3>
                  <router-link v-if="SEARCH_RECORD_LABELS_RESULT.length > 4" class="view-all x_small" v-ripple
                               :to="{name: 'searchDetails', params: { 'whatContent': 'labels' }}">
                    View All
                    <span class="material-icons">navigate_next</span>
                  </router-link>
                </div>
                <div class="result">
                  <div class="item_artist label"
                       @click="redirectToLabelProfile(label.id, label.title)"
                       v-for="label of SEARCH_RECORD_LABELS_RESULT.slice(0, 4)"
                       :key="label.id">
                    <div class="left_box">
                      <div class="img">
                      <img v-if="label.logo && label.logo.thumbnail"
                           v-bind:srcset="`${label.logo.thumbnail['50x50']} 1x, ${label.logo.thumbnail['50x50@2x']} 2x`"
                           :src="label.logo.thumbnail['50x50']"
                           :alt="label.title">
                      <img v-else :src="labelNoImage" alt="" class="default_artist_ava">
                    </div>
                    <div class="descript">
                      <div class="name">{{ label.title }}</div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </template>

          <div class="nothing_found_block" v-if="nothingFound && !SEARCH_LOADER">
            <div class="search_nothing_found">Nothing found</div>
          </div>
          
          <dialogNeedLogin v-if="needLoginDialog" v-model="needLoginDialog"></dialogNeedLogin>

        </v-container>
      </div>
    </div>
  </searchLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import searchLayout from "@/layouts/searchLayout.vue";
import albumGrid from '@/components/musicReleases/albumGrid';
import itemTrack from "@/components/musicReleases/itemTrack";

const dialogNeedLogin = () => import('@/components/dialogs/needLogin');

export default {
  name: 'search',
  components: {
    dialogNeedLogin,
    searchLayout,
    albumGrid,
    itemTrack
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      artistNoImage: require('../../assets/image/svg/square_avatar.svg'),
      labelNoImage: require('../../assets/image/svg/square_avatar.svg'),

      searchText: '',
      isFocus: false,
      nothingFound: false,

      followBtnLoader: [],
      hoverFollowingButton: false,
      cacheKey: new Date().getTime(),

      needLoginDialog:false,
    }
  },

  watch: {
    SEARCH_STRING() {
      this.$store.commit('SET_ARTISTS_CURRENT_PAGE', 1);
      this.$store.commit('SET_SEARCH_ALBUMS_CURRENT_PAGE', 1);
      this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
      this.$store.commit('SET_SEARCH_RECORD_LABELS_CURRENT_PAGE', 1);
    }
  },

  computed: {
    ...mapGetters([
      'SEARCH_STRING', 'SEARCH_LOADER', 'FROM_PAGE',
      'AUTH_DATA',
      'SEARCH_ARTISTS_RESULT',
      'SEARCH_COUNT_ARTISTS',
      'SEARCH_ALBUMS_RESULT',
      'SEARCH_COUNT_ALBUMS',
      'SEARCH_TRACKS_RESULT',
      'SEARCH_COUNT_TRACKS',
      'SEARCH_RECORD_LABELS_RESULT',
      'SEARCH_COUNT_RECORD_LABELS',
      'SEARCH_RECORD_LABELS_CURRENT_PAGE'
    ]),
  },
  beforeMount() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
  },
  mounted() {
    this.$store.commit('UPDATE_SEARCH_LOADER', false);
    this.searchText = this.SEARCH_STRING;

    this.$nextTick(() => {
      this.$refs.inputSearch.focus();
    });
    setTimeout(() => {
      const input = this.$refs.inputSearch;

      if (input) {
        input.focus();
        this.isFocus = true;
      }
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscapeKeyUp)
  },
  methods: {
    ...mapActions(['CLEAN_SEARCH_RESULT', 'SEARCH_ARTISTS', 'SEARCH_ALBUMS', 'SEARCH_TRACKS', 'SEARCH_RECORD_LABELS', 'ARTIST_FOLLOW', 'ARTIST_UNFOLLOW']),
    redirectToArtistPage(event,artistId, artistName) {
      event.preventDefault();
      this.$router.push({ name: 'artist-profile', params: { id: artistId, name: window.toHumanFormat(artistName) } });
    },
    redirectToLabelProfile(labelId, labelTitle) {
      this.$router.push({ name: 'label-profile', params: { id: labelId, name: window.toHumanFormat(labelTitle) } });
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.$router.push({ name: 'explore' });
      }
    },
    async runSearch() {
      console.log('runSearch called', this.searchText);
      if (this.searchText.length) {
        this.$refs.inputSearch.blur(); // Hide the keyboard by blurring the input field
        this.CLEAN_SEARCH_RESULT();
        this.$store.commit('UPDATE_SEARCH_STRING', this.searchText);
        this.$store.commit('UPDATE_SEARCH_LOADER', true);

        try {
          await Promise.all([
            this.SEARCH_ARTISTS({ 'name': this.searchText.trim() }),
            this.SEARCH_ALBUMS({ 'title': this.searchText.trim(), sort: 'releasedAt', order: 'DESC', status: 'published' }),
            this.SEARCH_TRACKS({ 'title': this.searchText.trim(), 'order[releasedAt]': 'DESC' }),
            this.SEARCH_RECORD_LABELS({ 'title': this.searchText.trim() })
          ]);
        } catch (err) {
          console.error('Search error:', err);
        }

        this.$store.commit('UPDATE_SEARCH_LOADER', false);

        this.nothingFound = !this.SEARCH_ARTISTS_RESULT.length &&
          !this.SEARCH_ALBUMS_RESULT.length &&
          !this.SEARCH_TRACKS_RESULT.length &&
          !this.SEARCH_RECORD_LABELS_RESULT.length;

        console.log('Search completed, results:', {
          artists: this.SEARCH_ARTISTS_RESULT,
          albums: this.SEARCH_ALBUMS_RESULT,
          tracks: this.SEARCH_TRACKS_RESULT,
          labels: this.SEARCH_RECORD_LABELS_RESULT
        });
      }
    },
    clearSearch() {
      this.CLEAN_SEARCH_RESULT();
      this.searchText = '';
      this.$store.commit('UPDATE_SEARCH_STRING', '');

      if (this.$route.name !== 'search') {
        this.$router.push({ name: 'search' });
      }
    },
    removeFocus(event) {
      // if (event.target.className !== 'search active router-link-active') {
      // 	this.isFocus = false;
      // }
    },
    addFocus() {
      this.isFocus = true;
    },

    follow(artist) {
      if (this.AUTH_DATA.accessToken) {
        this.followBtnLoader.push(artist.id);
        this.ARTIST_FOLLOW(artist.id)
          .then(result => {
            artist.hasFollow = result === 'success'

            if (result !== 'success') {
              this.errorDialog = true;
            }
          })
          .catch(() => {
            this.errorDialog = true;
          })
          .finally(() => {
            const index = this.followBtnLoader.indexOf(artist.id);
            if (index !== -1) {
              this.followBtnLoader.splice(index, 1);
            }
          });
      } else {
		this.needLoginDialog = true;
	  }
    },
	unfollow(artist) {
		if (this.AUTH_DATA.accessToken) {
		this.followBtnLoader.push(artist.id);
		this.ARTIST_UNFOLLOW(artist.id)
			.then(result => {
			artist.hasFollow = !(result === 'success')
			if (result !== 'success') {
				this.errorDialog = false;
			}
			})
			.catch(err => {
			this.errorDialog = true;
			console.error(`artist unfollow, ${err}`)
			})
			.finally(() => {
			const index = this.followBtnLoader.indexOf(artist.id);
			if (index !== -1) {
				this.followBtnLoader.splice(index, 1);
			}
			});
		} else {
		this.needLoginDialog = true;
	  }
	},
	
    isLoading(artistId) {
      return this.followBtnLoader.includes(artistId);
    },
  }
}
</script>

<style lang="scss">
.page-search {
  // Your styles here
}
</style>
