import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#323659'
            },
            light: {
                primary: '#ffffff'
            }
        }
    },
});
