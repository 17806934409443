// src/store/modules/favoriteMusicRelease.store.js

import axios from 'axios';

const initialState = () => ({
    activeReq: null, // Holds the current AbortController instance
    favoriteMusicRelease: [],
    countFavoriteMusicRelease: 0,
    favoriteMusicReleaseCurrentPage: 1,
    favoriteMusicReleaseLoading: false,
    currentParams: null, // Tracks the parameters of the current request
    lastUpdateTimestamp: null, // Tracks the last update time
    error: null, // Holds any error message
    lastRequestCompleted: true, // Tracks if the last request was completed
    favoriteChangesFlag: false, // Flag to track changes
    lastRequestCanceled: false, // Flag to track if the last request was canceled
});

export default {
    state: initialState(),

    mutations: {
        resetState(state) {
            console.log('[Mutation] resetState: Resetting state to initial values.');
            Object.assign(state, initialState());
        },

        SET_FAVOURITE_MUSIC_RELEASE(state, data) {
            console.log('[Mutation] SET_FAVOURITE_MUSIC_RELEASE: Setting favorite music releases.');
            state.favoriteMusicRelease = data.items || [];
            state.countFavoriteMusicRelease = data.count_results || 0;
            console.log(`[Mutation] SET_FAVOURITE_MUSIC_RELEASE: Count of favorite releases set to ${state.countFavoriteMusicRelease}.`);
        },

        SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE(state, page) {
            console.log(`[Mutation] SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE: Changing current page to ${page}.`);
            state.favoriteMusicReleaseCurrentPage = page;
        },

        SET_FAVOURITE_MUSIC_RELEASE_LOADING(state, isLoading) {
            console.log(`[Mutation] SET_FAVOURITE_MUSIC_RELEASE_LOADING: Setting loading state to ${isLoading}.`);
            state.favoriteMusicReleaseLoading = isLoading;
        },

        SET_CURRENT_PARAMS(state, params) {
            state.currentParams = params;
            console.log(`[Mutation] SET_CURRENT_PARAMS: Current params set to ${JSON.stringify(params)}.`);
        },

        SET_LAST_UPDATE_TIMESTAMP(state, timestamp) {
            state.lastUpdateTimestamp = timestamp;
            console.log(`[Mutation] SET_LAST_UPDATE_TIMESTAMP: Last update timestamp set to ${timestamp}.`);
        },

        SET_ERROR(state, error) {
            state.error = error;
            console.log(`[Mutation] SET_ERROR: Error set to "${error}".`);
        },

        SET_ACTIVE_REQUEST(state, controller) {
            state.activeReq = controller;
            console.log('[Mutation] SET_ACTIVE_REQUEST: Active request controller set.');
        },

        SET_LAST_REQUEST_COMPLETED(state, completed) {
            state.lastRequestCompleted = completed;
            console.log(`[Mutation] SET_LAST_REQUEST_COMPLETED: Last request completed status set to ${completed}.`);
        },

        SET_FAVORITE_CHANGES_FLAG(state, value) {
            state.favoriteChangesFlag = value;
            console.log(`[Mutation] SET_FAVORITE_CHANGES_FLAG: Set to ${value}`);
        },

        SET_LAST_REQUEST_CANCELED(state, value) {
            state.lastRequestCanceled = value;
            console.log(`[Mutation] SET_LAST_REQUEST_CANCELED: Set to ${value}`);
        },
    },

    actions: {
        async GET_FAVOURITE_MUSIC_RELEASE({ commit, state }, params) {
            console.log('[Action] GET_FAVOURITE_MUSIC_RELEASE: Initiating fetch for favorite music releases.');

            // Check if we need to fetch new data
            if (!state.lastRequestCanceled && !state.favoriteChangesFlag && 
                state.currentParams && JSON.stringify(state.currentParams) === JSON.stringify(params) &&
                state.favoriteMusicRelease.length > 0) {
                console.log('[Action] GET_FAVOURITE_MUSIC_RELEASE: No changes detected. Using cached data.');
                return;
            }

            // Reset the flags
            commit('SET_FAVORITE_CHANGES_FLAG', false);
            commit('SET_LAST_REQUEST_CANCELED', false);

            // Set the new params
            commit('SET_CURRENT_PARAMS', params);

            // Set loading state to true and clear previous error
            commit('SET_FAVOURITE_MUSIC_RELEASE_LOADING', true);
            commit('SET_ERROR', null);
            commit('SET_LAST_REQUEST_COMPLETED', false);

            // Abort any ongoing request
            if (state.activeReq) {
                console.log('[Action] GET_FAVOURITE_MUSIC_RELEASE: Aborting previous request.');
                state.activeReq.abort();
            }

            // Create a new AbortController for the new request
            const controller = new AbortController();
            commit('SET_ACTIVE_REQUEST', controller);
            const signal = controller.signal;
            console.log('[Action] GET_FAVOURITE_MUSIC_RELEASE: New AbortController created and associated with the request.');

            try {
                console.log('[Action] GET_FAVOURITE_MUSIC_RELEASE: Sending GET request to "favorite_releases" endpoint.');
                const response = await axios.get('favorite_releases', { 
                    signal, 
                    params: {
                        ...params,
                        last_update: state.lastUpdateTimestamp
                    }
                });

                // Commit the fetched data to the state
                commit('SET_FAVOURITE_MUSIC_RELEASE', response.data);
                commit('SET_LAST_UPDATE_TIMESTAMP', new Date().toISOString());
                commit('SET_LAST_REQUEST_COMPLETED', true);
                console.log('[Action] GET_FAVOURITE_MUSIC_RELEASE: Data fetched and stored successfully.');
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.warn('[Action] GET_FAVOURITE_MUSIC_RELEASE: Request was canceled.');
                    commit('SET_LAST_REQUEST_CANCELED', true);
                } else {
                    console.error('[Action] GET_FAVOURITE_MUSIC_RELEASE: An error occurred while fetching data.', error);
                    commit('SET_ERROR', error.message || 'An error occurred while fetching favorite releases.');
                }
                commit('SET_LAST_REQUEST_COMPLETED', false);
            } finally {
                // Reset the activeReq since the request has completed or been cancelled
                commit('SET_ACTIVE_REQUEST', null);
                commit('SET_FAVOURITE_MUSIC_RELEASE_LOADING', false);
            }
        },

        CANCEL_FAVOURITE_MUSIC_RELEASE_REQUEST({ state, commit }) {
            if (state.activeReq) {
                console.log('[Action] CANCEL_FAVOURITE_MUSIC_RELEASE_REQUEST: Aborting active request.');
                state.activeReq.abort();
                commit('SET_ACTIVE_REQUEST', null);
                commit('SET_LAST_REQUEST_CANCELED', true);
                console.log('[Action] CANCEL_FAVOURITE_MUSIC_RELEASE_REQUEST: Active request aborted.');
                return true;
            } else {
                console.log('[Action] CANCEL_FAVOURITE_MUSIC_RELEASE_REQUEST: No active request to abort.');
                return false;
            }
        },

        async ADD_ALBUM_TO_FAVORITES({ commit }, { music_release_id: musicReleaseID }) {
            console.log(`[Action] ADD_ALBUM_TO_FAVORITES: Attempting to add album with ID ${musicReleaseID} to favorites.`);

            if (!musicReleaseID) {
                console.error('[Action] ADD_ALBUM_TO_FAVORITES: Missing music_release_id parameter.');
                return;
            }

            try {
                console.log('[Action] ADD_ALBUM_TO_FAVORITES: Sending POST request to "add_favorite_song" endpoint.');
                await axios.post('add_favorite_song', { song_id: "", music_release_id: musicReleaseID });
                console.log(`[Action] ADD_ALBUM_TO_FAVORITES: Album ${musicReleaseID} added to favorites successfully.`);
                commit('SET_FAVORITE_CHANGES_FLAG', true);
            } catch (error) {
                console.error(`[Action] ADD_ALBUM_TO_FAVORITES: Failed to add album ${musicReleaseID} to favorites.`, error);
            }
        },

        async REMOVE_ALBUM_FROM_FAVORITES({ commit }, { music_release_id: musicReleaseID }) {
            console.log(`[Action] REMOVE_ALBUM_FROM_FAVORITES: Attempting to remove album with ID ${musicReleaseID} from favorites.`);

            if (!musicReleaseID) {
                console.error('[Action] REMOVE_ALBUM_FROM_FAVORITES: Missing music_release_id parameter.');
                return;
            }

            try {
                console.log('[Action] REMOVE_ALBUM_FROM_FAVORITES: Sending DELETE request to "remove_favorite_music_release" endpoint.');
                await axios.delete('remove_favorite_music_release', { data: { song_id: "", music_release_id: musicReleaseID } });
                console.log(`[Action] REMOVE_ALBUM_FROM_FAVORITES: Album ${musicReleaseID} removed from favorites successfully.`);
                commit('SET_FAVORITE_CHANGES_FLAG', true);
            } catch (error) {
                console.error(`[Action] REMOVE_ALBUM_FROM_FAVORITES: Failed to remove album ${musicReleaseID} from favorites.`, error);
            }
        },

        RESET_FAVORITE_REQUEST_STATUS({ commit }) {
            commit('SET_LAST_REQUEST_COMPLETED', false);
            console.log('[Action] RESET_FAVORITE_REQUEST_STATUS: Reset last request completed status.');
        }
    },

    getters: {
        FAVOURITE_MUSIC_RELEASE: state => state.favoriteMusicRelease,
        COUNT_FAVOURITE_MUSIC_RELEASE: state => state.countFavoriteMusicRelease,
        FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE: state => state.favoriteMusicReleaseCurrentPage,
        FAVOURITE_MUSIC_RELEASE_LOADING: state => state.favoriteMusicReleaseLoading,
        LAST_REQUEST_COMPLETED: state => state.lastRequestCompleted,
        ERROR: state => state.error,
        FAVORITE_CHANGES_FLAG: state => state.favoriteChangesFlag,
        LAST_REQUEST_CANCELED: state => state.lastRequestCanceled,
    },
};