import axios from "axios";

const initialState = () => ({
    activeReq: null,

    activeTab: 'collection',
    albums: [],
    countAlbums: '',
    albumsCurrentPage: 1,
    albumsLoading: false,
    // albumsOrderBy: { sort: 'releasedAt', order: 'desc'},
    albumsOrderBy: { sort: 'dateLiked', order: 'desc'},

    tracks: [],
    countTracks: '',
    tracksCurrentPage: 1,
    tracksOrderBy: { 'order[song.bpm]': 'asc' },
    tracksLoading: false,

    libraryFilter: '',
    subgenre: {},
    bpm: [],
    keys: [],
    applyKeys: [],
    tags: [],
    viewAlbums: 'grid',
});

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState);
        },
        SET_ACTIVE_TAB: (state, tab) => {
            state.activeTab = tab;
        },
        SET_ALBUMS: (state, data) => {
            state.albums = data.items;
            state.countAlbums = data.count_results;
        },
        SET_ALBUMS_CURRENT_PAGE: (state, currentPage) => {
            state.albumsCurrentPage = currentPage;
        },
        SET_ALBUMS_ORDER_BY: (state, data) => {
            state.albumsOrderBy = data;
        },
        SET_ALBUMS_LOADING: (state, data) => {
          state.albumsLoading = data;
        },

        SET_TRACKS: (state, data) => {
            state.tracks = data['hydra:member']
            state.countTracks = data['hydra:totalItems'];
        },
        SET_TRACKS_CURRENT_PAGE: (state, currentPage) => {
            state.tracksCurrentPage = currentPage;
        },
        SET_TRACKS_ORDER_BY: (state, data) => {
            console.log('collection.js - SET_TRACKS_ORDER_BY', data);
            state.tracksOrderBy = data;
        },
        SET_TRACKS_LOADING: (state, data) => {
            state.tracksLoading = data;
        },


        // filters
        SET_VIEW_ALBUMS: (state, data) => {
            state.viewAlbums = data;
        },
        SET_LIBRARY_FILTER: (state, status) => {
          state.libraryFilter = status;
        },
        SET_SUBGENRE: (state, subgenre) => {
            state.subgenre = subgenre;
        },
        SET_BPM: (state, bpm) => {
            state.bpm = bpm;
        },
        SET_KEYS: (state, keys) => {
            state.keys = keys;
        },
        SET_APPLY_KEYS: (state, data) => {
            state.applyKeys = data;
        },
        SET_TAGS: (state, tags) => {
            state.tags = tags;
        },
    },
    actions: {
        CLEAR_FILTERS: ({commit}) => {
            console.log('collection CLEAR_FILTERS');
            commit('SET_SUBGENRE', {});
            commit('SET_BPM', []);
            commit('SET_KEYS', []);
            commit('SET_TAGS', []);
            commit('SET_APPLY_KEYS', []);
        },

        GET_ALBUMS({commit, state}, params) {
            commit('SET_ALBUMS_LOADING', true);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            // let empty = {
            //     'hydra:member': [],
            //     'hydra:totalItems': ''
            // }
            // commit('SET_ALBUMS', empty);

            return axios.get('favorite_releases', {signal, params: params})
                .then(response => {
                    commit('SET_ALBUMS_LOADING', false);
                    commit('SET_ALBUMS', response.data);
                })
                .catch(error => console.log(`collection, GET_ALBUMS ${error}`));
        },
        GET_TRACKS({commit, state}, params) {
            commit('SET_TRACKS_LOADING', true);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_TRACKS', empty);

            return axios.get('favorite_tracks', {signal, params: params})
                .then(response => {
                    commit('SET_TRACKS_LOADING', false);
                    commit('SET_TRACKS', response.data);
                })
                .catch(err => {
                    console.log(`SET_TRACKS ${err}`);
                });
        },

        UPDATE_ALBUMS_ORDER_BY: ({commit}, orderBy) => {
            commit('SET_ALBUMS_ORDER_BY', orderBy);
        },
    },
    getters: {
        ACTIVE_TAB: state => state.activeTab,
        ALBUMS: state => state.albums,
        COUNT_ALBUMS: state => state.countAlbums,
        ALBUMS_CURRENT_PAGE: state => state.albumsCurrentPage,
        ALBUMS_ORDER_BY: state => state.albumsOrderBy,
        ALBUMS_LOADING: state => state.albumsLoading,


        TRACKS: state => state.tracks,
        COUNT_TRACKS: state => state.countTracks,
        TRACKS_CURRENT_PAGE: state => state.tracksCurrentPage,
        TRACKS_ORDER_BY: state => state.tracksOrderBy,
        TRACKS_LOADING: state => state.tracksLoading,

        VIEW_ALBUMS: state => state.viewAlbums,
        LIBRARY_FILTER: state => state.libraryFilter,
        SUBGENRE: state => state.subgenre,
        BPM: state => state.bpm,
        KEYS: state => state.keys,
        APPLY_KEYS: state => state.applyKeys,
        TAGS: state => state.tags,
    }
}
