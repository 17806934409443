import axios from 'axios';

const initialState = () => ({
    comment: {},
    comments: [],
    commentsLoader: false,
    countComments: '',

    // album
    albumComments: [],
    albumCountComments: '',
    commentsOrderBy: 'Newest',

    // suggestion
    suggestionComments: [],
    suggestionCountComments: '',

    createCommentData: '',
    editCommentStatus: '',
    deleteCommentStatus: '',
});

export default {
    state: initialState(),

    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_COMMENT: (state, data) => {
            state.comment = data;
        },

        // albums
        SET_COMMENTS: (state, data) => {
            state.comments = data['hydra:member'];
            state.countComments = data['hydra:totalItems'];
        },
        COMMENTS_LOADER: (state, data) => {
            state.commentsLoader = data;
        },

        // suggestions
        // SET_SUGGESTION_COMMENTS: (state, data) => {
        //     state.suggestionComments = data['hydra:member'];
        // },
        // SET_SUGGESTION_COUNT_COMMENTS: (state, data) => {
        //     state.suggestionCountComments = data['hydra:totalItems'];
        // },


        SET_CREATE_COMMENT_DATA: (state, data) => {
            state.createCommentData = data;
        },
        SET_EDIT_COMMENT_STATUS: (state, data) => {
            state.editCommentStatus = data;
        },
        SET_DELETE_COMMENT_STATUS: (state, data) => {
            state.deleteCommentStatus = data;
        },

        COMMENTS_ORDER_BY: (state, orderBy) => {
            state.commentsOrderBy = orderBy;
        }
    },
    actions: {
        CLEAN_RELEASE_COMMENTS({commit}) {
            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_COMMENTS', empty);
        },
        GET_COMMENT({commit}, id) {
            return axios.get(`comments/${id}`,)
                .then((response) => {
                    commit('SET_COMMENT', response.data);
                })
                .catch(err => {
                    console.log(`GET_COMMENT ${err}`);
                })
        },

        // GET_ALBUM_COMMENTS({commit}, params) {
        GET_COMMENTS({commit}, params) {
            commit('COMMENTS_LOADER', true);
            return axios.get(`comments`, {params: params})
                .then((response) => {
                    commit('SET_COMMENTS', response.data);
                })
                .catch(err => console.log(`GET_COMMENTS ${err}`))
                .finally(() => commit('COMMENTS_LOADER', false));
        },
        // GET_SUGGESTION_COMMENTS({commit}, params) {
        //     return axios.get(`comments`, {params: params})
        //         .then((response) => {
        //             commit('SET_SUGGESTION_COMMENTS', response.data);
        //             commit('SET_SUGGESTION_COUNT_COMMENTS', response.data);
        //         })
        //         .catch(err => console.log(`GET_SUGGESTION_COMMENTS ${err}`));
        // },

        CREATE_COMMENT({commit}, comment) {
            return axios.post(`comments`, comment)
                .then((response) => {
                    commit('SET_CREATE_COMMENT_DATA', response.data);
                })
                .catch(err => {
                    console.log(`CREATE_COMMENT ${err}`);
                });
        },
        EDIT_COMMENT({commit}, comment) {
            return axios.patch(`comments/${comment.id}`, {'text': comment.text}, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then(() => {
                    commit('SET_EDIT_COMMENT_STATUS', 'success');
                })
                .catch((err) => {
                    console.log(`EDIT_COMMENT ${err}`);
                    commit('SET_EDIT_COMMENT_STATUS', 'failed');
                })
        },
        DELETE_COMMENT({commit}, id) {
            return axios.delete(`comments/${id}`).catch(err => console.error(`DELETE_COMMENT ${err}`))
        },
        ADD_LIKE_COMMENT({commit}, commentID) {
            return axios.post(`comments/${commentID}/like`).catch(err => console.log(`ADD_LIKE_COMMENT ${err}`));
        },
        DELETE_LIKE_COMMENT({commit}, commentID) {
            return axios.delete(`comments/${commentID}/like`).catch(err => console.log(`DELETE_COMMENT ${err}`));
        }
    },
    getters: {
        COMMENTS_ORDER_BY: state => state.commentsOrderBy,

        COMMENT: state => state.comment,
        COMMENTS: state => state.comments,
        COMMENTS_LOADER: state => state.commentsLoader,
        COUNT_COMMENTS: state => state.countComments,

        CREATE_COMMENT_DATA: state => state.createCommentData,
        EDIT_COMMENT_STATUS: state => state.editCommentStatus,
        DELETE_COMMENT_STATUS: state => state.deleteCommentStatus,
    }
}
