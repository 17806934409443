import axios from 'axios';

const initialState = () => ({
    activeReq: null,
    subscriptionLink: '',
    createSubscriptionData: null,

    subscription: null,
    allSubscriptions: null,
    limitFounders: null,

    // loader get subscription
    loaderGetSubscription: false,
    loaderGetAllSubscriptions: false,

    // loader add subscription
    loaderAddSubscription: false,
    loaderAddFoundersSubscription: false,

    // loader remove && reactivate subscription
    loaderRemoveSubscription: false,
    loaderReactivateSubscription: false,
});

export default {
    state: initialState(),

    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_SUBSCRIPTION_LINK: (state, data) => {
            state.subscriptionLink = data;
        },
        SET_CREATE_SUBSCRIPTION_DATA: (state, data) => {
            state.createSubscriptionData = data;
        },
        SET_SUBSCRIPTION: (state, data) => {
            state.subscription = data;
        },
        SET_ALL_SUBSCRIPTIONS: (state, data) => {
            state.allSubscriptions = data;
        },

        // loaders get subscription
        SET_LOADER_GET_SUBSCRIPTION: (state, data) => {
            state.loaderGetSubscription = data;
        },
        SET_LOADER_GET_ALL_SUBSCRIPTIONS: (state, data) => {
            state.loaderGetAllSubscriptions = data;
        },

        // loader add subscription
        SET_LOADER_ADD_SUBSCRIPTION: (state, data) => {
            state.loaderAddSubscription = data;
        },
        SET_LOADER_ADD_FOUNDERS_SUBSCRIPTION: (state, data) => {
            state.loaderAddFoundersSubscription = data;
        },
        SET_LIMIT_FOUNDERS: (state, data) => {
            state.limitFounders = data;
        },


        // loader remove && reactivate subscription
        SET_LOADER_REMOVE_SUBSCRIPTION: (state, data) => {
            state.loaderRemoveSubscription = data;
        },
        SET_LOADER_REACTIVATE_SUBSCRIPTION: (state, data) => {
            state.loaderReactivateSubscription = data;
        }

    },
    actions: {
        // get subscription
        GET_SUBSCRIPTION({commit}) {
            commit('SET_LOADER_GET_SUBSCRIPTION', true);
            return axios.get(`user/subscriptions/current`)
                .then((response) => {
                    commit('SET_SUBSCRIPTION', response.data.result);
                })
                .catch(err => console.error(`GET_SUBSCRIPTION, ${err}`))
                .finally(() => {
                    commit('SET_LOADER_GET_SUBSCRIPTION', false);
                })
        },
        GET_ALL_SUBSCRIPTIONS({state, commit}) {
            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            commit('SET_LOADER_GET_ALL_SUBSCRIPTIONS', true);
            return axios.get(`user/subscriptions`, signal)
                .then((response) => {
                    commit('SET_ALL_SUBSCRIPTIONS', response.data.result);
                })
                .catch(err => console.error(`GET_ALL_SUBSCRIPTIONS, ${err}`))
                .finally(() => {
                    commit('SET_LOADER_GET_ALL_SUBSCRIPTIONS', false);
                })
        },

        // add subscription
        ADD_SUBSCRIPTION({commit}, data) {
            commit('SET_LOADER_ADD_SUBSCRIPTION', true);
            return axios.post(`user/subscriptions`, data)
                .then((response) => {
                    commit('SET_CREATE_SUBSCRIPTION_DATA', response.data);
                })
                .catch(err => console.error(`ADD_PREMIUM_SUBSCRIPTION, ${err}`))
                .finally(() => {
                    commit('SET_LOADER_ADD_SUBSCRIPTION', false);
                })
        },
        ADD_FOUNDERS_SUBSCRIPTION({commit}, data) {
            commit('SET_LOADER_ADD_FOUNDERS_SUBSCRIPTION', true);
            return axios.post(`user/founders`, data)
                .then((response) => {
                    commit('SET_CREATE_SUBSCRIPTION_DATA', response.data.result);
                })
                .catch(err => console.error(`ADD_FOUNDERS_SUBSCRIPTION, ${err}`))
                .finally(() => {
                    commit('SET_LOADER_ADD_FOUNDERS_SUBSCRIPTION', false);
                })
        },
        CHECK_LIMIT_FOUNDERS({commit}) {
            return axios.get(`user/founders/check-limit`)
                .then((response) => {
                    commit('SET_LIMIT_FOUNDERS', response.data.result)
                })
                .catch(err => console.error(`CHECK_LIMIT_FOUNDERS, ${err}`))
        },


        // Remove subscription (if one subscription)
        REMOVE_SUBSCRIPTION({commit, state}, data) {
            commit('SET_LOADER_REMOVE_SUBSCRIPTION', true);

            return axios.delete(`user/subscriptions`, data)
                .catch(err => console.error(`REMOVE_SUBSCRIPTION, ${err}`))
                .finally(() => {
                    setTimeout(() => {
                        commit('SET_LOADER_REMOVE_SUBSCRIPTION', false);
                    }, 1200);
                })
        },
        // Remove subscription (if two subscriptions)
        DOWNGRADE_SUBSCRIPTION({commit, state}, data) {
            commit('SET_LOADER_REMOVE_SUBSCRIPTION', true);

            return axios.delete(`user/subscriptions/downgrade`, data)
                .catch(err => console.error(`REMOVE_SUBSCRIPTION, ${err}`))
                .finally(() => {
                    setTimeout(() => {
                        commit('SET_LOADER_REMOVE_SUBSCRIPTION', false);
                    }, 1200);
                })
        },


        // Reactivate subscription
        REACTIVATE_SUBSCRIPTION({commit, state}, data) {
            commit('SET_LOADER_REACTIVATE_SUBSCRIPTION', true);

            return axios.patch(`user/subscriptions/reactivating`, data)
                .then((response) => {
                    commit('SET_SUBSCRIPTION_LINK', response.data.result);
                })
                .catch(err => console.error(`REACTIVATE_SUBSCRIPTION, ${err}`))
                .finally(() => {
                    setTimeout(() => {
                        commit('SET_LOADER_REACTIVATE_SUBSCRIPTION', false);
                    }, 1000);
                })
        },
    },
    getters: {
        SUBSCRIPTION_LINK: state => state.subscriptionLink,
        CREATE_SUBSCRIPTION_DATA: state => state.createSubscriptionData,

        SUBSCRIPTION: state => state.subscription,
        ALL_SUBSCRIPTION: state => state.allSubscriptions,
        LIMIT_FOUNDERS: state => state.limitFounders,

        // loaders get subscription
        LOADER_GET_SUBSCRIPTION: state => state.loaderGetSubscription,
        LOADER_GET_ALL_SUBSCRIPTIONS: state => state.loaderGetAllSubscriptions,

        // loader add subscription
        LOADER_ADD_SUBSCRIPTION: state => state.loaderAddSubscription,
        LOADER_ADD_FOUNDERS_SUBSCRIPTION: state => state.loaderAddFoundersSubscription,

        // loader remove && reactivate subscription
        LOADER_REMOVE_SUBSCRIPTION: state => state.loaderRemoveSubscription,
        LOADER_REACTIVATE_SUBSCRIPTION: state => state.loaderReactivateSubscription,


    }
}