import SongKey from '@/models/songKey'

export function LOAD_SONG_KEYS (state, data) {
  if (!data) {
    state.songKeys = []

    return
  }

  state.songKeys = data.map((item) => {
    return new SongKey(item)
  })
}
