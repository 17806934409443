const initialState = () => ({
    topFilter: 'all',
});
export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_TOP_FILTER: (state, data) => {
            state.topFilter = data;
        },
    },
    actions: {

    },
    getters: {
        TOP_FILTER: state => state.topFilter,
    }
}