import axios from 'axios';

const initialState = () => ({
    purchases: '',
    purchasesLoader: false,
});

export default {
    state: initialState(),
    mutations: {
        SET_PURCHASES: (state, data) => {
            state.purchases = data;
        },
        SET_PURCHASES_LOADER: (state, data) => {
            state.purchasesLoader = data;
        }
    },
    actions: {
        GET_PURCHASES({ commit }, params) {
            commit('SET_PURCHASES', '');
            commit('SET_PURCHASES_LOADER', true);
            setTimeout(() => {
                return axios.get(`/user/purchases`, {params: params})
                    .then((response) => {
                        commit('SET_PURCHASES', response.data.result);
                    })
                    .catch(err => console.error(`GET_PURCHASES ${err}`))
                    .finally(() => commit('SET_PURCHASES_LOADER', false));
            }, 750);
        }
    },
    getters: {
        PURCHASES: state => state.purchases,
        PURCHASES_LOADER: state => state.purchasesLoader,
    }
}