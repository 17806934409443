<template>
    <div>
      <v-bottom-sheet :content-class="'sub_genres_wrap'" v-model="localDialog">
        <template v-slot:activator="{ on }">
          <div class="mobile_filter_item" v-on="on">
            <span class="top">BPM</span>
            <span class="bottom">{{displayValue}}</span>
          </div>
        </template>
        <v-sheet class="text-left subGenresDialog" @click.stop>
          <div class="style_dialog subGenres bpm-dialog" @click.stop>
            <h2 class="header">
              BPM
            </h2>
            <div class="subGenresItems bpm-wrap">
              <v-list>
                <div class="bpm_body">
                  <div class="wrap_inputs">
                    <input type="text" v-model="localRange[0]" maxlength="3">
                    <span>-</span>
                    <input type="text" v-model="localRange[1]" maxlength="3">
                  </div>
  
                  <div class="wrap_slider">
                    <v-range-slider
                        v-model="localRange"
                        :min="0"
                        :max="300"
                        :step="1"
                        hide-details
                        :thumb-color="'#7481A6'"
                        :thumb-size='20'
                        :tick-size='100'
                        class="align-center"
                        @start="onSliderStart"
                        @end="onSliderEnd">
                    </v-range-slider>
                  </div>
                </div>
              </v-list>
            </div>
  
            <div class="modal_wrap_actions">
              <button type="button" class="unsubscribe" v-ripple @click="cancelBPM">Clear</button>
              <button type="button" class="keep_it" v-ripple @click="saveBPM">Apply</button>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BpmFilterCard',
    props: {
      value: {
        type: Array,
        default: () => []
      },
      dialog: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        localRange: [0, 300],
        localDialog: false
      };
    },
    computed: {
      displayValue() {
        return this.value.length > 0 ? `${this.value[0]} - ${this.value[1]}` : '-';
      }
    },
    watch: {
      value: {
        handler(newValue) {
          this.localRange = newValue.length > 0 ? newValue : [0, 300];
        },
        immediate: true
      },
      dialog(newValue) {
        this.localDialog = newValue;
      },
      localDialog(newValue) {
        this.$emit('update:dialog', newValue);
      }
    },
    methods: {
      onSliderStart() {
        document.body.style.overflow = 'hidden';
      },
      onSliderEnd() {
        document.body.style.overflow = '';
      },
      saveBPM() {
        this.$emit('input', this.localRange);
        this.localDialog = false;
      },
      cancelBPM() {
        this.localRange = [0, 300];
        this.$emit('input', []);
        this.localDialog = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .mobile_filter_item {
    width: calc((100vw - 40px) / 3);
    border-left: 1px solid #2E3548;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .mobile_filter_item .top {
    color: #8591B1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .mobile_filter_item .bottom {
    color: #FFF;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .style_dialog.subGenres.bpm-dialog .bpm_body {
    padding: 0;
  }
  
  .style_dialog.subGenres.bpm-dialog .wrap_inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .style_dialog.subGenres.bpm-dialog .wrap_inputs > input {
    height: 50px;
    width: 45%;
    max-width: 156px;
    background-color: transparent;
    border: 1px solid #5F6986;
    border-radius: 8px;
    color: #FFF;
    padding: 0 10px;
    font-size: 16px;
  }
  
  .style_dialog.subGenres.bpm-dialog .wrap_slider {
    padding: 0 10px;
    touch-action: none;
  }
  
  .style_dialog.subGenres.bpm-dialog .v-slider.v-slider--horizontal {
    margin-top: 34px !important;
    margin-right: 22px !important;
  }
  
  .style_dialog.subGenres.bpm-dialog .v-slider__track-container {
    height: 6px !important;
  }
  
  .style_dialog.subGenres.bpm-dialog .v-slider__thumb {
    width: 20px !important;
    height: 20px !important;
    background-color: #7481A6 !important;
  }
  
  .style_dialog.subGenres.bpm-dialog .v-slider__thumb-container {
    top: calc(50% - 10px) !important;
  }
  
  .modal_wrap_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  
  .modal_wrap_actions > button {
    display: flex;
    padding: 11px 0px;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--icon-purple, #5F6986);
    width: 156px;
    justify-content: center;
    background-color: transparent;
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal_wrap_actions > button.keep_it {
    background-color: #5F6986;
  }
  
  .modal_wrap_actions > button:hover {
    background-color: rgba(95, 105, 134, 0.8);
  }
  </style>