export function getSongKeys(state) {
  return state.songKeys;
}

export function songKeysGroupA(state) {
  return state.songKeys.filter(key => key.group === 'A');
}

export function songKeysGroupB(state) {
  return state.songKeys.filter(key => key.group === 'B');
}

