<template>
	<div
		class="mj-modal-image"
		:class="{'image-zoom-in': isZoomIn, 'can-be-zoom': canBeZoom}"
		v-show="showModal"
		@click="hide"
		ref="image_modal">

		<div class="close-modal" @click="showModal = false"></div>
		<div class="image-container" ref="image_container">
			<img
				:src="image"
				@click="zoom"
				ref="image"
				@load="canBeZoomCheck"
				alt="zoom image"
				class="mj-modal-main-image">
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
	name: "modalImage",

	props: {
		image: {
			default: ''
		}
	},

	data() {
		return {
			overlay: false,
			isZoomIn: false,
			canBeZoom: false,
			imageWidth: 0,
			imageHeight: 0,
			windowHeight: 0,
			showModal: false
		}
	},
	watch: {
		showModal(val) {
			const mainElement = document.querySelector('main.v-main')
			const body = document.body;

			if (val) {
				mainElement.style.cssText = 'position: relative; z-index: 10000;';
				body.style.overflow = 'hidden';
			} else {
				mainElement.style.cssText = '';
				body.style.overflow = '';
			}
		},
		isZoomIn(val) {
			const modalEl = this.$refs.image_modal;
			if (modalEl) {
				modalEl.style.overflow = val ? 'auto' : 'hidden';
				// Force scrollbar to be hidden when not zoomed
				modalEl.style.scrollbarWidth = val ? 'auto' : 'none';
				modalEl.style.msOverflowStyle = val ? 'auto' : 'none';
			}
		}
	},
	computed: {
		...mapGetters(['CURRENT_PAGE'])
	},

	beforeDestroy() {
		const mainElement = document.querySelector('main.v-main');
		const body = document.body;
		mainElement.style.cssText = '';
		body.style.overflow = '';
		this.showModal = false;
	},
	methods: {
		handlePopstate(event) {
			console.log('back btn', event);
			this.hide();
		},
		zoom() {
			if (this.canBeZoom) {
				this.isZoomIn = !this.isZoomIn;
			}
		},

		canBeZoomCheck() {
			if (this.$refs.image) {
				this.imageWidth = this.$refs.image.naturalWidth;
				this.imageHeight = this.$refs.image.naturalHeight;
				this.windowHeight = window.innerHeight;

				if (this.imageWidth < this.imageHeight) {
					this.canBeZoom = true;
				}

				if (this.windowHeight > this.imageHeight) {
					this.$refs.image_container.style.cssText = 'display: flex; justify-content: center; align-items: center;';
				}
			}
		},

		show() {
			this.showModal = true;
			this.isZoomIn = false;
			document.addEventListener('keydown', this.hide);
			if (this.$refs.image_modal) {
				this.$refs.image_modal.style.overflow = 'hidden';
				// Initially hide scrollbar
				this.$refs.image_modal.style.scrollbarWidth = 'none';
				this.$refs.image_modal.style.msOverflowStyle = 'none';
			}
		},

		hide(event) {
			console.log('hide');
			if (event.type === "keydown" && event.code === "Escape") {
				this.showModal = false;
				document.removeEventListener('keydown', this.hide);
			}

			let clickedElement = event.srcElement;
			if (event.type === "click" && !clickedElement.classList.contains('mj-modal-main-image')) {
				this.showModal = false;
				document.removeEventListener('keydown', this.hide);
			}
		}
	},
}
</script>

<style lang="scss">
.mj-modal-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    // Hide scrollbar by default
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    &::-webkit-scrollbar {
        display: none;  /* Chrome, Safari, Opera */
    }

    // Show scrollbar only when zoomed
    &.image-zoom-in {
        scrollbar-width: auto;
        -ms-overflow-style: auto;
        &::-webkit-scrollbar {
            display: block;
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.3);
            border-radius: 4px;
            &:hover {
                background: rgba(255, 255, 255, 0.5);
            }
        }
    }

    .close-modal {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 10001;

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: white;
            top: 50%;
            left: 0;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    .image-container {
        max-width: 100%;
        max-height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
            max-width: 100%;
            max-height: 100vh;
            object-fit: contain;
            cursor: zoom-in;
            transition: transform 0.3s ease;
        }
    }

    &.image-zoom-in {
        .image-container {
            cursor: zoom-out;
            padding: 0;
            width: 100vw;
            height: 100vh;

            img {
                max-height: unset;
                max-width: 100vw;
                height: 100vh;
                object-fit: cover;
                transform: scale(1);
            }
        }
    }

    &.can-be-zoom {
        .image-container {
            img {
                cursor: zoom-in;
            }
        }
    }
}
</style>
