<template>
    <div class="top_welcome_banner" v-if="!isSetUnderstand">
        <v-container>
            <div class="message">
                This project is in <router-link :to="{name: 'faq'}" class="faq-link">alpha development stage.</router-link>
                Comments and other aspects of the site may be reset before the official launch.
            </div>
            <a href="#" class="understand" @click.prevent="understand">I understand</a>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "topWelcomeBanner",

    data() {
        return {
            isSetUnderstand: true // Change this to true initially
        }
    },

    mounted() {
        // Since we're forcing the banner to be hidden, we can remove the mounted hook
        // or keep it just for clearing localStorage
        localStorage.removeItem('psytranc_understand');
    },

    methods: {
        understand() {
            // This method can be kept for when you want to re-enable the banner later
            localStorage.setItem('psytranc_understand', 'isSet');
            this.isSetUnderstand = true;
        }
    }
}
</script>

