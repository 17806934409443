import axios from 'axios';

const initialState = () => ({
    activeReq: null,

    // FAVORITE_TRACKS
    favoriteTracks: [],
    countFavoriteTracks: '',
    favoriteTracksCurrentPage: 1,
    favoriteTracksLoading: false,

    downloadSongLink: '',
    downloadSongLinkError: '',

    songDownloaded: {},
    songDownloadData: {},

    // New flag for favorite changes
    favoriteChangesFlag: false
});

export default {
    state: {
        ...initialState(),

        // TRACKS
        tracks: [],
        countTracks: '',
        tracksCurrentPage: 1,
        tracksLoading: false,
        tracksOrderBy: {
            'order[bpm]': 'asc'
        },
        showColumnsTrack: [
            'title',
            'artist',
            'released',
            'bpm',
            'key'
        ],
    },
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },

        // TRACKS
        SET_TRACKS: (state, data) => {
            state.tracks = data['hydra:member'];
            state.countTracks = data['hydra:totalItems'];
        },
        SET_TRACKS_CURRENT_PAGE: (state, data) => {
            state.tracksCurrentPage = data;
        },
        SET_TRACKS_LOADING: (state, data) => {
            state.tracksLoading = data;
        },

        SET_TRACKS_ORDER_BY: (state, orderBy) => {
            console.log('SET_TRACKS_ORDER_BY - orderBy', orderBy);
            state.tracksOrderBy = orderBy;
        },
        SET_SHOW_COLUMNS_TRACK: (state, columns) => {
            state.showColumnsTrack = columns;
        },

        // FAVORITE_TRACKS
        SET_FAVORITE_TRACKS: (state, data) => {
            state.favoriteTracks = data['hydra:member'];
            state.countFavoriteTracks = data['hydra:totalItems'];
        },
        SET_FAVORITE_TRACKS_CURRENT_PAGE: (state, data) => {
            state.favoriteTracksCurrentPage = data;
        },
        SET_FAVORITE_TRACKS_LOADING: (state, data) => {
            state.favoriteTracksLoading = data;
        },

        SET_CHECK_SONG_DOWNLOAD: (state, data) => {
            state.songDownloaded = data;
        },
        SET_DOWNLOAD_SONG_LINK: (state, data) => {
            state.downloadSongLink = data;
        },
        SET_DOWNLOAD_SONG_LINK_ERROR: (state, data) => {
            state.downloadSongLinkError = data;
        },
        SET_SONG_DOWNLOAD_DATA: (state, data) => {
            state.songDownloadData = data;
        },

        // New mutation for favorite changes flag
        SET_FAVORITE_CHANGES_FLAG: (state, value) => {
            state.favoriteChangesFlag = value;
            console.log(`[Mutation] SET_FAVORITE_CHANGES_FLAG: Set to ${value}`);
        }
    },
    actions: {
        GET_TRACKS({commit, state}, params) {
            commit('SET_TRACKS_LOADING', true);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_TRACKS', empty);

            return axios.get('songs', {signal, params: params})
                .then((response) => {
                    commit('SET_TRACKS_LOADING', false);
                    commit('SET_TRACKS', response.data);
                })
                .catch(err => {
                    console.log(`GET_TRACKS ${err}`);
                })
        },
        GET_FAVORITE_TRACKS({commit, state}, params) {
            commit('SET_FAVORITE_TRACKS_LOADING', true);
            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_FAVORITE_TRACKS', empty);

            return axios.get('favorite_tracks', {params: params})
                .then(response => {
                    commit('SET_FAVORITE_TRACKS_LOADING', false);
                    commit('SET_FAVORITE_TRACKS', response.data);
                })
                .catch(err => {
                    console.log(`SET_FAVORITE_TRACKS ${err}`);
                });
        },

        ADD_TRACK_TO_FAVORITES({commit}, params) {
            return axios.post('add_favorite_song', {'song_id': params.song_id, 'music_release_id': params.album_id})
                .then(() => {
                    // Set the favorite changes flag after successful addition
                    commit('SET_FAVORITE_CHANGES_FLAG', true);
                })
                .catch(err => console.log(`ADD_TRACK_TO_FAVORITES, ${err}`));
        },
        REMOVE_TRACK_FROM_FAVORITES({commit}, params) {
            return axios.delete('remove_favorite_music_release',{data:{'song_id': params.song_id, 'music_release_id': params.album_id}})
                .then(() => {
                    // Set the favorite changes flag after successful removal
                    commit('SET_FAVORITE_CHANGES_FLAG', true);
                })
                .catch(err => console.log(`REMOVE_ALBUM_FROM_FAVORITES ${err}`));
        },

        UPDATE_TRACKS_ORDER_BY: ({commit}, orderBy) => {
            console.log('UPDATE_TRACKS_ORDER_BY', orderBy);
            commit('SET_TRACKS_ORDER_BY', orderBy);
        },

        // download track
        CHECK_SONG_DOWNLOAD({commit}) {
            commit('SET_CHECK_SONG_DOWNLOAD', {});
            commit('SET_DOWNLOAD_SONG_LINK_ERROR', '');
            return axios.get(`songs/download-check`)
                .then(response => {
                    commit('SET_CHECK_SONG_DOWNLOAD', response.data.data);
                })
                .catch(err => console.error(`CHECK_SONG_DOWNLOAD, ${err}`));
        },
        GET_DOWNLOAD_SONG_LINK({commit}, songID) {
            commit('SET_DOWNLOAD_SONG_LINK', '');

            return axios.get(`song/${songID}/download`)
                .then((response) => {
                    commit('SET_DOWNLOAD_SONG_LINK', response.data.link);
                })
                .catch(err => {
                    commit('SET_DOWNLOAD_SONG_LINK_ERROR', err.response.data.error);
                    console.error(`GET_DOWNLOAD_SONG_LINK, ${err}`);
                })
                .finally(() => commit('SET_SONG_DOWNLOAD_DATA', {}))
        },

        HIDE_DELETED_SONG({commit}, songID) {
            return axios.patch(`hide_favorite_song/${songID}`)
                .catch(err => console.error(`HIDE_DELETED_SONG, ${err}`));
        }
    },
    getters: {
        // TRACKS
        TRACKS: state => state.tracks,
        COUNT_TRACKS: state => state.countTracks,
        TRACKS_CURRENT_PAGE: state => state.tracksCurrentPage,
        TRACKS_LOADING: state => state.tracksLoading,

        TRACKS_ORDER_BY: state => state.tracksOrderBy,
        SHOW_COLUMNS_TRACK: state => state.showColumnsTrack,

        // FAVORITE_TRACKS
        FAVOURITE_TRACKS: state => state.favoriteTracks,
        COUNT_FAVOURITE_TRACKS: state => state.countFavoriteTracks,
        FAVORITE_TRACKS_CURRENT_PAGE: state => state.favoriteTracksCurrentPage,
        FAVORITE_TRACKS_LOADING: state => state.favoriteTracksLoading,

        SONG_DOWNLOADED: state => state.songDownloaded,
        DOWNLOAD_SONG_LINK: state => state.downloadSongLink,
        DOWNLOAD_SONG_LINK_ERROR: state => state.downloadSongLinkError,
        SONG_DOWNLOAD_DATA: state => state.songDownloadData,

        // New getter for favorite changes flag
        FAVORITE_CHANGES_FLAG: state => state.favoriteChangesFlag
    }
}