import dashboard from "@/pages/dashboard/dashboard.vue";
import followers from "@/pages/dashboard/followers.vue";
import earnings from "@/pages/dashboard/earnings.vue";
import accounts from "@/pages/dashboard/accounts";
import uploads from "@/pages/dashboard/uploads.vue";
import events from "@/pages/dashboard/events.vue";
import merch from "@/pages/dashboard/merch.vue";
import orders from "@/pages/dashboard/orders.vue";
import promotions from "@/pages/dashboard/promotions.vue";

export default [
    {
        path: '/dashboard/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/dashboard/dashboard'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/followers',
        name: 'manageFollowers',
        component: () => import('@/pages/dashboard/followers'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/earnings',
        name: 'earnings',
        component: () => import('@/pages/dashboard/earnings'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/accounts',
        name: 'accounts',
        component: () => import('@/pages/dashboard/accounts'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/uploads',
        name: 'uploads',
        component: () => import('@/pages/dashboard/uploads'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/events',
        name: 'manageEvents',
        component: () => import('@/pages/dashboard/events'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/merch',
        name: 'merch',
        component: () => import('@/pages/dashboard/merch'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/orders',
        name: 'orders',
        component: () => import('@/pages/dashboard/orders'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/dashboard/promotions',
        name: 'promotions',
        component: () => import('@/pages/dashboard/promotions'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
];
