import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            song: this.song,
        }
    },
    watch: {
        // afterActionDialogNeedLogin() {

            // if (this.afterActionDialogNeedLogin === false) {
            //     console.log(this.song);
            //     this.removeMarkTrack(this.song);
            // }
            // console.log(this.afterActionDialogNeedLogin);
            // this.selectedAlbum = this.albumDetails ? this.albumDetails : this.album;

        // }
    },
    created() {

    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
            currentAlbum: 'CURRENT_ALBUM',
            currentTrack: 'CURRENT_TRACK',
            fullAlbum: 'FULL_ALBUM',
            fullAlbumLoading: 'FULL_ALBUM_LOADING',
            releasesCollection: 'RELEASES_COLLECTION',
            playedOnlyFavTracks: 'PLAYED_ONLY_FAV_TRACKS',
            currentPage: 'CURRENT_PAGE',
            afterActionDialogNeedLogin: 'AFTER_ACTION_DIALOG_NEED_LOGIN'
        }),
    },
    methods: {
        ...mapActions([
            'REMOVE_TRACK_FROM_FAVORITES',
        ]),
        removeLikeAlbumFromCollections(albumID) {
            if (albumID) {
                for (let collection of this.releasesCollection) {
                    let getter = this.$store.getters[collection];
                    if (getter && getter.length) {
                        let albumIndex = getter.findIndex((album) => +album.id === albumID);
                        if (albumIndex !== -1) {
                            getter[albumIndex].isFavorite = false;
                        }
                    }
                }
            } else {
                console.log('addLikeAlbumFromCollections need music release id', albumID);
            }
        },
        removeLikeTrackFromCollections(track) {
            if (track) {
                let trackID = track.id;
                for (let collection of this.TRACKS_COLLECTION) {
                    let getter = this.$store.getters[collection];
                    if (getter && getter.length) {

                        if (track.song) {
                            let trackSongIndex = getter.findIndex((track) => +track.song.id === trackID);
                            if (trackSongIndex !== -1) {
                                getter[trackSongIndex].isFavorite = false;
                            }
                        } else {
                            let trackIndex = getter.findIndex((track) => +track.id === trackID);

                            if (trackIndex !== -1) {
                                getter[trackIndex].isFavorite = false;
                            } else {
                                let trackIndex = getter.findIndex((track) => {
                                    if (!track?.song) {
                                        return +track.id === trackID;
                                    }

                                    return +track.song.id === trackID
                                });
                                if (trackIndex !== -1) {
                                    getter[trackIndex].isFavorite = false;
                                    getter[trackIndex].song.isFavorite = false;
                                }
                            }
                        }
                    }
                }
            }
        },
        checkFavTracks(playList) {
            let isFavorite = playList.find(track => track.song.isFavorite === true);
            return !!isFavorite;
        },
        removeTrackFromFavorites(song, album) {
            this.song = song;

            console.log('album', album);
            if (!album) {
                console.log('need album');
            }
            // let album;
            // if (song.playLists[0].musicRelease) {
            //     album = song.playLists[0].musicRelease;
            // } else if (song.musicRelease) {
            //     album = song.musicRelease;
            // }


            if (this.authData.accessToken) {
                this.removeMarkTrack(song);

                let albumID = album.id;
                let songID = song.id;

                this.removeLikeAlbumFromCollections(albumID);
                this.removeLikeTrackFromCollections(song);

                if (this.currentTrack && this.currentTrack.id === songID) {
                    this.currentTrack.isFavorite = false;
                    if (this.currentAlbum.playList) {
                        let isFavorite = this.currentAlbum.playList.find(track => track.song.isFavorite === true);

                        if (!isFavorite) {
                            this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
                            this.btnShowAllTracks = false;
                        }
                    }
                }
                if (this.fullAlbum && this.fullAlbum.playList) {
                    let favTrack = this.fullAlbum.playList.find(el => el.song.id === songID);
                    if (favTrack && favTrack.song) {
                        favTrack.song.isFavorite = false;
                    }
                }

                if (album.playList) {
                    // check all tracks are not favorite
                    let favTrack = album.playList.find(el => el.song.id === songID);
                    if (favTrack && favTrack.song) {
                        favTrack.song.isFavorite = false;
                        album.isFavorite = false;
                    }
                }


                if (album.playList && album.playList.length) {

                    if (this.checkFavTracks(album.playList) === false) {
                        this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
                        this.btnShowAllTracks = false;
                    } else if ((this.UPPER_FILTER === 'favorite' && this.currentPage.name === 'explore') || this.$route.params.favourite === 'favourite' || this.currentPage.name === 'library') {
                        this.btnShowAllTracks = true;
                    }
                    if (this.playedOnlyFavTracks === true) {
                        // album.playList = album.playList.filter(track => track.song.isFavorite === true);
                    }
                }

                this.$store.commit('SET_REFRESH_RELEASES', true);
                this.REMOVE_TRACK_FROM_FAVORITES({'song_id': song.id, 'album_id': albumID})
                    .then(() => {
                    })
                    .catch(err => {
                        console.log(`removeTrackFromFavorites, ${err}`);
                    })
            } else {
                this.dialogNeedLoginVisible = true;
            }
        },
        removeMarkTrack(song) {
            song.isFavorite = false;
        }
    }
}
