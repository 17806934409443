<template>
    <div>
        <div class="wrap_keys filter_keys" v-if="filterName === 'explore'">
            <div class="b_section">
                <div v-for="key in songKeysGroupB" :key="key.id" @click="select_key(key)" :class="{'active': isKeySelected(key.id)}">
                    <span class="material-icons" v-if="isKeySelected(key.id)">check_circle</span>
                    <div class="_mark">{{ key.code }}</div>
                    <div class="_name">{{ key.name}}</div>
                </div>
            </div>
            <div class="a_section">
                <div v-for="key in songKeysGroupA" :key="key.id" @click="select_key(key)" :class="{'active': isKeySelected(key.id)}">
                    <span class="material-icons" v-if="isKeySelected(key.id)">check_circle</span>
                    <div class="_mark">{{ key.code }}</div>
                    <div class="_name">{{ key.name }}</div>
                </div>
            </div>
        </div>
        <div class="wrap_keys filter_keys" v-if="filterName === 'library'">
            <div class="b_section">
                <div v-for="key in songKeysGroupB" :key="key.id" @click="select_key(key)" :class="{'active': isLibraryKeySelected(key.id)}">
                    <span class="material-icons" v-if="isLibraryKeySelected(key.id)">check_circle</span>
                    <div class="_mark">{{ key.code }}</div>
                    <div class="_name">{{ key.name }}</div>
                </div>
            </div>
            <div class="a_section">
                <div v-for="key in songKeysGroupA" :key="key.id" @click="select_key(key)" :class="{'active': isLibraryKeySelected(key.id)}">
                    <span class="material-icons" v-if="isLibraryKeySelected(key.id)">check_circle</span>
                    <div class="_mark">{{ key.code }}</div>
                    <div class="_name">{{ key.name }}</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {songKeysGroupA, songKeysGroupB} from "@/store/modules/metaData/getters";

export default {
    name: 'keys',
    props: ['filterName'],
    data() {
        return {
            // groupKeysB: [
            //     {mark: '1B', name: 'B-Major'},
            //     {mark: '2B', name: 'F-Sharp Major'},
            //     {mark: '3B', name: 'd-flat Major'},
            //     {mark: '4B', name: 'a-flat Major'},
            //     {mark: '5B', name: 'e-flat Major'},
            //     {mark: '6B', name: 'b-flat Major'},
            //     {mark: '7B', name: 'F Major'},
            //     {mark: '8B', name: 'C Major'},
            //     {mark: '9B', name: 'G Major'},
            //     {mark: '10B', name: 'D Major'},
            //     {mark: '11B', name: 'A Major'},
            //     {mark: '12B', name: 'E Major'},
            // ],
            // groupKeysA: [
            //     {mark: '1A', name: 'A-flat Minor'},
            //     {mark: '2A', name: 'E-flat Minor'},
            //     {mark: '3A', name: 'B-flat Minor'},
            //     {mark: '4A', name: 'F Minor'},
            //     {mark: '5A', name: 'C Minor'},
            //     {mark: '6A', name: 'G Minor'},
            //     {mark: '7A', name: 'D Minor'},
            //     {mark: '8A', name: 'A Minor'},
            //     {mark: '9A', name: 'E Minor'},
            //     {mark: '10A', name: 'B Minor'},
            //     {mark: '11A', name: 'F-sharp Minor'},
            //     {mark: '12A', name: 'D-flat Minor'},
            // ],
            keys: [],
            libraryKeys: [],
        }
    },
    computed: {
        ...mapGetters([
            'SONG_KEYS',
            'KEYS', 'APPLY_KEYS',

	        //Song Keys Getters
	        'songKeysGroupA',
	        'songKeysGroupB'
        ]),
        ...mapGetters({
            collectionKeys: 'collection/KEYS',
            collectionApplyKeys: 'collection/APPLY_KEYS',
        })
    },
	created() {
		this.loadSongKeys();
	},
	mounted() {
    if (!this.keys.length) {
            for (let key of this.APPLY_KEYS) {
                this.keys.push({ id: key.id, code: key.code });
            }
        }
        if (!this.libraryKeys.length) {
            for (let key of this.collectionApplyKeys) {
                this.libraryKeys.push({id: key.id, code: key.code});
            }
        }
    },

  methods: {
        ...mapActions(['GET_SONG_KEYS', 'loadSongKeys']),

        select_key(key) {
            if (this.filterName === 'explore') {
	            const index = this.keys.findIndex(item => item.id === key.id);
	            if (index !== -1) {
		            this.keys.splice(index, 1);
	            } else {
		            this.keys.push({ id: key.id, code: key.code });
	            }

                this.$store.dispatch('UPDATE_KEYS', this.keys);
            }


            if (this.filterName === 'library') {
	            const index = this.libraryKeys.findIndex(item => item.id === key.id);
	            if (index !== -1) {
		            this.libraryKeys.splice(index, 1);
	            } else {
		            this.libraryKeys.push({ id: key.id, code: key.code });
	            }

	            this.$store.commit('collection/SET_KEYS', this.libraryKeys);
            }
        },

	    isKeySelected(id) {
		    return this.keys.some(key => key.id === id) || this.libraryKeys.includes(id);
	    },
	    isLibraryKeySelected(id) {
		    return this.libraryKeys.some(key => key.id === id) || this.libraryKeys.includes(id);
	    }

        // getSongKeys() {
        //     this.GET_SONG_KEYS()
        //         .then(() => {
        //             console.log('SONG_KEYS', this.SONG_KEYS);
        //         })
        //         .catch(err => console.log(`GET_SONG_KEYS, ${err}`));
        // }
    }
}
</script>
