import axios from 'axios';
import {LOAD_SONG_KEYS} from "@/store/modules/metaData/mutations";

export function loadSongKeys ({ commit }) {
  return new Promise(function (resolve, reject) {
    axios.get(`meta-data/song-keys`, {})
        .then(response => {
          if (!response.data) {
            response.data = []
          }
          commit('LOAD_SONG_KEYS', response.data)
          resolve(response)
        })
        .catch(err => reject(err))
  })
}


