// src/store/modules/exploreFilters.store.js

import axios from 'axios';

const initialState = () => ({
    statusFilter: '', // open-close (explore page)
    upperFilter: '',
    viewFilter: '',
    subgenre: {},
    bpm: [],
    songKeys: [],
    keys: [], // temp keys
    applyKeys: [], // approve keys
    tags: [],
    albumStatus: 'pending',
    activeSavedFilter: '',
    savedFilters: [],
    discovery: true,
    orderBy: { sort: 'releasedAt', order: 'desc' },
    isUserInitiatedOrderBy: false,
    favoriteTabOrder: { sort: 'dateLiked', order: 'desc' }
});

const state = initialState();

const mutations = {
    resetState(state) {
        Object.assign(state, initialState());
    },
    SET_STATUS_FILTER: (state, status) => {
        state.statusFilter = status;
    },
    SET_UPPER_FILTER: (state, upperFilter) => {
        state.upperFilter = upperFilter;
    },
    SET_VIEW_FILTER: (state, viewFilter) => {
        state.viewFilter = viewFilter;
    },
    SET_SUBGENRE: (state, subgenre) => {
        state.subgenre = subgenre;
    },
    SET_BPM: (state, bpm) => {
        state.bpm = bpm;
    },
    SET_KEYS: (state, keys) => {
        state.keys = keys;
    },
    SET_APPLY_KEYS: (state, data) => {
        state.applyKeys = data;
    },
    SET_SONG_KEYS: (state, data) => {
        state.songKeys = data;
    },
    SET_TAGS: (state, tags) => {
        state.tags = tags;
    },
    SET_ALBUM_STATUS: (state, status) => {
        state.albumStatus = status;
    },
    SET_ACTIVE_SAVED_FILTER: (state, filterName) => {
        state.activeSavedFilter = filterName;
    },
    SET_SAVED_FILTERS: (state, savedFilters) => {
        state.savedFilters = savedFilters;
    },
    SET_DISCOVERY: (state, status) => {
        state.discovery = status;
    },
    SET_ORDER_BY: (state, orderBy) => {
        state.orderBy = orderBy;
    },
    SET_USER_INITIATED_ORDER_BY: (state, isUserInitiated) => {
        state.isUserInitiatedOrderBy = isUserInitiated;
    },
    SET_FAVORITE_TAB_ORDER: (state, order) => {
        state.favoriteTabOrder = order;
    },
};

const actions = {
    UPDATE_UPPER_FILTER: ({commit, state, dispatch}, upperFilter) => {
        console.log(`[Action] UPDATE_UPPER_FILTER: ${upperFilter}`);
        
        if (state.upperFilter === 'favorite' && upperFilter !== 'favorite') {
          const wasCanceled = dispatch('CANCEL_FAVOURITE_MUSIC_RELEASE_REQUEST', null, { root: true });
          if (wasCanceled) {
            console.log('[Action] UPDATE_UPPER_FILTER: Favorite request was canceled.');
          }
        }
        
        commit('SET_UPPER_FILTER', upperFilter);
      
        // Update ORDER_BY based on the new UPPER_FILTER
        let newOrderBy;
        if (upperFilter === 'favorite') {
          newOrderBy = state.favoriteTabOrder;
        } else {
          newOrderBy = { sort: 'releasedAt', order: 'desc' };
        }
      
        // Use the separate UPDATE_ORDER_BY action
        dispatch('UPDATE_ORDER_BY', newOrderBy);
      
        // Reset related state
        commit('SET_ALBUMS_CURRENT_PAGE', 1, { root: true });
        if (upperFilter === 'favorite') {
          commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1, { root: true });
        }
    },

    UPDATE_ORDER_BY: ({commit, state}, newOrderBy) => {
        console.log(`[Action] UPDATE_ORDER_BY called with:`, newOrderBy);
        
        // Only commit if the ORDER_BY actually changes
        if (JSON.stringify(newOrderBy) !== JSON.stringify(state.orderBy)) {
            console.log(`[Action] UPDATE_ORDER_BY: Updating to ${JSON.stringify(newOrderBy)}`);
            commit('SET_ORDER_BY', newOrderBy);
        } else {
            console.log(`[Action] UPDATE_ORDER_BY: No change needed`);
        }
    },

    UPDATE_FAVORITE_TAB_ORDER: ({commit}, order) => {
        commit('SET_FAVORITE_TAB_ORDER', order);
    },

    UPDATE_VIEW_FILTER: ({commit}, viewFilter) => {
        commit('SET_VIEW_FILTER', viewFilter);
    },

    UPDATE_SUBGENRE: ({commit}, subgenre) => {
        console.log('UPDATE_SUBGENRE', subgenre);
        commit('SET_SUBGENRE', subgenre);
        commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1, { root: true });
    },

    UPDATE_BPM: ({commit}, bpm) => {
        commit('SET_BPM', bpm);
    },

    GET_SONG_KEYS({commit}, params) {
        return axios.get('song_keys', {params: params})
            .then((response) => {
                console.log('response', response);
                commit('SET_SONG_KEYS', response.data['hydra:member']);
            })
            .catch(err => {
                console.log(`GET_SONG_KEYS ${err}`);
            })
    },

    UPDATE_KEYS: ({commit}, keys) => {
        commit('SET_KEYS', keys);
    },

    UPDATE_APPLY_KEYS: ({commit}, keys) => {
        commit('SET_APPLY_KEYS', keys);
    },

    UPDATE_TAGS: ({commit}, tags) => {
        commit('SET_TAGS', tags);
    },

    UPDATE_ACTIVE_SAVED_FILTER: ({commit}, filterName) => {
        commit('SET_ACTIVE_SAVED_FILTER', filterName);
    },

    GET_SAVED_FILTERS: ({commit}) => {
        let filters = JSON.parse(localStorage.getItem('filtersSubGenre') || '[]');
        commit('SET_SAVED_FILTERS', filters);
    },

    UPDATE_DISCOVERY: ({commit}, status) => {
        commit('SET_DISCOVERY', status);
    },

    CLEAN_FILTERS: ({commit, dispatch}) => {
        commit('SET_BPM', []);
        commit('SET_KEYS', []);
        commit('SET_APPLY_KEYS', []);
        commit('SET_TAGS', []);
        commit('SET_ACTIVE_SAVED_FILTER', '');
        commit('SET_TRACKS_CURRENT_PAGE', 1, { root: true });
        commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1, { root: true });
        commit('SET_SUBGENRE', {});
        commit('SET_TRACK_SCROLL_ID', null, { root: true });

    }


};

const getters = {
    STATUS_FILTER: state => state.statusFilter,
    UPPER_FILTER: state => state.upperFilter,
    VIEW_FILTER: state => state.viewFilter,
    SUBGENRE: state => state.subgenre,
    BPM: state => state.bpm,
    SONG_KEYS: state => state.songKeys,
    KEYS: state => state.keys,
    APPLY_KEYS: state => state.applyKeys,
    TAGS: state => state.tags,
    ALBUM_STATUS: state => state.albumStatus,
    ACTIVE_SAVED_FILTER: state => state.activeSavedFilter,
    SAVED_FILTERS: state => state.savedFilters,
    DISCOVERY: state => state.discovery,
    ORDER_BY: state => state.orderBy,
    IS_USER_INITIATED_ORDER_BY: state => state.isUserInitiatedOrderBy,
    FAVORITE_TAB_ORDER: state => state.favoriteTabOrder,
};

export default {
    state,
    mutations,
    actions,
    getters
};