import axios from 'axios';

const initialState = () => ({
    followers: '',
    artistProfileReleasesAlbums: [],
    artistProfileAppearsOnAlbums: [],
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState);
        },
        SET_ARTIST_FOLLOWERS: (state, data) => {
            state.followers = data;
        },
        SET_ARTIST_PROFILE_RELEASES_ALBUMS: (state, data) => {
            state.artistProfileReleasesAlbums = data;
        },
        SET_ARTIST_PROFILE_APPEARS_ON_ALBUMS:  (state, data) => {
            state.artistProfileAppearsOnAlbums = data;
        },
    },
    actions: {
        GET_ARTIST_FOLLOWERS({commit}, id) {
            return axios.get(`artists/${id}/followers`)
                .then((response) => {
                    commit('SET_ARTIST_FOLLOWERS', response.data);
                })
                .catch(err => {console.error(`ARTIST_FOLLOWERS ${err}`)})
        },
        GET_ARTIST_PROFILE_RELEASES_ALBUMS({commit}, params) {
           commit('SET_ARTIST_PROFILE_RELEASES_ALBUMS', []);
           return axios.get(`optimized/music_releases`, {params: params})
               .then(response => {
                   commit('SET_ARTIST_PROFILE_RELEASES_ALBUMS', response.data.items)
               })
               .catch(error => console.log(`GET_ARTIST_PROFILE_RELEASES_ALBUMS ${error}`));
       },
        GET_ARTIST_PROFILE_APPEARS_ON_ALBUMS({commit}, params) {
            commit('SET_ARTIST_PROFILE_APPEARS_ON_ALBUMS', []);
            return axios.get(`optimized/music_releases`, {params: params})
                .then(response => {
                    commit('SET_ARTIST_PROFILE_APPEARS_ON_ALBUMS', response.data.items);
                    // commit('SET_ARTIST_PROFILE_APPEARS_ON_ALBUMS', response.data.items);
                })
                .catch(err => console.log(`GET_ARTIST_PROFILE_APPEARS_ON_ALBUMS ${err}`));
        },
    },
    getters: {
        ARTIST_FOLLOWERS: state => state.followers,
        ARTIST_PROFILE_RELEASES_ALBUMS: state => state.artistProfileReleasesAlbums,
        ARTIST_PROFILE_APPEARS_ON_ALBUMS: state => state.artistProfileAppearsOnAlbums,
    }
}
