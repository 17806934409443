import axios from 'axios';
import { Centrifuge } from 'centrifuge';

const initialState = () => ({
    client: null,
});

export default {
    state: initialState(),

    mutations: {
        SET_CENTRIFUGE_CLIENT: (state, data) => {
            state.client = data;
        },
    },

    actions: {
        GET_CENTRIFUGE_CLIENT({commit}) {
            return axios.get('centrifugo/credentials/user')
                .then(response => {
                    const centrifuge = new Centrifuge(process.env.VUE_APP_CENTRIFUGE_URL, {
                        token: response.data.token
                    });
                    commit('SET_CENTRIFUGE_CLIENT', centrifuge);
                }).catch(err => {
                    console.log(`GET_CENTRIFUGE_CLIENT ${err}`);
                })
        },
    },

    getters: {
        CENTRIFUGE_CLIENT: state => state.client
    }
}
