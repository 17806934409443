<template>
    <footer>
      <v-container>
        <div class="left_box">
          <div class="footer_logo"></div>
          <span>Copyright © 2021</span>
        </div>
        <div class="right_box">
          <div class="links">
            <router-link :to="{name: 'faq'}">About</router-link>
            <router-link :to="{name: 'changelog'}">Changelog</router-link>
            <router-link :to="{name: 'feature-suggestions'}">Suggest a feature</router-link>
            <!-- <router-link :to="{name: 'bug-reports'}">Report a bug</router-link> -->
            <a href="mailto:info@psychurch.org?subject=Found a bug!">Report a bug</a>
            <!-- <router-link :to="{name: 'terms-of-use'}">Terms of use</router-link> -->
            <!-- Link to the static FunkyTOS.html -->
            <a href="/FunkyTOS.html" target="_blank">Terms of Service</a>
            <a :href="FACEBOOK" target="_blank">Contact us</a>
          </div>
          <div class="social_block">
            <!-- <a href="mailto:info.psytrance.network@gmail.com" class="mail" target="_blank"></a> -->
            <a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="facebook"></a>
            <!-- <a href="https://instagram.com" target="_blank" class="instagram"></a> -->
          </div>
        </div>
      </v-container>
    </footer>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    name: 'mainFooter',
    computed: {
      ...mapGetters(['FACEBOOK'])
    }
  }
  </script>
  
  <style scoped>
  /* You can add or update your footer styles here */
  </style>
  