import axios from 'axios';

const initialState = () => ({
    notifications: [],
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_NOTIFICATIONS: (state, data) => {
            state.notifications = data;
        },
    },
    actions: {
        GET_NOTIFICATIONS({ commit, rootGetters }, params) {
            if (!rootGetters.isAuthenticated) {
                return;
            }

            return axios.get(`user_notifications`, {params: params})
                .then(response => {
                    if (response && response.data && response.data["hydra:member"]) {
                        commit('SET_NOTIFICATIONS', response.data["hydra:member"]);
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        // Handle unauthorized error, e.g., redirect to login
                    }
                });
        },
    },
    getters: {
        NOTIFICATIONS: state => state.notifications,
    }
}
