import axios from 'axios';

const initialState = () => ({
    playlists: [],
    playlist: {},
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_PLAYLISTS: (state, data) => {
            state.playlists = data;
        },
        SET_PLAYLIST: (state, data) => {
            state.playlist = data;
        },
    },
    actions: {
        GET_PLAYLISTS({commit}, params) {
            return axios.get('favorite_music_release_list', {params: params})
                .then(data => {
                    commit('SET_PLAYLISTS', data.data.result);
                })
                .catch(error => console.log(`GET_PLAYLISTS ${error}`));
        },

        GET_PLAYLIST({commit}, params) {
            console.log(' userId, playlistId', params);
            return axios.get(`favorite_music_release_lists/${params.playlistId}`, params.id)
                .then(data => {
                    console.log('DTA-DTA-DATA', data);
                    // commit('SET_PLAYLIST', data.data['hydra:member']);
                })
                .catch(error => console.log(`GET_PLAYLIST ${error}`));
        },

        ADD_TRACK_TO_PLAYLISTS({commit}, params) {
            return axios.post('add_song_to_favorite_list', {
                'song_id': params.song_id,
                'favorite_list_id': params.favorite_list_id
            }).then(response => {
                console.log(`ADD_TRACK_TO_PLAYLISTS - ${params}, ${response}`);
            }).catch(err => {
                console.log(`ADD_TRACK_TO_PLAYLISTS - ${params}, ${err}`);
            });
        },

        CREATE_PLAYLIST({commit}, data, params) {
            return axios.post(`favorite_music_release_list`, data, {params: params})
                .catch(error => console.log(`CREATE_PLAYLIST ${error}, ${params}`));
        },
        DELETE_PLAYLIST({commit}, id) {
            return axios.delete(`favorite_music_release_list/${id}`)
                .catch(err => console.log(`DELETE_FAQ ${err}`));
        },

    },
    getters: {
        PLAYLISTS: state => state.playlists,
        PLAYLIST: state => state.playlist,
    }
}
