import Hls from "hls.js";
import axios from "axios";

const initialState = () => ({
    activeReq: null,
    currentTrack: null,
    currentTrackIdx: null,
    currentAlbum: null,
    currentFromPlay: '',

    sound: null,
    playing: false,
    playerStatus: false,
    shuffle: false,
    loop: false,
    muted: false,
    soundVolume: 60,
    player: {},
    volume: 0.5,
    duration: 0,
    alertNextAlbum: '',

    playedZone: [],
    trackList: [],
    playedOnlyFavTracks: false,

    trackOnload: false,
    progress: null,
    fullAlbum: '',
    fullAlbumLoading: false,
    loadingReleaseID: null,
    soundError: false,
    isOnline: window.navigator.onLine,
    seek: false,
    bufferProgress: 0,
});

export default {
    state: initialState(),
    mutations: {
        SET_SOUND(state, track) {
              if (Hls.isSupported()) {
                  if (state.hlsInstance) {
                      state.hlsInstance.destroy();
                    }
                    
                    state.trackOnload = false;
                    state.sound = document.getElementById('player');
                    
                    state.hlsInstance = new Hls();
                    state.hlsInstance.loadSource(track.link);
                    state.hlsInstance.attachMedia(state.sound);

                    const onEnded = () => {
                        if (this.getters.IS_TOKEN_ACTIVE === false) {
                            let refreshToken = this.getters['AUTH_DATA'].refreshToken || localStorage.getItem('refreshToken');
                            this.dispatch('REFRESH_TOKEN', refreshToken);
                        }
        
                        if (this.getters['CURRENT_ALBUM'].playList) {
                            const currentPlaylist = this.getters['CURRENT_ALBUM'].playList;
                            const lastIndexTrack = currentPlaylist.length - 1;
        
                            if (currentPlaylist[lastIndexTrack].song.id === track.id) {
                                this.dispatch('SKIP_ALBUM', 'next');
                            }

                            if (!state.loop) {
                                this.dispatch('SKIP_TRACK', 'next');
                            }
                        }
                        state.sound.removeEventListener('ended', onEnded);
                      }
                    
                    state.hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
                        state.sound.addEventListener('ended', onEnded);
                    });
                    
                    state.hlsInstance.loadSource(track.link);
                    state.hlsInstance.attachMedia(state.sound);
                    
                    state.hlsInstance.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                        console.log(
                            'manifest parsed, found ' + data.levels.length + ' quality level',
                            event, data, new Date()
                    );
                    
                    state.trackOnload = true;
                });
                
                state.hlsInstance.on(Hls.Events.ERROR, (event, data) => {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.log("Network error occurred");
                                state.sound.pause();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.log("Media error occurred");
                                state.sound.pause();
                                break;
                            default:
                                console.log("An error occurred", data);
                                break;
                        }
                        state.soundError = true;
                    }
                });
            } else {
                console.log("HLS is not supported. Trying alternative method.");

                state.trackOnload = false;
                state.sound = new Audio(track.link);
                state.sound.crossOrigin = 'anonymous';
                state.sound.addEventListener('canplaythrough', () => {
                    state.trackOnload = true;
                    if (!state.playing) {
                        this.commit('SET_PLAY');
                    }
                });

                state.sound.addEventListener('error', (error) => {
                    console.error("Error loading audio:", error);
                    state.soundError = true;
                });
            }
        },

        SET_PLAYED_ZONE: (state, data) => {
            state.playedZone = data;
        },
        SET_TRACK_LIST: (state, data) => {
            state.trackList = data;
        },
        SET_PLAYED_ONLY_FAV_TRACKS: (state, data) => {
            state.playedOnlyFavTracks = data;
        },
        SET_CURRENT_FROM_PLAY(state, data) {
            state.currentFromPlay = data;
        },
        SET_CURRENT_TRACK(state, data) {
            state.currentTrack = data;
        },
        SET_CURRENT_TRACK_INDEX(state, data) {
            state.currentTrackIdx = data;
        },
        SET_CURRENT_ALBUM(state, data) {
            state.currentAlbum = data;
        },
        SET_CURRENT_PLAYING(state, status = true) {
            state.playing = status;
        },
        SET_PLAY(state) {
            state.sound.play();
        },
        SET_STOP(state) {
            state.sound.pause();
        },
        SET_PAUSE(state) {
            state.sound.pause();
        },
        SET_PLAYER_STATUS(state, status) {
            state.playerStatus = status;
        },
        SET_SHUFFLE(state, data) {
            state.shuffle = data;
        },
        SET_LOOP(state) {
            state.loop = !state.loop;
            state.sound.loop(state.loop);
        },
        SET_MUTED(state) {
            state.muted = !state.muted;
            state.sound.mute(state.muted);
        },
        SET_SOUND_VOLUME(state, data) {
            state.player.volume(data);
        },
        SET_PROGRESS: (state, progress) => {
            state.progress = progress;
        },
        SET_FULL_ALBUM_TO_STATE: (state, data) => {
            state.fullAlbum = data;
        },
        SET_FULL_ALBUM_LOADING: (state, data) => {
            state.fullAlbumLoading = data;
        },
        SET_LOADING_RELEASE: (state, id) => {
            state.loadingReleaseID = id;
        },
        SET_PLAYER(state) {
            // state.player = Howler;
        },
        SET_IS_LISTENED_ALBUM(state, data) {
            data.isListened = true;
        },
        SET_ALERT_NEXT_ALBUM: (state, data) => {
            state.alertNextAlbum = data;
        },
        SET_BUFFER_PROGRESS: (state, data) => {
            state.bufferProgress = data;
        },
        // TODO: !!!
        SET_SEEK: (state, data) => {
            state.seek = data;
        },
    },
    actions: {
        SET_PLAY({commit, state}, {
            fromPlay,
            track = state.currentTrack,
            index = state.currentTrackIdx,
            album = state.currentAlbum,
            isDiscoveryStatus = false}) {

            // fromPlay = 'album_default'

            // console.log('SET_PLAY - fromPlay', fromPlay);
            // console.log('state.isOnline', state.isOnline);
            // commit('SET_PROGRESS', null);
            if (state.isOnline === false) {
                console.log('offline mode');
            }
            // set album logic
            // if (isDiscoveryStatus) {
            commit('SET_IS_LISTENED_ALBUM', album);
            // }

            commit('SET_CURRENT_ALBUM', album);
            if (!state.playerStatus) {
                commit('SET_PLAYER_STATUS', true);
            }

            // set track logic
            commit('SET_CURRENT_TRACK_INDEX', index);

            if (state.currentTrack && state.currentTrack.id !== track.id) {
                commit('SET_STOP');
                commit('SET_CURRENT_TRACK', track);
                commit('SET_SOUND', track, index, album);
            } else {
                if (!state.currentTrack && !state.sound) {
                    commit('SET_CURRENT_TRACK', track);
                    commit('SET_SOUND', track, index, album);
                }
            }

            // TODO: // check SET_CURRENT_FROM_PLAY
            commit('SET_CURRENT_FROM_PLAY', fromPlay || 'album_default');

            if (fromPlay !== 'track_default' && state.shuffle === false) {
                if (this.getters['AUTH_DATA'].accessToken) {
                    this.dispatch('INCREASE_PLAYED_MUSIC_RELEASE', {'musicReleaseID': album.id});
                }
            }

            commit('SET_PLAY');
            commit('SET_CURRENT_PLAYING');
            commit('SET_PLAYER');
        },

        SET_PLAYED_ONLY_FAV_TRACKS({commit}, data) {
            commit('SET_PLAYED_ONLY_FAV_TRACKS', data);
        },
        SET_TRACK_LIST({commit}, data) {
            commit('SET_TRACK_LIST', data);
        },
        SET_PAUSE({commit}) {
            commit('SET_CURRENT_PLAYING', false);
            commit('SET_PAUSE');
        },

        // STOP
        STOP_PLAY({commit}) {
            commit('SET_STOP');
            commit('SET_PLAYER_STATUS', false);

            commit('SET_CURRENT_TRACK', null);
            commit('SET_CURRENT_TRACK_INDEX', null);
            commit('SET_CURRENT_PLAYING', false);
            commit('SET_CURRENT_ALBUM', null);
        },
        SET_PLAYED_ZONE({commit}, data) {
            commit('SET_PLAYED_ZONE', data);
        },

        TOGGLE_SHUFFLE({commit}) {
            commit('SET_SHUFFLE');
        },
        TOGGLE_LOOP({commit}) {
            commit('SET_LOOP')
        },
        TOGGLE_MUTE({commit}) {
            commit('SET_MUTED')
        },
        UPDATE_PROGRESS({commit}, data) {
            commit('SET_PROGRESS', data);
        },

        SKIP_TRACK({commit, state}, direction) {
            let index = 0;
            let album;
            let track;

            if (state.shuffle === true) {
                this.dispatch('SKIP_ALBUM', 'next');
                return;
            }

            if (direction === 'prev') {
                index = state.currentTrackIdx - 1;

                if (state.currentAlbum.playList) {

                    if (state.playedOnlyFavTracks === true) {
                        index = this.getters.favIndexFinder(direction);
                        if (!index && this.getters['PLAYED_ZONE'].length > 0) {
                            this.dispatch('SKIP_ALBUM', 'prev');
                            return;
                        }
                    }

                    if (index < 0) {
                        this.dispatch('SKIP_ALBUM', 'prev');
                        return;
                    }
                }
            }
            if (direction === 'next') {

                // find index next track
                index = state.currentTrackIdx + 1;

                // album playList
                if (state.currentAlbum.playList) {

                    if (state.playedOnlyFavTracks === true) {
                        index = this.getters.favIndexFinder(direction);
                        if (!index && this.getters['PLAYED_ZONE'].length > 0) {
                            this.dispatch('SKIP_ALBUM', 'next');
                            return;
                        }
                    }

                    if (index >= state.currentAlbum.playList.length) {
                        this.dispatch('SKIP_ALBUM', 'next');
                        return;
                    }

                }
            }


            // album playList
            if (state.currentAlbum.playList) {
                let playlist = state.currentAlbum.playList.filter(track => !track.song.deletedAt && track.song.link);

                if (index < 0) {
                    index = 0;
                }
                if (index >= playlist.length) {
                    index = 0;
                }

                track = playlist[index].song;

                album = state.currentAlbum;

            } else if (state.trackList.length) {
                if (index >= state.trackList.length) {
                    index = 0;
                }
                if (index < 0) {
                    index = state.trackList.length - 1;
                }


                // library
                if (state.trackList[index].song) {
                    track = state.trackList[index].song;
                } else {
                    // default
                    track = state.trackList[index];
                }

                // default
                if (track.playLists) {
                    album = track.playLists[0].musicRelease;
                } else {
                    // library
                    album = track.musicRelease;
                }
            } else {
                console.log('need release-playlist || trackList');
            }

            let skipTrack = {track: track, index, album};

            this.dispatch('SET_PLAY', skipTrack);
        },

        SKIP_ALBUM({commit, state}, direction) {
            const albums = this.getters['PLAYED_ZONE'].filter(album => (album.deletedAt && album.havePurchased === true) || album.status !== 'draft');

            if (albums.length) {
                const currAlbumIdx = albums.findIndex((({id}) => id === state.currentAlbum.id));

                let skipAlbum;
                let trackIndex = 0;

                if (state.shuffle === true) {
                    // trackIndex = Math.round(Math.random() * (state.currentAlbum.playList.length - 1));
                    skipAlbum = albums[Math.round(Math.random() * (albums.length - 1))];
                }
                if (direction === 'prev') {
                    skipAlbum = albums[currAlbumIdx - 1];

                    if (currAlbumIdx <= 0) {
                        skipAlbum = albums[albums.length - 1];
                    }
                } else {
                    if (currAlbumIdx + 1 === albums.length) {
                        skipAlbum = albums[0];
                    } else {
                        skipAlbum = albums[currAlbumIdx + 1];
                    }
                }


                let params = { id: skipAlbum.id, title: skipAlbum.title };
                this.dispatch('GET_FULL_ALBUM', params).then(() => {
                    commit('SET_IS_LISTENED_ALBUM', skipAlbum);
                    console.log('SET_IS_LISTENED_ALBUM, skipAlbum', skipAlbum.id);
                    if (this.getters['FULL_ALBUM'].playList && this.getters['FULL_ALBUM'].playList.length) {
                        if (state.shuffle === true) {
                            trackIndex = Math.round(Math.random() * (this.getters['FULL_ALBUM'].playList.length - 1));
                        }
                        if (state.playedOnlyFavTracks === true) {
                            let favPlaylist = [];

                            for (let i = 0; i < this.getters['FULL_ALBUM'].playList.length; i++) {
                                if (this.getters['FULL_ALBUM'].playList[i].song.isFavorite) {
                                    favPlaylist.push(i);
                                }
                            }
                            if (favPlaylist.length) {
                                trackIndex = favPlaylist[0];
                            } else {
                                trackIndex = 0;
                            }

                            console.log('playedOnlyFavTracks - skip album, tracks index=', trackIndex);
                        }
                        console.log('trackIndex', trackIndex);
                        const data = {
                            fromPlay: 'album_default',
                            track: this.getters['FULL_ALBUM'].playList[trackIndex].song,
                            index: 0,
                            album: this.getters['FULL_ALBUM'],
                            isDiscoveryStatus: this.getters['DISCOVERY']
                        }
                        this.dispatch('SET_PLAY', data);
                    } else {
                        console.log('none this.getters[FULL_ALBUM].playList or playList.length');
                    }
                })
            } else {
                console.log('none Played-Zone!');
            }
        },

        SET_SOUND_VOLUME({commit, state}, data) {
            // TODO: NOT USES
            // TODO: check
            commit('SET_SOUND_VOLUME', data);
            if (+data < 0.2) {
                commit('SET_MUTED');
            }
            if (+data > 0.1 && state.sound._muted) {
                commit('SET_MUTED', false);
            }
        },

        ADD_SONG_STATISTIC({commit}, params) {
            // Available values : downloaded, purchased, listened_to
            return axios.post(`add_song_statistic/${params.value}`, {'song_id': params.id})
                .then(response => {

                })
                .catch(err => {
                    console.log(`ADD_SONG_STATISTIC, ${err}`);
                })
        },


        // main album
        GET_FULL_ALBUM({commit, state}, params) {
            if (this.getters['CURRENT_PAGE'].name !== 'albumDetails') {
                commit('SET_FULL_ALBUM_LOADING', true);
                commit('SET_LOADING_RELEASE', params.id);
            }
            //  withDeletedSong: true
            let route = `music_releases/${params.id || params.name}`;

            return axios.get(`${route}`, ) // {params: params.title}
                .then(album => {
                    commit('SET_FULL_ALBUM_TO_STATE', album.data);
                })
                .catch(error => {
                    console.error(`GET_FULL_ALBUM ${error}`);
                })
                .finally(() => {
                    commit('SET_FULL_ALBUM_LOADING', false);
                    commit('SET_LOADING_RELEASE', null);
                })
        }
    },

    getters: {
        CURRENT_FROM_PLAY: (state) => state.currentFromPlay,
        CURRENT_TRACK: (state) => state.currentTrack,
        CURRENT_ALBUM: (state) => state.currentAlbum,
        PLAYING: (state) => state.playing,
        PLAYER_STATUS: (state) => state.playerStatus,
        SOUND: (state) => state.sound,
        SHUFFLE: (state) => state.shuffle,
        CURRENT_TRACK_IDX: (state) => state.currentTrackIdx,
        LOOP: (state) => state.loop,
        MUTED: (state) => state.muted,
        DURATION: (state) => state.duration,
        ALERT_NEXT_ALBUM: (state) => state.alertNextAlbum,
        PLAYED_ZONE: (state) => state.playedZone,
        TRACK_LIST: state => state.trackList,
        PLAYED_ONLY_FAV_TRACKS: (state) => state.playedOnlyFavTracks,
        TRACK_ONLOAD: state => state.trackOnload,

        favIndexFinder: (state) => (direction) => {
            let currentPlayingIndex = -1;
            let favPlaylist = [];

            for (let i = 0; i < state.currentAlbum.playList.length; i++) {
                if (state.currentAlbum.playList[i].song.isFavorite) {
                    favPlaylist.push(i);
                }
                if (state.currentTrack.id === state.currentAlbum.playList[i].song.id) {
                    currentPlayingIndex = i;
                }
            }
            const favIndex = favPlaylist.findIndex(x => x === currentPlayingIndex);

            let index = 0;
            if (direction === 'next') {
                index = favIndex + 1;
                if (index >= favPlaylist.length) {
                    // index = 0;
                    index = -1;
                }
            } else {
                index = favIndex - 1;
                if (index < 0) {
                    // index = favPlaylist.length - 1;
                    index = -1;
                }
            }

            return favPlaylist[index];
        },

        PROGRESS: (state) => state.progress,
        FULL_ALBUM: state => state.fullAlbum,
        FULL_ALBUM_LOADING: state => state.fullAlbumLoading,
        LOADING_RELEASE_ID: state => state.loadingReleaseID,
        SOUND_ERROR: state => state.soundError,
        SEEK: state => state.seek,
        BUFFER_PROGRESS: state => state.bufferProgress,
        // onlyValidTracks: (state) => (direction) => {
        //     let currentPlayingIndex = -1;
        //     let playlist = [];
        //
        //     for (let i = 0; i < state.currentAlbum.playList.length; i++) {
        //         const currentTrack = state.currentAlbum.playList[i].song;
        //         const currentRelease = state.currentAlbum;
        //         if (!currentTrack.deletedAt && (currentRelease.status === 'published' || currentRelease.status.value === 'published')) {
        //             playlist.push(i);
        //             console.log('push playlist', i);
        //         }
        //         if (state.currentTrack.id === state.currentAlbum.playList[i].song.id) {
        //             currentPlayingIndex = i;
        //         }
        //     }
        //     const setIndex = playlist.findIndex(x => x === currentPlayingIndex);
        //
        //     let index = 0;
        //     if (direction === 'next') {
        //         index = setIndex + 1;
        //         if (index >= playlist.length) {
        //             // index = 0;
        //             index = -1;
        //         }
        //     } else {
        //         index = setIndex - 1;
        //         if (index < 0) {
        //             // index = favPlaylist.length - 1;
        //             index = -1;
        //         }
        //     }
        //
        //     return playlist[index];
        // },

    },
}
