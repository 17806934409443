import router from "@/routes";

const actions = {
    CLEAR_STORE({ commit, state }) {
        commit('resetState');

        // namespaced modules
        commit('manageAccounts/resetState');
        commit('manageUploadsReleases/resetState');
        commit('manageUploadsEvents/resetState');
        commit('manageFollows/resetState');
        commit('events/resetState');
        commit('collection/resetState');
        commit('suggestions/resetState');

        // scroll
        console.log('CLEAR_STORE');
        commit('SET_GRID_ALBUM_SCROLL_ID', null);
        commit('SET_TRACK_SCROLL_ID', null);

    }
};

export default {
    actions,
}