import axios from 'axios';
import store from '../store';
import router from "@/routes";

export function Interceptors() {
    axios.interceptors.request.use(request => {
        const accessToken = store.getters['AUTH_DATA'].accessToken || localStorage.getItem('accessToken');

        if (accessToken) {
            request.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }

        return request;
    });

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                if (originalRequest.url !== "authentication_token") {
                    originalRequest._retry = true;
                    const authData = store.getters['AUTH_DATA'];

                    if (authData.accessToken && authData.refreshToken) {
                        return store.dispatch('REFRESH_TOKEN', authData.refreshToken)
                            .then(() => {
                                const accessToken = store.getters['AUTH_DATA'].accessToken;
                                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                                return axios.request(originalRequest);
                            })
                            .catch(err => {
                                console.error('Token refresh failed:', err);
                                return handleLogout();
                            });
                    } else {
                        console.log('inspectors 2 - LOGOUT');
                        return handleLogout();
                    }
                }
            }
            return Promise.reject(error);
        }
    );
}

function handleLogout() {
    if (store.getters.isAuthenticated) {
        console.log('Logging out due to authentication failure');
        return store.dispatch('LOGOUT').then(() => {
            if (router.currentRoute.name !== 'logged-out') {
                router.push({name: 'logged-out'});
            }
        });
    } else {
        console.log('User was not logged in, no need to logout');
        return Promise.resolve();
    }
}