export function toHumanFormat(path) {
    const regex = /[ .!@#$%^&*()+=\[\]{}|\\:;"'<>,/?~]/g;

    return path.trim().replace(regex, '-').toLowerCase();
}

export function keepRouteParams(route, params) {
    // localStorage.setItem(`ROUTE[${route}]`, params);
}

export function takeRouteParams(route) {
    const safeParams = JSON.parse(localStorage.getItem(`ROUTE[${route.path}]`));
    if (safeParams && JSON.stringify(safeParams) !== JSON.stringify(route.params)) {
        Object.keys(safeParams).forEach(key => {
            route.params[key] = safeParams[key];
        });
    }
}

