<template>
	<div class="wrap_alert_notification">
		<!--<button type="button" class="alert_message"></button>--><!-- :TODO: temp hidden -->

		<transition name="slide-y-transition">

		</transition>
	</div>
</template>
<script>
export default {
	name: "alertNotifications",
}
</script>

<style lang="scss">
.wrap_alert_notification {
	display: flex;
}
</style>
