// const mainSearch = () => import('@/pages/search/search');
import mainSearch from "@/pages/search/search";

export default [
    {
        // path: '/:subgenre?',
        path: '/',
        name: 'explore',
        component: () => import('@/pages/explore'),
        props: true,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
        },
    },
    {
        path: '/explore/:subgenre?',
        name: 'explore',
        component: () => import('@/pages/explore'),
        props: true,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
        },
    },
    {   // mobile
        path: '/albums-list/:id?', // subgenre
        name: 'albumsList',
        component: () => import('@/pages/mobile/main/albumsList'),
        props: true,
        meta: {requiresAuth: false},
    },
    {
        path: '/album/:name/:favourite?',
        name: 'albumDetails',
        component: () => import('@/pages/musicRelease/albumDetails'),
        props: true,
        meta: {requiresAuth: false, human: true},
    },
    // {
    //     path: '/album/:id/:trackID',
    //     name: 'albumDetails',
    //     component: () => import('@/pages/albumDetails'),
    //     props: true,
    //     meta: {requiresAuth: false}
    // },


    // Search
    {
        path: '/search',
        name: 'search',
        component: mainSearch,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
        }
    },
    {
        path: '/search-details',
        name: 'searchDetails',
        component: () => import('@/pages/search/searchDetails.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            requiresVerify: true,
        }
    },

    // Library
    {
        path: '/library',
        name: 'library',
        component: () => import('@/pages/library/library'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },

    {
        path: '/memberships',
        name: 'memberships',
        component: () => import('@/pages/dashboard/memberships'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
]
