import store from "@/store";

const initialState = () => ({
    current: '',
    from: '',
});

export default {
    state: {
        ...initialState(),
        needLoginRedirectPath: '',
    },
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_NEED_LOGIN_REDIRECT_PATH(state, path) {

            // іgnored routes
            let routes = [
                '/',
                '/login',
                '/forgot-password',
                '/logged-out',
                '/registration',
                '/password-changed',
                '/settings/security'
            ];
            if (routes.includes(path)) {
               return;
            }

            state.needLoginRedirectPath = path;
        },
        CHANGE_ROUTE: (state, data) => {
            state.from = data.from;
            state.current = data.to;
        },
    },
    getters: {
        NEED_LOGIN_REDIRECT_PATH: state => state.needLoginRedirectPath,
        FROM_PAGE: state => state.from,
        CURRENT_PAGE: state => state.current
    }
}
