import axios from 'axios';

const initialState = () => ({
    suggestions: [],
    suggestionsLoading: false,
    totalSuggestions: '',
    currentPage: 1,
    totalPages: '',

    suggest: '',

    // *** //
    changelogs: [],
    globalStats: '',
    availableVoteStats: ''
});

export default {
    namespaced: true,
    state: initialState(),

    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_SUGGESTIONS: (state, data) => {
            state.suggestions = data;
        },
        SET_SUGGESTIONS_LOADING: (state, data) => {
            state.suggestionsLoading = data;
        },
        SET_TOTAL_SUGGESTIONS: (state, data) => {
          state.totalSuggestions = data;
        },
        SET_CURRENT_PAGE: (state, data) => {
          state.currentPage = data;
        },
        SET_TOTAL_PAGES: (state, data) => {
          state.totalPages = data;
        },
        SET_SUGGEST: (state, data) => {
            state.suggest = data;
        },

        // *** //
        SET_CHANGELOGS: (state, data) => {
            state.changelogs = data;
        },
        SET_GLOBAL_STATS: (state, data) => {
            state.globalStats = data;
        },
        SET_AVAILABLE_VOTE_STATS: (state, data) => {
            state.availableVoteStats = data;
        }
    },

    actions: {
        CREATE_SUGGEST({commit}, data) {
            return axios.post(`suggestions`, data)
                .catch(err => {
                    console.log(`CREATE_SUGGEST ${err}`);
                });
        },
        GET_SUGGESTIONS({commit}, params) {
            commit('SET_SUGGESTIONS', []);
            commit('SET_SUGGESTIONS_LOADING', true);
            return axios.get(`search/suggestions`, {params: params})
                .then(response => {
                    commit('SET_SUGGESTIONS', response.data.suggestions);
                    commit('SET_TOTAL_SUGGESTIONS', response.data.total);
                    commit('SET_CURRENT_PAGE', response.data.currentPage);
                    commit('SET_TOTAL_PAGES', response.data.totalPages);
                })
                .catch(err => console.log(`GET_SUGGESTIONS ${err}`))
                .finally(() =>  commit('SET_SUGGESTIONS_LOADING', false));
        },
        GET_SUGGEST({commit}, params) {
            return axios.get(`suggestions/${params.id}`, {params: params})
                .then(response => {
                    commit('SET_SUGGEST', response.data['hydra:member']);
                })
                .catch(err => console.log(`GET_SUGGEST ${err}`));
        },
        UPDATE_SUGGEST({commit}, params) {
            return axios.put(`suggestions/${params.id}`).catch(err => {
                console.log(`UPDATE_SUGGEST ${err}`)
            })
        },
        DELETE_SUGGEST({commit}, id) {
            return axios.delete(`suggestions/${id}`)
                .then(() => {

                })
                .catch(err => {
                console.log(`DELETE_SUGGEST ${err}`)
            })
        },


        // *** //
        GET_CHANGELOGS({commit}) {
            commit('SET_CHANGELOGS', []);
            return axios.get(`suggestion/changelogs`)
                .then(response => {
                    commit('SET_CHANGELOGS', response.data);
                })
                .catch(err => console.log(`GET_CHANGELOGS ${err}`));
        },
        GET_GLOBAL_STATS({commit}) {
            commit('SET_GLOBAL_STATS', '');
            return axios.get(`suggestion/stats`)
                .then(response => {
                    commit('SET_GLOBAL_STATS', response.data);
                })
                .catch(err => console.log(`GET_GLOBAL_STATS ${err}`));
        },
        GET_AVAILABLE_VOTE_STATS({commit}, params) {
            commit('SET_AVAILABLE_VOTE_STATS', '');
            return axios.get(`vote/stats`, {params: params})
                .then(response => {
                    commit('SET_AVAILABLE_VOTE_STATS', response.data.UserVoteOption)
                })
                .catch(err => console.log(`GET_AVAILABLE_VOTE_STATS ${err}`))
        },
        ADD_VOTE_TO_SUGGESTION({commit}, id) {
            return axios.post(`vote/${id}`)
                .catch(err => {
                    console.log(`ADD_VOTE_TO_SUGGESTION ${err}`);
                });
        },
        PURCHASE_VOTES({commit}, amount) {
            return axios.post(`vote/purchase/${amount}`)
                .catch(err => {
                    console.log(`ADD_VOTE_TO_SUGGESTION ${err}`);
                });
        },


        // *** //
        UPDATE_CURRENT_PAGE({commit}, page) {
            commit('SET_CURRENT_PAGE', page);
        }

    },
    getters: {
        SUGGESTIONS: state => state.suggestions,
        SUGGESTIONS_LOADING: state => state.suggestionsLoading,
        TOTAL_SUGGESTIONS: state => state.totalSuggestions,
        CURRENT_PAGE: state => state.currentPage,
        TOTAL_PAGES: state => state.totalPages,

        SUGGEST: state => state.suggest,

        // *** //
        CHANGELOGS: state => state.changelogs,
        GLOBAL_STATS: state => state.globalStats,
        AVAILABLE_VOTE_STATS: state => state.availableVoteStats,
    }
}
