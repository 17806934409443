import axios from "axios";

const initialState = () => ({
    activeReq: null,

    topFilter: 'all',
    searchAccounts: '',

    manageArtists: [],
    manageArtistsLoader: false,
    orderBy: {
        sort: 'releasedAt',
        order: 'desc'
    },
    countAccounts: '',
    currentPage: 1
})
export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_TOP_FILTER: (state, data) => {
            state.topFilter = data;
        },
        SET_SEARCH_ACCOUNTS: (state, data) => {
            state.searchAccounts = data;
        },
        SET_MANAGE_ARTISTS: (state, data) => {
            state.manageArtists = data['hydra:member'];
            state.countAccounts = data['hydra:totalItems'];
        },
        SET_MANAGE_ARTIST_LOADER: (state, data) => {
            state.manageArtistsLoader = data;
        },
        SET_ORDER_BY: (state, data) => {
            state.orderBy = data;
        },
        SET_CURRENT_PAGE: (state, currentPage) => {
            state.currentPage = currentPage;
        }
    },
    actions: {
        GET_MANAGE_ARTISTS({commit, state}, params) {
            commit('SET_MANAGE_ARTIST_LOADER', true);
            commit('SET_MANAGE_ARTISTS', []);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get(`artists`, {signal, params: params})
                .then(artists => {
                    commit('SET_MANAGE_ARTISTS', artists.data);
                })
                .catch(err => console.log(`GET_MANAGE_ARTISTS, ${err}`))
                .finally(() => {
                    commit('SET_MANAGE_ARTIST_LOADER', false);
                })
        }
    },
    getters: {
        TOP_FILTER: state => state.topFilter,
        SEARCH_ACCOUNTS: state => state.searchAccounts,
        MANAGE_ARTISTS: state => state.manageArtists,
        MANAGE_ARTIST_LOADER: state => state.manageArtistsLoader,
        ORDER_BY: state => state.orderBy,
        COUNT_ACCOUNTS: state => state.countAccounts,
        CURRENT_PAGE: state => state.currentPage,
    }
}