<template>
    <v-dialog
        :value="value" @input="$emit('input'); cancelDialog()"
        max-width="574">

        <div class="need_login_dialog style_dialog">
            <h2 class="header">
                Oops!
            </h2>
            <div class="cnt">
                You have to be logged in to do that!
            </div>
            <div class="wrap_actions">
                <button type="button" class="cancel" @click="cancelDialog" v-ripple>Cancel</button>
                <button type="button" class="go_to_login" @click="goToLogin">Log-in</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "needLogin",
    props: ['value', 'action'],
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
	created() {
		this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', null);
	},
	methods: {
		cancelDialog() {
			this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', false);
			this.dialog = false;
			localStorage.removeItem('add_album_to_favorites');
			localStorage.removeItem('add_track_to_favorites');
		},
        goToLogin() {
	        this.$store.commit('SET_NEED_LOGIN_REDIRECT_PATH', this.$route.path);
	        this.$router.push({name: 'login'});
	        this.$store.commit('SET_AFTER_ACTION_DIALOG_NEED_LOGIN', true);
	        this.dialog = false;
        }
    }
}
</script>