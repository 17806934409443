import {jwtDecrypt} from '@/utils/jwtHelper';
import axios from 'axios';
import createMultiTabState from 'vuex-multi-tab-state';

const initialState = () => ({
    authData: {
        accessToken: '',
        refreshToken: '',
        tokenExp: '',
        userRoles: [],
        userName: '',
        userId: '',
        verify: '',
        firstLogin: ''
    },

    tokenAlive: '',
    loginStatus: {},
    registerStatus: '',
    registerErrorMessage: '',

    forgotPasswordStatus: '',
    resetPasswordStatus: {},
    sendVerifyCodeStatus: {},
    changeEmailStatus: false,

    userProfile: '',
});

export default {
    state: initialState(),

    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SAVE_TOKENS(state, tokens) {
            const accessToken = tokens.token;
            const refreshToken = tokens.refresh_token;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            const jwtDecodedValue = jwtDecrypt(accessToken);

            state.authData.accessToken = accessToken;
            state.authData.refreshToken = refreshToken;
            state.authData.tokenExp = jwtDecodedValue.exp;
            state.authData.userRoles = jwtDecodedValue.roles;
            state.authData.userName = jwtDecodedValue.username;
            state.authData.userId = jwtDecodedValue.userId;
            state.authData.verify = jwtDecodedValue.is_verified;
            state.authData.firstLogin = jwtDecodedValue.first_login;
        },
        SET_LOGIN_STATUS(state, data) {
            state.loginStatus = data;
        },
        SET_REGISTER_STATUS: (state, value) => {
            state.registerStatus = value;
        },
        SET_REGISTER_ERROR_MESSAGE: (state, value) => {
            state.registerErrorMessage = value;
        },
        SET_FORGOT_PASSWORD_STATUS(state, value) {
            state.forgotPasswordStatus = value;
        },
        SET_RESET_PASSWORD_STATUS(state, data) {
            state.resetPasswordStatus = data;
        },
        SET_SEND_VERIFY_CODE_STATUS: (state, data) => {
            state.sendVerifyCodeStatus = data;
        },
        SET_LOGOUT(state) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
        SET_CHANGE_EMAIL_STATUS: (state, status) => {
            state.changeEmailStatus = status;
        },
        SET_TOKEN_ALIVE: (state, status) => {
            state.tokenAlive = status;
        },
    },
    actions: {
        async LOGIN({commit}, userData) {
            localStorage.setItem('TEMP_USER_EMAIL', userData.email);
            console.log('action LOGIN');
            this.dispatch('CLEAR_STORE');
            await axios.post('authentication_token', userData)
                .then(response => {
                    if (response && response.data) {
                        commit('SAVE_TOKENS', response.data);

                        this.dispatch('GET_PROFILE');

                        commit('SET_LOGIN_STATUS', {
                            success: true,
                            message: response
                        });
                    } else {
                        commit('SET_LOGIN_STATUS', {
                            success: false,
                            message: response
                        });
                    }
                })
                .catch((error) => {
                    commit('SET_LOGIN_STATUS', {
                        success: false,
                        message: error.response.data.message
                    });
                    console.error(`LOGIN ${error}`);
                });
        },
        async LOGOUT({commit}) {
            await commit('SET_LOGOUT');
            this.dispatch('CLEAR_STORE');
            console.log('action LOGOUT');
        },
        async REGISTER({commit}, userData) {
            localStorage.setItem('TEMP_USER_EMAIL', userData.email);
            localStorage.setItem('TEMP_USER_PASSWORD', userData.password);
            this.dispatch('CLEAR_STORE');
            console.log('action REGISTER');
            await axios.post('users', userData)
                .then((response) => {
                    if (response.data) {
                        this.dispatch('GET_PROFILE');
                        commit('SET_REGISTER_STATUS', 'success');
                    } else {
                        commit('SET_REGISTER_STATUS', 'failed');
                    }
                })
                .catch((err) => {
                    commit('SET_REGISTER_ERROR_MESSAGE', err.response.data['hydra:description'].replace('email:', ''));
                    commit('SET_REGISTER_STATUS', 'failed');
                    console.error(`REGISTER ${err}`);
                })
        },
        async FORGOT_PASSWORD({commit}, email) {
            await axios.post(`reset_password_request`, email)
                .then(response => {
                    if (response && response.data.result) {
                        commit('SET_FORGOT_PASSWORD_STATUS', 'success');
                    } else {
                        commit('SET_FORGOT_PASSWORD_STATUS', 'failed');
                    }
                })
                .catch(err => {
                    commit('SET_FORGOT_PASSWORD_STATUS', 'failed');
                    console.error(`FORGOT_PASSWORD ${err}`);
                });
        },
        async REFRESH_TOKEN({commit}, refreshToken) {
            return await axios.post(`token/refresh`, {'refresh_token': refreshToken})
                .then(response => {
                    if (response) {
                        commit('SAVE_TOKENS', response.data);
                    } else {
                        console.error('REFRESH_TOKEN !response LOGOUT', response);
                        commit('SET_LOGOUT');
                    }
                })
                .catch(err => {
                    commit('SET_LOGOUT');
                    console.error(`REFRESH_TOKEN ${err}`);
                });
        },
        RESET_PASSWORD({commit}, params) {
            return axios.post(`reset_password`, params)
                .then(response => {
                    commit('SET_RESET_PASSWORD_STATUS', {
                        success: true,
                        message: response
                    });
                })
                .catch(err => {
                    commit('SET_RESET_PASSWORD_STATUS', {
                        success: false,
                        message: err.response.data.error
                    });
                    console.error(`RESET_PASSWORD ${err}`);
                })
        },
        CHECK_TOKEN_ALIVE({commit}) {
            const token = localStorage.getItem('accessToken');
            if (token) {
                const accessToken = jwtDecrypt(token);
                const accessTokenExp = accessToken.exp;
                commit('SET_TOKEN_ALIVE', !(!accessTokenExp || Date.now() >= accessTokenExp * 1000));
            }
        },
        CHANGE_EMAIL({commit}, email) {
            return axios.patch(`change_email`, {'email': email})
                .then(response => {
                    commit('SET_CHANGE_EMAIL_STATUS', true);
                })
                .catch(err => {
                    commit('SET_CHANGE_EMAIL_STATUS', false);
                    console.error(`CHANGE_EMAIL, ${err}`);
                })
        },
        SEND_EMAIL_VERIFICATION({commit}) {
            return axios.post(`send_email_verification`)
                .then(response => {
                })
                .catch(err => {
                    console.error(`SEND_EMAIL_VERIFICATION, ${err}`);
                })
        },
        SEND_VERIFY_CODE({commit}, params) { // {email: string; code: string}
            return axios.post(`verify/code`, params)
                .then(response => {
                    commit('SET_SEND_VERIFY_CODE_STATUS', {
                        success: true,
                        tokens: response.data
                    });
                })
                .catch(err => {
                    commit('SET_SEND_VERIFY_CODE_STATUS', {
                        success: false,
                        message: err.response.data.error
                    });
                    console.error(`SEND_VERIFY_CODE, ${err}`)
                });
        },
        RESEND_VERIFY_CODE({commit}, params) { // {email: string}
            return axios.post(`resend/code`, params)
                .catch(err => console.error(`RESEND_VERIFY_CODE, ${err}`))
        },
    },

    getters: {
        AUTH_DATA: state => state.authData,
        userID: state => state.authData.userId,
        isAdmin: state => state.authData.userRoles.includes('ROLE_ADMIN'),

        LOGIN_STATUS: state => state.loginStatus,

        REGISTER_STATUS: state => state.registerStatus,
        REGISTER_ERROR_MESSAGE: state => state.registerErrorMessage,

        FORGOT_PASSWORD_STATUS: state => state.forgotPasswordStatus,
        RESET_PASSWORD_STATUS: state => state.resetPasswordStatus,
        SEND_VERIFY_CODE_STATUS: state => state.sendVerifyCodeStatus,

        CHANGE_EMAIL_STATUS: state => state.changeEmailStatus,
        IS_TOKEN_ACTIVE: state => state.tokenAlive,
    },
    plugins: [
        createMultiTabState({
            statesPaths: ['authData.accessToken', 'authData.refreshToken', 'authData.tokenExp', 'authData.userRoles', 'authData.userName', 'authData.userId', 'authData.verify', 'authData.firstLogin'],
            onBeforeSave: (state) => {
                // Modify state here
                return state;
            },
            onBeforeReplace: (state) => {
                // Modify state here
                return state;
            }
        }),
    ],
};
