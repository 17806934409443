<template>
  <div>
    <v-bottom-sheet 
      :content-class="'sub_genres_dialog sub_genres_wrap fullscreen-sheet'" 
      v-model="localDialog" 
      fullscreen
    >
      <template v-slot:activator="{ on }">
        <div class="mobile_filter_item" v-on="on">
          <span class="top">Subgenre</span>
          <span class="bottom">{{ subgenre.id ? subgenre.name : 'All'}}</span>
        </div>
      </template>
      <v-sheet style="height: 100vh;" class="text-left subGenresDialog">
        <div class="style_dialog subGenres">
          <div class="header_container">
            <h2 class="header">Subgenre</h2>
            <div class="close" @click="localDialog = false"></div>
          </div>
          
          <div class="subGenresItems" style="overflow-y: auto;">
            <button type="button" class="button" @click="selectSubgenre({})" :class="{'active': !subgenre.id}">All</button>
            <button 
              class="button" 
              :class="{'active': (subgenre && subgenre.id === item.id)}" 
              v-for="item of subgenres" 
              :key="item.id" 
              type="button" 
              @click="selectSubgenre(item)"
            >
              {{ item.name }}
            </button>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'SubgenreFilterCard',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    subgenres: {
      type: Array,
      default: () => []
    },
    filterName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localDialog: false,
      subgenre: {}
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.subgenre = newValue;
      },
      immediate: true
    },
    dialog(newValue) {
      this.localDialog = newValue;
    },
    localDialog(newValue) {
      this.$emit('update:dialog', newValue);
    }
  },
  methods: {
    selectSubgenre(subgenre) {
      this.$emit('input', subgenre);
      this.$emit('select', subgenre);
      this.localDialog = false;
    }
  }
};
</script>

<style scoped>
.mobile_filter_item {
  width: calc((100vw - 40px) / 3);
  border-left: 1px solid #2E3548;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile_filter_item .top {
  color: #8591B1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile_filter_item .bottom {
  color: #FFF;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
}

.fullscreen-sheet .style_dialog.subGenres {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1E2335;
}

.fullscreen-sheet .subGenresItems {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 80px;
}

.style_dialog.subGenres .subGenresItems {
  display: flex;
  flex-direction: column;
  height: 90%;
  scrollbar-width: none;
}

.style_dialog.subGenres .subGenresItems button {
  border: 1px solid #5F6986;
  border-radius: 12px;
  padding: 11px 15px;
  margin: 5px 0;
}

.style_dialog.subGenres .subGenresItems button.active {
  background-color: #5F6986;
}

.style_dialog.subGenres .header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.style_dialog.subGenres .header_container .header {
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  align-items: start;
  justify-content: start;
  margin-bottom: 0;
  background-image: none;
  padding-bottom: 0;
}

.style_dialog.subGenres .header_container .close {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.949748 10.8492L10.8492 0.949666M10.8492 10.8492L0.949748 0.949666' stroke='%23ffffff' stroke-width='2.4611'/%3e%3c/svg%3e ");
  background-repeat: no-repeat;
  background-size: contain;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
</style>