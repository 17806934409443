import identityVerification from "@/pages/dashboard/identityVerification";
import checkOut from "@/pages/checkOut.vue";
import membershipCheckOut from "@/pages/checkOutMembership.vue";

export default [
    {
        path: '/create-music-release/:id?',
        name: 'createMusicRelease',
        props: true,
        component: () => import('@/pages/musicRelease/createMusicRelease'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/upload-verification/:artistId',
        name: 'upload-verification',
        component: identityVerification,
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
        props: true
    },
    {
        path: '/check-out',
        name: 'checkOut',
        props: true,
        component: checkOut,
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/membership-check-out',
        name: 'membershipCheckOut',
        props: true,
        component: membershipCheckOut,
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
]
