<template>
	<div v-if="musicRelease" id="list-album"
	     :class="['album',
             { short: ALBUM_EXPANDED && ALBUM_EXPANDED !== musicRelease.id },
             { active: isCurrentPlaying },
             { is_listened: musicRelease.isListened },
             {'pending': musicRelease.status === 'pending' && this.$router.history.current.name === 'explore'},
             {'deleted': currentlyUnavailable}
         ]"
	     :ref="`album-${musicRelease.id}`" @mouseover="showTooltip" @mouseleave="hideTooltip">

		<!--		<div v-if="currentlyUnavailable" class="currently_unavailable" ref="tooltip">-->
		<!--			Currently unavailable-->
		<!--		</div>-->

		<!-- SHORT ALBUM -->
		<div v-if="ALBUM_EXPANDED !== musicRelease.id && !musicRelease.extend" class="short_album" @click="changeVisibilityStatus(musicRelease, false)">
			<div class="description_info">

				<div class="cover_img" :class="{active: isCurrentPlaying}">
					<img :src="musicRelease.smallImage" alt="">
					<button v-if="!isCurrentPlaying" @click="changeVisibilityStatus(musicRelease, true)" class="play_album"
					        :class="[isCurrentPlaying ? 'current' : 'not_current']">
						<span class="material-icons">play_arrow</span>
					</button>
					<button v-if="isCurrentPlaying && playing" class="pause_album">
						<span @click.stop="pause"></span>
					</button>
					<button v-if="isCurrentPlaying && !playing" @click="play" class="play_album">
						<span class="material-icons">play_arrow</span>
					</button>
				</div>

				<div class="wrap_like_album" :class="{'hidden_like_album': hideLike}">
					<div class="release_deleted" v-if="currentlyUnavailable" @click.stop="removeRelease"></div>
					<template v-else>
						<template v-if="!musicRelease.isPurchased || musicRelease.isPurchased !== true">
							<template v-if="!hideLike">
								<button v-if="musicRelease.isFavorite === false" class="like" @click.stop="addAlbumToFavorites(musicRelease)"></button>
								<button v-if="musicRelease.isFavorite === true" class="like active"
								        @click.stop="removeAlbumFromFavorites(musicRelease)"></button>
							</template>
						</template>
						<template v-else>
							<div class="release_purchased"></div>
						</template>
					</template>
				</div>

				<div class="name">
                    <span @click.self="redirectToAlbum(musicRelease)">
                        {{ musicRelease.title }}
                    </span>
				</div>

				<div class="artist">
					<template v-if="musicRelease.type === 'album'">
						<span v-if="musicRelease.artist" @click="redirectToArtistPage(musicRelease.artist.id, musicRelease.artist.identifier)">
							{{ musicRelease.artist.name }}
						</span>
						<div class="artist" v-else>-</div>
					</template>
					<template v-if="musicRelease.type === 'collection'">
						<span>Various Artists</span>
					</template>
				</div>

				<div class="released" v-if="SHOW_COLUMNS.includes('released')">
					{{ musicRelease.releasedAt | moment("MMM, YYYY") }}
				</div>

				<div class="downloads" v-if="SHOW_COLUMNS.includes('downloads')">{{ musicRelease.downloaded }}</div>
			</div>
			<div class="actions_albums">
				<!--				<template v-if="CAN_PURCHASE_MUSIC_RELEASE && CAN_PURCHASE_MUSIC_RELEASE === true">-->
				<template v-if="!musicRelease.isPurchased || musicRelease.isPurchased !== true">
					<button type="button" class="album_paid" v-if="musicRelease.price > 0 && musicRelease.inCart === false"
					        :class="{album_in_cart: musicRelease.inCart === true}">
						<span class="price">${{ musicRelease.price }}</span>
						<span class="add_to_cart" @click.stop="setAlbumYourPrice('buy')">Add to Cart</span>
					</button>

					<button type="button" class="album_paid" id="open-header-shopping-cart"
					        v-if="musicRelease.inCart === true" :class="{album_in_cart: musicRelease.inCart === true}" @click.stop="openCart">
						In Cart
					</button>
					<button type="button" class="album_syp" v-if="musicRelease.price === 0 && musicRelease.inCart === false">
						<span class="default_name">SYP</span>
						<span class="full_name" @click.stop="setAlbumYourPrice('set')">Set your price</span>
					</button>
				</template>
				<!--				</template>-->

				<button v-if="musicRelease.isPurchased && musicRelease.isPurchased === true || !musicRelease.price && musicRelease.price !== 0"
				        type="button"
				        class="download"
				        @click.stop="checkPossibilityDownloadAlbum(musicRelease)">
					Download
				</button>

				<albumActions
					:albumID="musicRelease.id"
					:albumStatus="!musicRelease.status ? null : musicRelease.status"
					:isOwner="isOwner"
					:albumLocation="albumLocation"
				></albumActions>
			</div>
		</div>

		<!-- EXTEND ALBUM-->
		<div v-if="ALBUM_EXPANDED === musicRelease.id || musicRelease.extend" class="album_content item_list">
			<div class="left_box">
				<div class="cover_album">
					<!--					<div class="license" v-if="license">-->
					<!--						<span>Released under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">Creative Commons license</a></span>-->
					<!--						<span>For direct access visit <a href="https://ektoplazm.com/" target="_blank">www.Ektoplazm.com</a></span>-->
					<!--					</div>-->
					<img :src="musicRelease.smallImage" @click="$refs['modal-image'].show()" :alt="musicRelease.title">

					<!------------------ Modal Image ------------------>
					<modalImage ref="modal-image" :image="musicRelease.bigImage"/>
				</div>
			</div>

			<div class="info_album" :class="{'disabled': musicRelease.status && musicRelease.status === 'draft'}">
				<div class="head_album">
					<div class="titles">
						<div class="album_title" @click.self="redirectToAlbum(musicRelease)">
							{{ musicRelease.title }}
						</div>

						<div class="owner">
							<div class="album_artist" v-if="!isAdmin">Artist:
								<template v-if="musicRelease.type === 'album'">
								<span v-if="musicRelease.artist" @click="redirectToArtistPage(musicRelease.artist.id, musicRelease.artist.identifier)">
									{{ musicRelease.artist.name }}
								</span>
									<div class="artist" v-else>-</div>

									<a @click="dialogAreYouThisArtistVisible = true" v-if="!isOwner" class="is_this_you">(is this you?)</a>
									<are-you-this-artist-dialog v-if="dialogAreYouThisArtistVisible" :artist="musicRelease.artist"
									                            v-model="dialogAreYouThisArtistVisible"></are-you-this-artist-dialog>
								</template>
								<template v-if="musicRelease.type === 'collection'">
									<span>Various Artists</span>
								</template>
							</div>
							<div class="album_label" v-if="!isAdmin && musicRelease.recordLabel">Label:
								<span @click="redirectToLabelProfile(musicRelease.recordLabel.id, musicRelease.recordLabel.identifier)">
									{{ musicRelease.recordLabel.title }}
								</span>
							</div>
						</div>


						<div class="info_admin" v-if="isAdmin">
							<div class="_artist_">
								Artist:
								<span v-if="musicRelease.artist" @click="redirectToArtistPage(musicRelease.artist.id, musicRelease.artist.identifier)">
									{{ musicRelease.artist.name }}
								</span>

								<template v-if="musicRelease.type === 'collection'">
									<span>Various Artists</span>
								</template>
							</div>
							<div class="_label_" v-if="musicRelease.recordLabel">
								Label:
								<span @click="redirectToLabelProfile(musicRelease.recordLabel.id, musicRelease.recordLabel.identifier)">
									{{ musicRelease.recordLabel.title }}
								</span>
							</div>
							<div class="_user_" v-if="musicRelease.owner && musicRelease.owner.fullName">
								User: <span>
                                <span @click="redirectToUserProfile(musicRelease.owner.id,musicRelease.owner.fullName)">
                                    {{ musicRelease.owner.fullName }}
                                </span>
                            </span>
							</div>
							<div class="_total_uploads_" v-if="musicRelease.owner && musicRelease.owner.musicReleaseCount">
								Total Uploads: <span>{{ musicRelease.owner.musicReleaseCount }}</span>
							</div>
						</div>
					</div>
					<div class="actions">
						<div class="date">{{ musicRelease.releasedAt  | moment('LL') }}</div>
						<!--                        <button v-if="musicRelease.isFavorite === false" class="like" @click="addLike(musicRelease.id)"></button>-->
						<!--                        <button v-if="musicRelease.isFavorite === true" class="like active" @click="removeLike(musicRelease.id)"></button>-->
						<template v-if="!musicRelease.isPurchased || musicRelease.isPurchased !== true">
							<albumDetailsLikeButton :checked="musicRelease.isFavorite === true" :album="musicRelease"></albumDetailsLikeButton>
						</template>
						<template v-else>
							<div class="release_purchased"></div>
						</template>
						<albumActions :albumID="musicRelease.id"
						              :albumStatus="!musicRelease.status ? null : musicRelease.status"
						              :isOwner="isOwner"
						              :albumLocation="albumLocation"
						></albumActions>
					</div>
				</div>

				<div class="tags_list" v-if="musicRelease.tags && musicRelease.tags.length">
					<span v-ripple v-for="(tag, index) of musicRelease.tags" :key="tag + index" @click="selectTag(tag)">{{ tag }}</span>
				</div>


				<div class="buy_now" v-if="userID && (musicRelease.isFavorite || !PLAYED_ONLY_FAV_TRACKS)">

					<button type="button" class="download_album c2a_btn btn_buy_album"
					        v-if="musicRelease.isPurchased && musicRelease.isPurchased === true || !musicRelease.price && +musicRelease.price !== 0"
					        @click="checkPossibilityDownloadAlbum(musicRelease.id, musicRelease.title)">
						Download
					</button>

					<template v-else>
						<!--						<v-skeleton-loader v-if="canPurchaseMusicRelease === null && !CAN_PURCHASE_MUSIC_RELEASE" type="image" class="btn_skeleton_buy"></v-skeleton-loader>-->

						<!--						<template v-if="CAN_PURCHASE_MUSIC_RELEASE && CAN_PURCHASE_MUSIC_RELEASE === true">-->
						<!--							<template v-if="!musicRelease.isPurchased || musicRelease.isPurchased !== true">-->
						<button type="button" class="c2a_btn btn_buy_album" v-if="musicRelease.price > 0 && musicRelease.inCart === false" v-ripple
						        @click="setAlbumYourPrice('buy')">
							Buy Now ${{ musicRelease.price }}
							<span>or more</span>
						</button>
						<button type="button" class="cart_add_remove" id="open-header-shopping-cart" v-if="musicRelease.inCart === true" @click="openCart"
						        v-ripple>
							<span class="in_cart">In Cart</span>
						</button>

						<button type="button" class="c2a_btn btn_buy_album" v-if="musicRelease.price === 0 && musicRelease.inCart === false" v-ripple
						        @click.stop="setAlbumYourPrice('set')">
							Set Your Price
						</button>
						<!--							</template>-->
						<!--						</template>-->
					</template>


				</div>


				<!-- Description Album -->
				<template v-if="musicRelease.description !== null && musicRelease.description !== undefined">
					<div class="descript_info text" v-if="musicRelease.description.length < albumDescriptionLength" v-html="musicRelease.description"></div>
					<template v-else>
                      <span class="descript_info text">
                        <p v-html="musicRelease.description.substring(0, albumDescriptionLength)"></p>
                        <p>... </p>
                        <p v-if="musicRelease.description.length > albumDescriptionLength" class="see_more"
                           @click="seeMore">Show more</p>
                      </span>
					</template>
				</template>

				<album-play-list
					v-if="musicRelease.playList"
					:album="musicRelease"
					:albumLocation="albumLocation">
				</album-play-list>

			</div>

			<div class="album_background" v-bind:style="{'background-image': 'url('+(albumBackground)+')'}"></div>

			<v-progress-circular v-if="albumDetailsLoading"
			                     :width="3"
			                     :size="70"
			                     indeterminate
			                     color="#20c4f5"
			></v-progress-circular>
		</div>
		<template v-if="musicRelease.id === ALBUM_EXPANDED && UPPER_FILTER === 'pending'">
			<div class="notes_album" v-if="musicRelease.status.value === 'pending' && this.$router.history.current.name === 'explore'"
			     :class="{'active': showCommentsID === musicRelease.id}">
				<div class="notes_head" @click.self="showAlbumAdminComments(musicRelease.id)">
					<div class="title_head">Notes <span class="count_notes">{{ musicRelease.commentsCount }}</span></div>
					<button type="button" class="close_notes" v-if="showCommentsID === musicRelease.id" @click.self="closeComments">
						Close
					</button>
				</div>
				<comments v-if="showCommentsID && showCommentsID === musicRelease.id" :albumID="musicRelease.id" :commentsLocation="'album_admin'"></comments>
			</div>
		</template>

		<downloadAlbumDialog v-if="downloadAlbumDialogVisible" v-model="downloadAlbumDialogVisible"></downloadAlbumDialog>
		<setYouAlbumPrice v-if="setYouAlbumPriceDialogVisible"
		                  v-model="setYouAlbumPriceDialogVisible"
		                  :album="musicRelease"
		                  :setAlbumYourPriceModalVariant="setAlbumYourPriceModalVariant"
		                  :type="'music_release'">
		</setYouAlbumPrice>
		<dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible" :action="needLoginDialog.action"></dialogNeedLogin>
		<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
	</div>
</template>

<script>
import albumActions from "@/components/musicReleases/albumActions";
import albumPlayList from './albumPlayList.vue';
import {mapActions, mapGetters} from 'vuex';
import dialogNeedLogin from '@/components/dialogs/needLogin';
import areYouThisArtistDialog from '@/components/dialogs/areYouThisArtist';
import ModalImage from "@/components/public/modalImage";
import albumDetailsLikeButton from '@/components/small/albumDetailsLikeButton';

// mixins
import addAlbumToFavorites from "@/mixins/musicLike/addAlbumToFavorites";
import removeAlbumFromFavorites from "@/mixins/musicLike/removeAlbumFromFavorites";

const downloadAlbumDialog = () => import("@/components/dialogs/downloadAlbum");
const setYouAlbumPrice = () => import("@/components/dialogs/setYouAlbumPrice");
const comments = () => import("@/components/commentsV2");
const errorDialog = () => import('@/components/dialogs/error');

export default {
	name: 'album',
	props: ['albumDetails', 'discoveryStatus', 'index', 'isFavouriteSong', 'hideLike', 'albumLocation', 'playZone', 'playOnlyLikedTracks'],

	components: {
		albumActions,
		albumPlayList,
		dialogNeedLogin,
		areYouThisArtistDialog,
		ModalImage,
		albumDetailsLikeButton,
		downloadAlbumDialog,
		setYouAlbumPrice,
		comments,
		errorDialog
	},
	mixins: [
		addAlbumToFavorites,
		removeAlbumFromFavorites,
	],
	data() {
		return {
			musicRelease: this.albumDetails,
			isOwner: false,
			selectedAlbum: null,
			DownloadBtnShow: false,
			activeTabs: null,
			showAlbum: null,
			artistID: null,
			artistName: null,
			// needLoginDialogVisible: false,
			needLoginDialog: {
				action: null,
				visible: false,
			},
			albumDescriptionLength: 500,
			dialogAreYouThisArtistVisible: false,
			downloadMessageDialogVisible: false,
			setYouAlbumPriceDialogVisible: false,
			coverAlbumDialog: false,

			showDownloadAlbum: true,
			showCommentsID: null,
			albumDetailsLoading: false,
			albumBackground: this.albumDetails.smallImage,

			downloadLimit: 0,
			numberOfDownload: 0,
			downloadsLeft: 0,
			loading: false,
			downloadDisable: false,
			downloadAlbumDialogVisible: false,
			license: false,
			sypAlbumSelected: null,
			showAddToCartBtn: false,
			timeoutId: null,
			setAlbumYourPriceModalVariant: null,
			currentlyUnavailable: false,
			errorDialog: false,
			canPurchaseMusicRelease: null,
		}
	},
	created() {
		let status = null;

		if (this.musicRelease.status.value) {
			status = this.musicRelease.status.value;
		} else {
			status = this.musicRelease.status;
		}
		if (status !== 'pending' && !this.isAdmin) {
			if (this.musicRelease.deletedAt && this.musicRelease.havePurchased === false || (status !== 'published' && status !== 'grandfathered')) {
				this.currentlyUnavailable = true;
			}
		}

		if (this.musicRelease.owner) {
			let ownerID = this.musicRelease.owner.id;
			if (+this.userID === +ownerID) {
				this.isOwner = true;
			}
		}
	},
	computed: {
		...mapGetters({
			playing: 'PLAYING',
			currentAlbum: 'CURRENT_ALBUM',
			playList: 'PLAY_LIST',
		}),
		...mapGetters([
			'isAdmin', 'userID', 'AUTH_DATA', 'USER_PERMISSIONS', 'SHOW_COLUMNS', 'ALBUM_EXPANDED', 'ALBUM',
			'MUSIC_RELEASE', 'EXPLORE_GRID_ALBUMS', 'UPPER_FILTER',
			'PLAYED_ONLY_FAV_TRACKS', 'COMMENTS',
			'CHECK_MUSIC_RELEASE_DOWNLOAD', 'FULL_ALBUM', 'FULL_ALBUM_LOADING', 'MUSIC_RELEASE_COMMERCE', 'DOWNLOAD_MUSIC_RELEASE_LINK', 'MUSIC_RELEASE_DOWNLOAD_DATA',
			'CAN_PURCHASE_MUSIC_RELEASE', 'CAN_PURCHASE_TRACK'
		]),
		isCurrentPlaying() {
			return !!this.currentAlbum && this.currentAlbum.id === this.musicRelease.id;
		},
		albumInPlayList() {
			const playlist = Object.fromEntries(this.playList.map(({id}) => [id, true]));
			return this.musicRelease.playList.every(({id}) => (playlist[id]));
		},
	},
	mounted() {
		if (this.ALBUM_EXPANDED) {
			const currentAlbum = this.$refs[`album-${this.ALBUM_EXPANDED}`];
			let currentRoute = this.$router.history.current.name;

			if (this.FULL_ALBUM && this.musicRelease.id === this.ALBUM_EXPANDED) {
				if (this.musicRelease.id === this.FULL_ALBUM.id) {
					this.musicRelease = this.FULL_ALBUM;
				} else {
					let params = {
						id: this.ALBUM_EXPANDED,
						name: this.musicRelease.identifier
					}
					this.getFullAlbum(params)
						.then(() => {
							this.musicRelease = this.FULL_ALBUM;
							// this.checkCanPurchaseMusicRelease(this.musicRelease.id)
							// 	.then((response) => {
							// 		this.canPurchaseMusicRelease = response;
							// 	})
						})
				}
			} else {
				this.albumDetailsLoading = true;
			}

			if (currentAlbum && (currentRoute === 'explore'  || currentRoute === 'subgenre' || currentRoute === 'searchDetails')) {
				this.$nextTick(() => {
					currentAlbum.scrollIntoView({
						// behavior: 'smooth',
						block: 'center'
					});
				})
			}
		}
	},
	beforeDestroy() {
		// document.removeEventListener("click", this.closeAlbum);
	},
	methods: {
		...mapActions({
			play: 'SET_PLAY',
			pause: 'SET_PAUSE',
			addAlbumToPlaylist: 'ADD_ALBUM_TO_PLAYLIST',
			getAlbum: 'GET_ALBUM',
			getAlbums: 'GET_MUSIC_RELEASE',
			changeMusicReleaseStatus: 'CHANGE_ALBUM_STATUS',
			getComments: 'GET_COMMENTS',
			getCheckMusicReleaseDownload: 'GET_CHECK_MUSIC_RELEASE_DOWNLOAD',
			getFullAlbum: 'GET_FULL_ALBUM',
			addAlbumToBasket: 'ADD_TO_BASKET',
			removeAlbumFromBasket: 'REMOVE_FROM_BASKET',
			GET_DOWNLOAD_MUSIC_RELEASE_LINK: 'GET_DOWNLOAD_MUSIC_RELEASE_LINK',
			removeReleaseFromFavorites: 'REMOVE_ALBUM_FROM_FAVORITES',
			checkCanPurchaseMusicRelease: 'CHECK_CAN_PURCHASE_MUSIC_RELEASE',
			checkCanPurchaseTrack: 'CHECK_CAN_PURCHASE_TRACK',
		}),

		checkPossibilityDownloadAlbum(musicRelease) {
			let albumID = musicRelease.id;
			let albumTitle = musicRelease.title;
			let albumDeletedAt = musicRelease.deletedAt;
			let albumHavePurchased = musicRelease.isPurchased;

			if (this.AUTH_DATA.accessToken) {
				this.$store.commit('SET_MUSIC_RELEASE_DOWNLOAD_DATA', {id: albumID, title: albumTitle});

				if (albumHavePurchased || this.musicRelease.owner && this.musicRelease.owner.id === this.userID) {
					this.directDownload();
				} else if (!albumDeletedAt || (albumDeletedAt && albumHavePurchased === true)) {
					this.getCheckMusicReleaseDownload()
						.then(() => {
							let remain = this.CHECK_MUSIC_RELEASE_DOWNLOAD.limit - this.CHECK_MUSIC_RELEASE_DOWNLOAD.downloaded;
							if (remain <= 10) {
								this.downloadAlbumDialogVisible = true;
							} else {
								this.directDownload();
							}
						})
				} else {
					console.log('download failure');
					this.errorDialog = true;
				}
			} else {
				this.needLoginDialog = {
					visible: true,
					action: 'download_album'
				}
			}
		},
		directDownload() {
			this.GET_DOWNLOAD_MUSIC_RELEASE_LINK(this.MUSIC_RELEASE_DOWNLOAD_DATA.id)
				.then(() => {
					if (this.DOWNLOAD_MUSIC_RELEASE_LINK) {
						let link = document.createElement('a');
						link.href = this.DOWNLOAD_MUSIC_RELEASE_LINK;
						link.download = `${this.MUSIC_RELEASE_DOWNLOAD_DATA.title}`;
						link.click();
					} else {
						this.errorDialog = true;
					}
				})
				.catch(err => {
					console.error(`download-purchased, download music-release, ${err}`);
				})
		},

		redirectToAlbum(musicRelease) {
			let albumDeletedAt = musicRelease.deletedAt;
			let albumHavePurchased = musicRelease.havePurchased;
			let status = musicRelease.status;

			if (this.USER_PERMISSIONS !== 'free' && !albumDeletedAt && status !== 'draft' || (albumDeletedAt && albumHavePurchased === true)) {
				// const albumTitle = musicRelease.title.trim().replace(/ /g, '-');
				console.log('musicRelease', musicRelease);
				this.$router.push({
					name: 'albumDetails',
					params: {name: window.toHumanFormat(musicRelease.identifier), id: musicRelease.id, favourite: this.isFavouriteSong}
				});
			}
		},
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		redirectToLabelProfile(labelId, labelName) {
			this.$router.push({name: 'label-profile', params: {id: labelId, name: window.toHumanFormat(labelName)}});
		},
		redirectToUserProfile(userID, userName) {
			const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
			window.open(routeData.href, '_blank');
		},

		changeVisibilityStatus(musicRelease, playAlbum) {
			if (this.USER_PERMISSIONS !== 'free' && !musicRelease.deletedAt || (musicRelease.deletedAt && musicRelease.havePurchased === true)) {
				let id = musicRelease.id;
				let title = musicRelease.identifier;

				this.$store.commit('SET_ALBUM_EXPANDED', id);

				if (this.ALBUM_EXPANDED) {
					const currentAlbum = this.$refs[`album-${id}`];
					let currentRoute = this.$router.history.current.name;

					if (this.FULL_ALBUM && this.FULL_ALBUM.id === id) {
						this.musicRelease = this.FULL_ALBUM;
					} else {
						this.albumDetailsLoading = true;

						let params = {id: id, name: title};
						this.getFullAlbum(params)
							.then(() => {
								this.musicRelease = this.FULL_ALBUM;
								// this.checkCanPurchaseMusicRelease(this.musicRelease.id)
								// 	.then((response) => {
								// 		this.canPurchaseMusicRelease = response;
								// 	})
								if (playAlbum === true) {
									this.runPlay();
								}
							})
							.finally(() => this.albumDetailsLoading = false);
					}

					if (currentAlbum && currentRoute === 'explore') {
						this.$nextTick(() => {
							currentAlbum.scrollIntoView({
								behavior: 'smooth',
								block: 'center'  // nearest
							});
						});
					}
				}

			}
		},

		addAlbumToCart() {
			if (this.AUTH_DATA.accessToken) {
				this.musicRelease.inCart = true;
				this.addAlbumToBasket({'productId': this.musicRelease.id, 'type': 'music_release'})
					.then(() => {

					})
					.catch(err => console.error(err));
			} else {
				this.needLoginDialog = {
					visible: true,
					action: 'add_to_cart'
				}
			}
		},
		openCart() {
			if (this.AUTH_DATA.accessToken) {
				this.$store.commit('SET_HEADER_SHOPPING_CART', true);
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			} else {
				this.needLoginDialog = {
					visible: true,
					action: 'open_cart'
				}
			}
		},
		setAlbumYourPrice(val) {
			if (this.AUTH_DATA.accessToken) {
				this.setAlbumYourPriceModalVariant = val;
				this.sypAlbumSelected = this.musicRelease;
				this.setYouAlbumPriceDialogVisible = true;
			} else {
				this.needLoginDialog = {
					visible: true,
					action: 'set_your_price'
				}
			}
		},


		showTooltip(event) {
			if (this.musicRelease.deletedAt) {
				if (event.target.classList.contains('album')) {
					const album = event.currentTarget;

					const x = event.clientX - album.offsetLeft;
					// const y = event.clientY - album.offsetTop;

					this.$refs.tooltip.style.display = 'flex';
					this.$refs.tooltip.style.left = `${x - 350}px`;
					// this.$refs.tooltip.style.top = `${y - 40}px`
				}
			}
		},
		hideTooltip() {
			if (this.$refs.tooltip) {
				this.$refs.tooltip.style.display = 'none';
			}
		},

		tabs(activeTab) {
			this.activeTabs = activeTab;
		},
		playAlbum() {
			if (this.USER_PERMISSIONS !== 'free') {
				console.log('play album');
				let params = {id: this.musicRelease.id, title: this.musicRelease.title};
				this.getFullAlbum(params)
					.then(() => {
						this.musicRelease = this.FULL_ALBUM;
						this.runPlay();
					})
			}
		},
		runPlay() {
			if (!this.isCurrentPlaying) {

				this.$store.dispatch('SET_PLAYED_ZONE', this.playZone);
				let albumToPlay;

				if (this.playOnlyLikedTracks === true && !this.musicRelease.isPurchased) {
					albumToPlay = this.musicRelease.playList.filter(track => track.song.isFavorite === true);
				} else {
					albumToPlay = this.musicRelease.playList.filter(track => !track.song.deletedAt && track.song.link);
				}

				this.play({
					fromPlay: 'album_default',
					track: albumToPlay[0].song,
					index: 0,
					album: this.musicRelease,
					isDiscoveryStatus: this.discoveryStatus
				});
			}
		},

		seeMore() {
			this.albumDescriptionLength = this.musicRelease.description.length;
		},
		selectTag(tag) {
			this.$store.dispatch('UPDATE_TAGS', [tag]);
			this.$store.commit('SET_ALBUM_EXPANDED', null);
			this.scrollTop();
			this.$store.commit('SET_STATUS_FILTER', true);
		},

		scrollTop() {
			this.$nextTick(() => {
				const el = document.getElementById('explore-nav-id');
				if (el) {
					el.scrollIntoView({behavior: 'smooth'});
				}
			});
		},
		showAlbumAdminComments(albumID) {
			this.showCommentsID = albumID;
		},
		closeComments() {
			this.showCommentsID = null;
		},
		removeRelease() {
			const releaseID = this.musicRelease.id;
			this.$store.dispatch('REMOVE_RELEASE_FROM_COLLECTIONS', releaseID);
			this.removeReleaseFromFavorites({'music_release_id': releaseID})
				.then(() => {

				});
		}
	},

}
</script>
