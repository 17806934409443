export default [
    {
        path: '/settings/privacy',
        name: 'privacy',
        component: () => import('@/pages/settings/privacy.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/settings/notifications',
        name: 'notifications',
        component: () => import('@/pages/settings/notifications.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/settings/payments',
        name: 'payments',
        component: () => import('@/pages/settings/payments'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/settings/subscriptions',
        name: 'subscriptions',
        component: () => import('@/pages/settings/subscriptions'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/subscriptions-success',
        name: 'subscriptionSuccess',
        component: () => import('@/pages/after-actions/subscriptionSuccess.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/settings/wallet',
        name: 'wallet',
        component: () => import('@/pages/settings/wallet.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/settings/security',
        name: 'security',
        component: () => import('@/pages/settings/security.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/settings/membership',
        name: 'membership',
        component: () => import('@/pages/settings/membership.vue'),
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
]
