import Vue from 'vue';
import Vuex from 'vuex';

import constantData from "@/store/modules/constantData.store";
import globalSettings from "@/store/modules/globalSettings.store";
import actions from "@/store/modules/actions.store";

import main from './modules/main.store';
import auth from './modules/auth.store';
import clear from './modules/clear.store';

// import player from './modules/player.store';
import hlsPlayer from './modules/hlsPlayer.store';

// explore
import nav from './modules/explore/nav.store';
import exploreGetAlbums from './modules/explore/getAlbums.store';
import exploreFilters from './modules/explore/exploreFilters.store';

// library
import collection from './modules/library/collection';

// profiles
import privateProfile from './modules/profiles/privateProfile.store';
import userProfileGlobal from './modules/profiles/userProfileGlobal.store';
import artistProfile from './modules/profiles/artistProfile.store';

import currentUser from './modules/currentUser.store';

import mediaObjects from './modules/mediaObjects.store';

import albums from './modules/albums.store';
import musicRelease from './modules/musicRelease.store';

import favoriteMusicRelease from './modules/favoriteMusicRelease.store';

import track from './modules/track.store';
import playLists from './modules/playLists.store';

import artist from './modules/artist.store';
import artistVerificationRequest from './modules/artistVerificationRequest.store';
import artistVerificationRequestComment from './modules/artistVerificationRequestComment.store';
import adminNotifications from './modules/adminNotifications.store';
import playlist from './modules/playlist.store';
import faqs from './modules/faqs.store';
import route from "@/store/modules/route.store";
import comments from './modules/comments.store';
import search from './modules/search.store';
import supporters from './modules/supporters.store';
import suggestions from './modules/suggestions';
import manageUploaded from './modules/manageUploaded.store';

//events
import events from './modules/events/events.store';
import tickets from './modules/events/tickets.store';

//mobile
import mobileExplore from './modules/mobile/explore.store';

import follow from './modules/follow';
import createMusicRelease from './modules/createMusicRelease.store';

// dashboard
import manageAccounts from './modules/manage/accounts.store';
import manageUploadsReleases from './modules/manage/uploadsReleases.store';
import manageUploadsEvents from './modules/manage/uploadsEvents.store';
import manageFollows from './modules/manage/follows.store';

import subscriptions from './modules/subscriptions.store';
import stripeAccount from "@/store/modules/stripeAccount.store";

import basket from '@/store/modules/basket.store';
import purchases from "@/store/modules/purchases.store";

import notifications from "@/store/modules/notifications.store";

import recordLabel from "@/store/modules/recordLabel.store";

import centrifuge from "@/store/modules/centrifuge.store"

import metaData from "@/store/modules/metaData";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    constantData,
    globalSettings,
    actions,

    main,
    auth,
    clear,

    // player,
    hlsPlayer,

    // explore
    nav,
    exploreGetAlbums,
    exploreFilters,

    // library
    collection,

    // profiles
    privateProfile,
    //userProfile,
    userProfileGlobal,
    artistProfile,

    //users,
    currentUser,
    mediaObjects,
    albums,
    musicRelease,
    manageUploaded,
    favoriteMusicRelease,
    track,
    playLists,

    artist,
    artistVerificationRequest,
    artistVerificationRequestComment,
    adminNotifications,
    playlist,
    faqs,
    route,
    comments,
    search,
    events,
    tickets,
    supporters,
    follow,
    suggestions,

    // mobile
    mobileExplore,

    createMusicRelease,

    // dashboard
    manageAccounts,
    manageUploadsReleases,
    manageUploadsEvents,
    manageFollows,

    subscriptions,
    stripeAccount,

    basket,
    purchases,
    notifications,

    recordLabel,

    centrifuge,
    metaData
  }
});
