export default [
    {
        path: '/following',
        name: 'following',
        component: () => import('@/pages/follows/following'),
        props: true,
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        },
        redirect: {name: 'followingAll'},
        children: [
            {
                path: 'all',
                name: 'followingAll',
                props: true,
                component: () => import('@/pages/follows/following/FollowingAll'),
                meta: {requiresAuth: true},
            },
            {
                path: 'users',
                name: 'followingUsers',
                props: true,
                component: () => import('@/pages/follows/following/FollowingUsers'),
                meta: {requiresAuth: true},
            },
            {
                path: 'artists',
                name: 'followingArtists',
                props: true,
                component: () => import('@/pages/follows/following/FollowingArtists'),
                meta: {requiresAuth: true},
            },
            {
                path: 'labels',
                name: 'followingLabels',
                props: true,
                component: () => import('@/pages/follows/following/FollowingLabels'),
                meta: {requiresAuth: true},
            },
        ],
    },
    {
        path: '/download-purchased',
        name: 'downloadPurchased',
        component: () => import('@/pages/dashboard/downloadPurchased.vue'),
        meta: {requiresAuth: true, requiresVerify: true},
    },
];
