import axios from 'axios';

const initialState = () => ({
  faqs: [],
});

export default {
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    SET_FAQS: (state, faqs) => {
      state.faqs = faqs;
    },
  },
  actions: {
    GET_FAQS({commit}, params) {
      return axios.get(`faqs`, {params: params})
        .then(faqs => {
          commit('SET_FAQS', faqs.data['hydra:member'])
        })
        .catch(err => console.log(`GET_FAQS ${err}`));
    },
    ADD_FAQ({commit}, newFaq) {
      return axios.post(`faqs`, newFaq)
        .then((response) => {
          console.log('ADD_FAQ response', response);
        })
        .catch(err => console.log(`ADD_FAQ ${err}`));
    },
    UPDATE_FAQ({commit}, params) {
      const { id, ...updateData } = params;
      return axios.patch(`faqs/${id}`, updateData, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      })
      .then(response => {
        console.log('UPDATE_FAQ response', response);
      })
      .catch(err => {
        console.log(`UPDATE_FAQ ${err}`);
        throw err; // Re-throw the error for further handling if needed
      });
    },
    DELETE_FAQ({commit}, id) {
      console.log('params', id);
      return axios.delete(`faqs/${id}`)
        .catch(err => console.log(`DELETE_FAQ ${err}`));
    }
  },
  getters: {
    FAQS: state => state.faqs,
  }
}