import MediaT from "@/models/media";
import Subscription from "@/models/subscription";

export default class PaginationM<T> {
  page: number
  total: number
  pages: number
  items: T[]
  paginate: boolean
  next: null | number
  prev: null | number
  initialized: boolean

  constructor (data, itemConstructor: new (data: any) => T) {
    if (data) {
      this.page = data.current_page;
      this.total = data.count_results;
      this.pages = data.number_of_pages;
      this.paginate = data.paginate;
      this.next = data.next;
      this.prev = data.prev;

      this.items = data.items.map(item => new itemConstructor(item));

      this.initialized = true;
    }
  }

  hasPageItems(): boolean {
    return !!this.items?.length;
  }
}

