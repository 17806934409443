<template>
	<div class="identity-verification">
		<v-container>
			<div class="page_title">
				<span>Identity Verification</span>
			</div>
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				v-on:submit.prevent>

				<div class="wrap_steps">
					<div :class="stepsCompleted[1] ? 'step one completed' : 'step one'">
						<div class="step_title">
							<span class="step-number">1</span>
							<div class="step_name">Upload a photo of your Drivers license or Passport.</div>
						</div>
						<div class="step_body">
							<upload-image @selected_photo="photoDocSelected">
								10 MB limit | JPG, PNG
							</upload-image>
						</div>
					</div>
					<div :class="stepsCompleted[2] ? 'step two completed' : 'step two'">
						<div class="step_title">
							<span class="step-number">2</span>
							<div class="step_name">Upload a photo of yourself, holding your ID and the artist name of a profile which you’d like to claim.</div>
						</div>
						<div class="step_body">
							<div class="example_photo">
								<preview-image :src="require('@/assets/img/upload-verification/sample.jpg')"></preview-image>
							</div>
							<upload-image @selected_photo="photoSelfSelected">
								10 MB limit | JPG, PNG
							</upload-image>
						</div>
					</div>
					<div :class="stepsCompleted[3] ? 'step three completed' : 'step three'">
						<div class="step_title">
							<span class="step-number">3</span>
							<div class="step_name">Link(s) to a webpage showing a connection between your real name and your artist name.</div>
						</div>
						<div class="step_body">
							<v-textarea
								v-model="links"
								rows="5"
								:outlined="true"
                                :maxlength="500"
								class="links-text-area"
							></v-textarea>
						</div>
					</div>

					<div :class="stepsCompleted[4] ? 'step four completed' : 'step four'">
						<div class="step_title">
							<span class="step-number">4</span>
							<div class="step_name">Contact information & comments</div>
						</div>
						<div class="step_body">
							<div class="wrap_fields">
								<label>Email</label>
								<v-text-field v-if="PROFILE && PROFILE.email" v-model="PROFILE.email" :outlined="true" readonly :class="'readonly'"></v-text-field>
							</div>
						</div>
						<div class="step_body">
							<div class="wrap_fields">
								<label>Comments (Optional)</label>
								<v-textarea
									v-model="comments"
									rows="5"
									:maxlength="2000"
									:outlined="true"
									class="comments-text-area"
								></v-textarea>
							</div>
						</div>

					</div>


				</div>

				<v-btn
					:disabled="!allStepsCompleted || !valid"
					class="btn_submit"
					x-large
					:loading="loading"
					@click="submitRequest">
					Submit
				</v-btn>
			</v-form>

			<v-dialog
					v-if="errorDialog"
				v-model="errorDialog"
				max-width="600">
				<div class="need_login_dialog style_dialog">
					<h2 class="header">
						Oops!
					</h2>
					<div class="cnt">
						Something went wrong, please contact support
					</div>
					<div class="wrap_actions">
						<button type="button" class="cancel" @click="errorDialog = false" v-ripple>Cancel</button>
						<a v-if="FACEBOOK" :href="FACEBOOK" target="_blank" class="facebook">Contact</a>
					</div>
				</div>
			</v-dialog>

		</v-container>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import uploadImage from '@/components/manage/uploadImage'
import previewImage from '@/components/manage/previewImage'

export default {
	name: 'identityVerification',
	props: ['artistId'],
	components: {
		uploadImage,
		previewImage
	},
	data() {
		return {
			valid: true,
			loading: false,
			uploadedDocPhoto: null,
			uploadedSelfPhoto: null,
			stepOneCompleted: true,
			email: '',
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			comments: '',
			links: '',
			errorDialog: false,
		}
	},
	computed: {
		...mapGetters(['PROFILE', 'FILE_URL', 'ERROR_ARTIST_VERIFICATION_REQUEST', 'FACEBOOK']),
		stepsCompleted() {
			let steps = {
				1: false,
				2: false,
				3: false,
				4: false,
			};

			if (this.uploadedDocPhoto) {
				steps[1] = true;
			}

			if (this.uploadedSelfPhoto) {
				steps[2] = true;
			}

			if (this.links) {
				steps[3] = true;
			}
			if (this.comments) {
				steps[4] = true;
			}

			return steps;
		},
		allStepsCompleted() {
			return this.uploadedDocPhoto && this.uploadedSelfPhoto && this.links;
		}
	},
	created() {
        this.GET_PROFILE()
            .catch(err => {console.error('security, GET_PROFILE', err)});
    },
    methods: {
		...mapActions(['GET_PROFILE', 'ADD_ARTIST_VERIFICATION_REQUEST', 'ADD_FILE']),
		photoDocSelected(file) {
            this.uploadedDocPhoto = file;
        },
		photoSelfSelected(file) {
            this.uploadedSelfPhoto = file;
        },
		async submitRequest() {
			if (this.$refs.form.validate() && this.uploadedDocPhoto && this.uploadedSelfPhoto) {
				this.loading = true;
				let data = {
					artist: `artists/${this.artistId}`,
					email: this.PROFILE.email,
					comments: this.comments,
					links: this.links,
				};
				let formData = new FormData();
				// upload id photo
				formData.append('file', this.uploadedDocPhoto);
				await this.ADD_FILE(formData);
				data['idCardPhoto'] = this.FILE_URL;
				// upload self photo
				formData.set('file', this.uploadedSelfPhoto);
				await this.ADD_FILE(formData);
				data['selfPhoto'] = this.FILE_URL;

				// send all data
				await this.ADD_ARTIST_VERIFICATION_REQUEST(data)
					.then(() => {
						if (!this.ERROR_ARTIST_VERIFICATION_REQUEST) {
							this.$router.push({name: 'verification-request-submitted'});
						} else {
							this.errorDialog = true;
						}
					})
					.catch(err => {
						this.errorDialog = true;
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					})
			} else {
				this.valid = false;
			}
		}
	},
}
</script>

<style lang="scss">
.identity-verification {
	background: #031020;

	.page_title {
		font-family: 'Montserrat', sans-serif;
            font-size: min(55px, 8vw);
            font-weight: 700;
            color: rgba(95, 105, 134, 0.67);
            line-height: 67px;
            margin: 0 15px 20px 15px;
            text-transform: capitalize;
            letter-spacing: 0.03em;
            white-space: nowrap;
            width: calc(100% - 30px);
            overflow: hidden;
            text-align: left;
            text-rendering: optimizeLegibility;
            text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            
            @media (max-width: 480px) {
                font-size: min(40px, 8vw);  // Keep using same scale
                text-align: center;
                margin-bottom: 10px;
            }
	}

	.wrap_steps {
		padding: 16px 30px 16px 30px;
		background-color: #10182B;
		border-radius: 5px;

		.step {
			padding-bottom: 55px;
			padding-left: 32px;
			position: relative;
			border-left: 3px solid #f1f1f1;

			.step_title {
				font-size: 16px;
				color: #f1f1f1;
				margin-bottom: 30px;

				.step-number {
					position: absolute;
					left: -17px;
					width: 32px;
					height: 32px;
					line-height: 200%;
					background: #f1f1f1;
					border-radius: 50%;
					font-weight: 900;
					font-size: 16px;
					color: #282C47;
					text-align: center;
				}
				.step_name {
					padding-top: 5px;
				}
			}
			&.three {
				.step_title {
					margin-bottom: 20px;
				}
			}
			&.four {
				border-left-color: transparent;
				padding-bottom: 0;
				.step_title {
					margin-bottom: 20px;
				}
				.step_body {
					margin-bottom: 10px;
				}
			}



			&.completed {
				border-left-color: #20C4F5;

				&.four {
					border-left-color: transparent;
				}

				.step_title .step-number {
					background-color: #20C4F5;
					color: white;
				}
			}

			.step_body {
				display: flex;

				.example_photo {
					margin-right: 32px;
					border-radius: 10px;
					overflow: hidden;
					.preview-image .image-thumbnail {
						border: none;
					}
					.image-thumbnail {
						//border: 1px solid transparent;
					}
				}
				.example_photo,
				.upload-img {
					.select-img {
						border-radius: 10px;
						overflow: hidden;
					}
					.description {
						padding-left: 20px;
					}
				}

				.wrap_fields {
					width: 100%;
                    > .readonly {
						&:hover {
							fieldset {
                                border: 1px solid #282C47;
							}
                        }
	                    input {
		                    color: rgba(#fff, .5);
	                    }
					}
					> label {
						display: flex;
						font-size: 16px;
						line-height: 20px;
						color: #7481A6;
						margin-bottom: 6px;
					}
				}
			}
		}
	}

	button.v-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 326px;
		height: 64px !important;
		background: #5F6986;
		border-radius: 68px;
		color: white;
		margin: 32px 64px;
		padding: 22px !important;
		text-transform: none !important;
        margin-bottom: 100px;

		.v-btn__content {
			font-family: "Inter", sans-serif;
			font-style: normal;
			font-size: 18px;
			font-weight: 500;
		}

		&.v-btn--loading {
			.v-btn__loader {
				.v-progress-circular {
					display: flex;
				}
			}
		}
	}

	.theme--dark.v-btn.v-btn--has-bg {
		background-color: #5F6986;
	}

	.v-input {
		transition: 0.21s;
		.v-input__slot {
            padding: 0 16px !important;
			textarea {
                padding-left: 0;
			}
		}
		fieldset {
			width: 100%;
			border: 1px solid #282C47;
			background-color: #040A18;
			border-radius: 10px;
			transition: 0.1s;
		}

		&:focus,
		&:hover {
			fieldset {
				border-color: #5F6986;
			}
		}

		&.error--text {
			fieldset {
				border: 1px solid #ff5252;
			}
		}

		input,
		textarea {
			font-size: 16px;
			color: white;
			caret-color: white;
		}

		textarea {
			outline: none;
			height: 104px;
			padding: 6px 16px 4px 4px;
			line-height: 20px;
		}
	}
}
</style>
