import MediaM from "@/models/mediaM";
import SubscriptionM from "@/models/subscriptionM";

export default class AccountM {
  id: number
  name: string
  identifier: string
  ownerId: number
  type: string
  followersCount: number
  avatar: null | MediaM
  subscription: null | SubscriptionM

  _isBusy: boolean = false;

  constructor (data) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.identifier = data.identifier;
      this.ownerId = data.owner_id;
      this.type = data.type;
      this.followersCount = data.followersCount;

      this.avatar = data.avatar ? new MediaM(data.avatar) : null;
      this.subscription = data.subscription ? new SubscriptionM(data.subscription) : null;
    }
  }

  hasActiveSubscription(): boolean {
    return this.subscription && this.subscription.isActive();
  }

  hasCanceledSubscription(): boolean {
    return this.subscription && this.subscription.isCanceled();
  }

  isArtist(): boolean {
    return this.type.toLocaleLowerCase() === 'artist';
  }

  isLabel(): boolean {
    return this.type.toLocaleLowerCase() === 'label';
  }

  isBusy(): boolean {
    return this._isBusy;
  }

  changeBusy(busy: boolean): void {
    this._isBusy = busy;
  }
}

