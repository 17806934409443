<template>
    <div class="head_albums">

        <div class="select_view_albums" v-if="selectViewAlbums === true">
            <button type="button" class="view_list" @click="changeVisibleAlbums('list')" :class="{'active': stateViewAlbums === 'list'}"></button>
            <button type="button" class="view_grid" @click="changeVisibleAlbums('grid')" :class="{'active': stateViewAlbums === 'grid'}"></button>
        </div>

        <div class="cover_img_"></div>
        <div class="like_album_" :class="{'hidden_like': hideLike}"></div>

        <div class="title_">Title
            <div class="sort">
                <span class="material-icons"
                      :class="{active: currentOrderBy.sort === 'title' && currentOrderBy.order === 'asc'}"
                      @click="orderBy('title', 'asc')">
                    arrow_drop_up
                </span>
                <span class="material-icons"
                      :class="{active: currentOrderBy.sort === 'title' && currentOrderBy.order === 'desc'}"
                      @click="orderBy('title', 'desc')">
                    arrow_drop_down
                </span>
            </div>
        </div>
        <div class="artist_">Artist
            <div class="sort">
                <span class="material-icons"
                      :class="{active: currentOrderBy.sort === 'artist.name' && currentOrderBy.order === 'asc'}"
                      @click="orderBy('artist.name', 'asc')">
                    arrow_drop_up
                </span>
                <span class="material-icons"
                      :class="{active: currentOrderBy.sort === 'artist.name' && currentOrderBy.order === 'desc'}"
                      @click="orderBy('artist.name', 'desc')">
                    arrow_drop_down
                </span>
            </div>
        </div>
        <div class="released_" v-if="SHOW_COLUMNS.includes('released')">Released
            <div class="sort">
                <span class="material-icons"
                      :class="{active: currentOrderBy.sort === 'releasedAt' && currentOrderBy.order === 'asc'}"
                      @click="orderBy('releasedAt', 'asc')">
                    arrow_drop_up
                </span>
                <span class="material-icons"
                      :class="{active: currentOrderBy.sort === 'releasedAt' && currentOrderBy.order === 'desc'}"
                      @click="orderBy('releasedAt', 'desc')">
                    arrow_drop_down
                </span>
            </div>
        </div>
        <div class="status_" v-if="showStatus === true">
            Status
        </div>
        <div class="actions_" style="display: none;">
            <v-menu
                transition="slide-y-transition"
                :close-on-content-click="false"
                :nudge-width="120"
                content-class="menu-pointer charcoal"
                offset-y
                nudge-left="60"
                nudge-bottom="15"
                origin="top center"
            >
                <template v-slot:activator="{ on, attrs }">
                    <button v-bind="attrs" v-on="on">
                        <span class="material-icons">arrow_drop_down</span>
                    </button>
                </template>
                <v-list>
                    <v-list-item>
                        <div class="material_checkbox">
                            <input type="checkbox" id="label" value="label" v-model="columns" @change="checkColumn($event)">
                            <label for="label">Label</label>
                        </div>
                    </v-list-item>
                    <v-list-item>
                        <div class="material_checkbox">
                            <input type="checkbox" id="released" value="released" v-model="columns" @change="checkColumn($event)">
                            <label for="released">Released</label>
                        </div>
                    </v-list-item>

                    <v-list-item>
                        <div class="material_checkbox">
                            <input type="checkbox" id="viewes" value="viewes" v-model="columns" @change="checkColumn($event)">
                            <label for="viewes">Viewes</label>
                        </div>
                    </v-list-item>
                    <v-list-item>
                        <div class="material_checkbox">
                            <input type="checkbox" id="downloads" value="downloads" v-model="columns" @change="checkColumn($event)">
                            <label for="downloads">Downloads</label>
                        </div>
                    </v-list-item>
                    <v-list-item>
                        <div class="material_checkbox">
                            <input type="checkbox" id="collected" value="collected" v-model="columns" @change="checkColumn($event)">
                            <label for="collected">Collected</label>
                        </div>
                    </v-list-item>

                </v-list>
            </v-menu>
        </div>

        <div class="sortable" v-if="UPPER_FILTER === 'pending' && this.$route.name === 'explore'">
            <span>Status</span>

            <v-menu
                :close-on-content-click="true"
                offset-y
                nudge-left="0"
                nudge-bottom="-3"
                origin="top center"
                content-class="yankees">
                <template v-slot:activator="{ on, attrs }">
                    <button type="button" class="sort_by_btn" v-bind="attrs" v-on="on">
                        <template v-if="ALBUM_STATUS === 'pending'">Pending</template>
                        <template v-if="ALBUM_STATUS === 'need_review'">Need Review</template>
                        <span></span>
                    </button>
                </template>
                <v-list class="filter_status">
                    <v-list-item v-ripple>
                        <button type="button" @click="filterAlbumByStatus('pending')">Pending</button>
                    </v-list-item>
                    <v-list-item v-ripple>
                        <button type="button" @click="filterAlbumByStatus('need_review')">Need Review</button>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "headListAlbums",
    props: {
        selectViewAlbums: Boolean,
        which: String,
        showStatus: Boolean,
        hideLike: Boolean,
        stateViewAlbums: String,
        updateViewAlbums: String,
        stateOrderBy: String,
        updateOrderBy: String
    },

    data() {
        return {
            currentOrderBy: {},
            columns: this.$store.getters.SHOW_COLUMNS,
            activeViewAlbum: 'grid',
        };
    },
    computed: {
        ...mapGetters([
	        'SHOW_COLUMNS',

	        'ORDER_BY',
	        'SEARCH_ALBUMS_ORDER_BY',

            'VIEW_ALBUMS',
            'SEARCH_VIEW_ALBUMS',

            'UPPER_FILTER',
            'ALBUM_STATUS'
        ]),
        ...mapGetters({
            libraryViewAlbums: 'collection/VIEW_ALBUMS',
	        libraryOrderBy: 'collection/ALBUMS_ORDER_BY',
        })
    },
    mounted() {
        if (this.currentOrderBy.sort !== undefined && this.currentOrderBy.order !== undefined ) {
            this.currentOrderBy = {
                sort: this.stateOrderBy.sort,
                order: this.stateOrderBy.order
            };
        } else {
            this.currentOrderBy = {
                sort: 'releasedAt',
	            order: 'desc'
            };
        }
    },
    methods: {
        orderBy: function (key, direction) {
            this.currentOrderBy = {
                sort: key,
                order: direction
            }
			console.log('this.currentOrderBy', this.currentOrderBy);

            if (key && direction) {
                this.$store.dispatch(this.updateOrderBy, {
                    sort: key, order: direction
                });
            }
        },
        checkColumn() {
            this.$store.commit('SET_SHOW_COLUMNS', this.columns);
        },
        changeVisibleAlbums(value) {
            console.log('changeVisibleAlbums', value, this.updateViewAlbums);
            this.$store.commit(this.updateViewAlbums, value);
        },
        filterAlbumByStatus(status) {
            this.$store.commit('SET_ALBUM_STATUS', status);
        }
    }
}
</script>

<style scoped>
.select_view_albums {
    position: absolute;
    display: flex;
    height: 100%;
    padding-bottom: 4px;
}
</style>
