import axios from 'axios';

const initialState = () => ({
    // explore albums (grid, list)
    exploreGridAlbums: [],
    countExploreGridAlbums: '',
    activeReq: null
});

export default {
    state: {
        ...initialState(),
        exploreAlbumsLoading: false,
    },
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },

        SET_EXPLORE_GRID_ALBUMS: (state, data) => {
            state.countExploreGridAlbums = data.count_results;
            state.exploreGridAlbums = data.items;
        },
        SET_EXPLORE_ALBUMS_LOADING: (state, data) => {
            state.exploreAlbumsLoading = data;
        }
    },
    actions: {
        CLEAN_EXPLORE_GRID_ALBUMS({commit}) {
            let empty = {
                'count_results': '',
                'items': {}
            }
            commit('SET_EXPLORE_GRID_ALBUMS', empty);
        },
        GET_EXPLORE_GRID_ALBUMS({commit, state}, params) {
            commit('SET_EXPLORE_ALBUMS_LOADING', true);
            console.log('SET_EXPLORE_ALBUMS_LOADING', true);


            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            let source = JSON.parse(localStorage.getItem('DataSource'));
            let elasticPath = `search/`;
            if (!source || !source.db || source.db === 'api') {
                elasticPath = ``;
            }

            // console.log()
            return axios.get(`${elasticPath}optimized/music_releases`, {signal, params: params})
                .then(response => {
                    commit('SET_EXPLORE_ALBUMS_LOADING', false);
                    if (!response.data && source.auto === true) {
                        // get data from api
                        return axios.get(`optimized/music_releases`, {params: params})
                            .then(response => {
                                commit('SET_EXPLORE_ALBUMS_LOADING', false);
                                commit('SET_EXPLORE_GRID_ALBUMS', response.data);
                            })
                            .catch(err => {
                                commit('SET_EXPLORE_ALBUMS_LOADING', false);
                                console.log(`GET_EXPLORE_GRID_ALBUMS ${err}`);
                            })
                    } else {
                        commit('SET_EXPLORE_GRID_ALBUMS', response.data);
                    }
                })
                .catch(err => {
                    // commit('SET_EXPLORE_ALBUMS_LOADING', false);
                    console.log(`GET_EXPLORE_GRID_ALBUMS ${err}`);
                })
        },
    },


    getters: {
        EXPLORE_GRID_ALBUMS: state => state.exploreGridAlbums,
        COUNT_EXPLORE_GRID_ALBUMS: state => state.countExploreGridAlbums,
        EXPLORE_ALBUMS_LOADING: state => state.exploreAlbumsLoading,
    },
}
