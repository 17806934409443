import axios from 'axios';

const initialState = () => ({
    activeReq: null,

    artist: [],
    artists: [],
    countArtists: '',
    artistsCurrentPage: 1,
    artistAlbums: [],
    userArtists: [],

    yourArtists: [],
    yourArtistsLoader: false,
    countYourArtists: '',

    changeArtistDataStatus: '',
    artistOwner: '',
    createArtistError: '',
    createdArtistID: '',
    selectedArtistID: '',
    changeSelectedArtist: false,
    uniqueArtistName: true,

    // subscription
    subscriptionClientSecret: '',
    uncancelArtistSubscriptionStatus: false,
    createArtistSubscriptionLoader: false,
    renewArtistSubscriptionLoader: false,
    hasFollowedArtist: false,
    commissionRates: '',
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_CHECK_UNIQUE_ARTIST_NAME: (state, data) => {
            state.uniqueArtistName = data;
        },
        SET_CREATE_ARTIST_ERROR: (state, err) => {
            state.createArtistError = err;
        },
        // SET_SELECTED_ARTIST_ID: (state, id) => {
        //     state.selectedArtistID = id;
        // },
        SET_CHANGE_SELECTED_ARTIST: (state, data) => {
            state.changeSelectedArtist = data;
        },

        SET_ARTIST: (state, data) => {
            state.artist = data;
        },
        SET_ARTISTS: (state, data) => {
            state.artists = data['hydra:member'];
            state.countArtists = data['hydra:totalItems'];
        },
        SET_ARTISTS_CURRENT_PAGE: (state, data) => {
            state.artistsCurrentPage = data;
        },
        SET_ARTIST_ALBUMS: (state, data) => {
            state.artistAlbums = data;
        },
        SET_USER_ARTISTS: (state, data) => {
            state.userArtists = data;
        },

        SET_YOUR_ARTISTS: (state, data) => {
            state.yourArtists = data['hydra:member'];
            state.countYourArtists = data['hydra:totalItems'];
        },
        SET_YOUR_ARTISTS_LOADER: (state, data) => {
            state.yourArtistsLoader = data;
        },


        SET_CHANGE_ARTIST_DATA: (state, data) => {
            state.changeArtistDataStatus = data;
        },
        SET_ARTIST_OWNER: (state, data) => {
            state.artistOwner = data;
        },

        // subscription
        SET_SUBSCRIPTION_CLIENT_SECRET: (state, data) => {
            state.subscriptionClientSecret = data;
        },
        SET_UNCANCEL_ARTIST_SUBSCRIPTION_STATUS: (state, data) => {
            state.uncancelArtistSubscriptionStatus = data;
        },

        SET_CREATE_ARTIST_SUBSCRIPTION_LOADER: (state, data) => {
            state.createArtistSubscriptionLoader = data;
        },
        SET_RENEW_ARTIST_SUBSCRIPTION_LOADER: (state, data) => {
            state.renewArtistSubscriptionLoader = data;
        },
        SET_HAS_FOLLOWED_ARTIST: (state, data) => {
            state.hasFollowedArtist = data;
        },
        SET_COMMISSION_RATES: (state, data) => {
            state.commissionRates = data;
        }
    },
    actions: {
        CLEAN_ARTISTS({commit}) {
            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_ARTISTS', empty);
        },
        CHECK_UNIQUE_ARTIST_NAME({commit, state}, data) {
            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.post(`artists/check-unique-name`, data, {signal}, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    if (response.data.result === true) {
                        commit('SET_CHECK_UNIQUE_ARTIST_NAME', true);
                    } else {
                        commit('SET_CHECK_UNIQUE_ARTIST_NAME', false);
                    }
                })
                .catch(err => {
                    console.log('err.response', err.response);
                    if (err.response.data.error === 'You already have artist with this name') {
                        commit('SET_CHECK_UNIQUE_ARTIST_NAME', false);
                    }
                    console.error(`CHECK_UNIQUE_ARTIST_NAME ${err}`)
                });
        },
        CREATE_ARTIST({commit}, data) {
            return axios.post(`artists`, data, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    commit('SET_SUBSCRIPTION_CLIENT_SECRET', response.data.result.clientSecret);
                })
                .catch(err => {
                    commit('SET_CREATE_ARTIST_ERROR', true);
                    console.log(`CREATE_ARTIST ${err}`);
                })
        },
        DELETE_ARTIST({commit}, id) {
            let artistIndex = this.getters['YOUR_ARTISTS'].findIndex((artist) => artist.id === id);
            if (artistIndex !== -1) {
                this.getters['YOUR_ARTISTS'].splice(artistIndex, 1);
            }

            return axios.delete(`artists/${id}`)
                .then(() => {

                })
                .catch(error => console.log(`DELETE_ARTIST ${error}`));
        },
        // GET_ARTIST({commit}, id) {
        //     commit('SET_ARTIST', []);
        //     return axios.get(`artists/${id}/base-info`)
        //         .then(artist => {
        //             commit('SET_ARTIST', artist.data);
        //         })
        //         .catch(error => console.log(`GET_ARTIST ${error}`));
        // },
        GET_ARTIST({commit}, id) {
            commit('SET_ARTIST', []);
            return axios.get(`artists/${id}/base-info`)
                .then(artist => {
                    commit('SET_ARTIST', artist.data);
                })
                .catch(error => console.log(`GET_ARTIST ${error}`));
        },
        GET_ARTISTS({commit}, params) {
            return axios.get(`artists`, {params: params})
                .then(response => {
                    commit('SET_ARTISTS', response.data);
                })
                .catch(err => {
                    console.log(`GET_ARTISTS ${err}`);
                })
        },
        CHANGE_ARTIST_DATA({commit}, artist) {
            commit('SET_CHANGE_ARTIST_DATA', '');
            return axios.patch(`artists/${artist.id}`, artist, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then(() => {
                    commit('SET_CHANGE_ARTIST_DATA', 'success');
                })
                .catch(err => {
                    commit('SET_CHANGE_ARTIST_DATA', 'error');
                    console.log(`CHANGE_ARTIST_DATA ${err}`);
                })
        },
        GET_ARTIST_ALBUMS({commit}, params) {
            commit('SET_ARTIST_ALBUMS', []);
            return axios.get(`optimized/music_releases`, {params: params})
                .then(artistAlbums => {
                    commit('SET_ARTIST_ALBUMS', artistAlbums.data.items);
                })
                .catch(error => console.log(`GET_ARTIST_ALBUMS ${error}`));
        },
        GET_ARTIST_OWNER({commit}, userID) {
            commit('SET_ARTIST_OWNER', '');
            return axios.get(`users/${userID}`)
                .then(user => {
                    commit('SET_ARTIST_OWNER', user.data)
                })
                .catch(err => console.log(`GET_USER ${err}`));
        },
        GET_HAS_FOLLOWED_ARTIST({commit}, artistID) {
            commit('SET_HAS_FOLLOWED_ARTIST', false);
            return axios.get(`/artists/${artistID}/has-follow`)
                .then(response => {
                    commit('SET_HAS_FOLLOWED_ARTIST', response.data.result);
                })
                .catch(err => console.log(`HAS_FOLLOWED_ARTIST ${err}`));
        },


        ///
        GET_USER_ARTISTS({commit}, params) {
            commit('SET_USER_ARTISTS', []);
            return axios.get(`artists`, {params: params})
                .then(artists => {
                    commit('SET_USER_ARTISTS', artists.data['hydra:member']);
                })
                .catch(err => console.log(`GET_USER_ARTISTS, ${err}`))
        },
        GET_YOUR_ARTISTS({commit, state}, params) {
            commit('SET_YOUR_ARTISTS_LOADER', true);
            // commit('SET_YOUR_ARTISTS', []);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get(`artists`, {signal, params: params})
                .then(response => {
                    console.log('response.data', response.data);
                    if (response && response.data) {
                        commit('SET_YOUR_ARTISTS', response.data);
                    }
                    // commit('SET_YOUR_ARTISTS_LOADER', false);
                })
                .catch(err => console.log(`GET_YOUR_ARTISTS, ${err.response.data.error}`))
                .finally(() => {
                    if (!signal.aborted) { // Перевіряємо, чи запит не був скасований
                        commit('SET_YOUR_ARTISTS_LOADER', false);
                    }
                })
        },

        // artist subscriptions
        CREATE_ARTIST_SUBSCRIPTION({commit}, artistID) {
            commit('SET_CREATE_ARTIST_SUBSCRIPTION_LOADER', true);
            return axios.post(`artists/${artistID}/subscriptions`)
                .then((response) => {
                    commit('SET_SUBSCRIPTION_CLIENT_SECRET', response.data.result.clientSecret);
                })
                .catch(err => console.log(`CREATE_ARTIST_SUBSCRIPTION ${err}`))
                .finally(() => {
                    commit('SET_CREATE_ARTIST_SUBSCRIPTION_LOADER', false);
                })
        },
        UNCANCEL_ARTIST_SUBSCRIPTION({commit}, data) {
            return axios.patch(`artists/${data.artistID}/subscriptions/${data.subscriptionID}`)
                .then((response) => {
                    commit('SET_UNCANCEL_ARTIST_SUBSCRIPTION_STATUS', response.data.result);
                    return response.data.result;
                })
                .catch(err => console.log(`UNCANCEL_ARTIST_SUBSCRIPTION ${err}`));
        },
        RENEW_ARTIST_SUBSCRIPTION({commit}, data) {
            commit('SET_RENEW_ARTIST_SUBSCRIPTION_LOADER', true);
            return axios.patch(`artists/${data.artistID}/subscriptions/${data.subscriptionID}`)
                .then((response) => {
                    commit('SET_SUBSCRIPTION_CLIENT_SECRET', response.data.result.clientSecret);
                })
                .catch(err => console.log(`RENEW_ARTIST_SUBSCRIPTION ${err}`))
                .finally(() => {
                    commit('SET_RENEW_ARTIST_SUBSCRIPTION_LOADER', false);
                })
        },
        REMOVE_ARTIST_SUBSCRIPTION({commit}, data) {
            return axios.delete(`artists/${data.artistID}/subscriptions/${data.subscriptionID}`)
                .then((response) => {
                    return response.data.result;
                    // commit('SET_SUBSCRIPTION_CLIENT_SECRET', '');
                })
                .catch(err => console.log(`REMOVE_ARTIST_SUBSCRIPTION ${err}`));
        },
        GET_COMMISSION_RATES({commit}, data) {
            return axios.get(`meta-data/commission-rates`)
                .then((response) => {
                    commit('SET_COMMISSION_RATES', response.data);
                    //---> artist_subscription_month:4.99
                    //---> artist_subscription_year:49
                    // donation_fee:6
                    // ---> founder_fee:300
                    //---> record_label_subscription_month:19.99
                    //---> record_label_subscription_year:190
                    // sale_fee:13.5
                    // ---> user_subscription_month:4.99
                    // ---> user_subscription_year:49
                })
                .catch(err => console.log(`GET_COMMISSION_RATES ${err}`));
        }
    },
    getters: {
        UNIQUE_ARTIST_NAME: state => state.uniqueArtistName,
        CREATE_ARTIST_ERROR: state => state.createArtistError,
        // SELECTED_ARTIST_ID: state => state.selectedArtistID,
        CHANGE_SELECTED_ARTIST: state => state.changeSelectedArtist,

        ARTIST: state => state.artist,
        ARTISTS: state => state.artists,
        COUNT_ARTISTS: state => state.countArtists,
        ARTISTS_CURRENT_PAGE: state => state.artistsCurrentPage,
        ARTIST_ALBUMS: state => state.artistAlbums,
        USER_ARTISTS: state => state.userArtists,
        CHANGE_ARTIST_DATA_STATUS: state => state.changeArtistDataStatus,
        ARTIST_OWNER: state => state.artistOwner,

        // your artists
        YOUR_ARTISTS: state => state.yourArtists,
        COUNT_YOUR_ARTISTS: state => state.countYourArtists,
        YOUR_ARTISTS_LOADER: state => state.yourArtistsLoader,

        // subscription
        SUBSCRIPTION_CLIENT_SECRET: state => state.subscriptionClientSecret,
        UNCANCEL_ARTIST_SUBSCRIPTION_STATUS: state => state.uncancelArtistSubscriptionStatus,
        CREATE_ARTIST_SUBSCRIPTION_LOADER: state => state.createArtistSubscriptionLoader,
        RENEW_ARTIST_SUBSCRIPTION_LOADER: state => state.renewArtistSubscriptionLoader,

        HAS_FOLLOWED_ARTIST: state => state.hasFollowedArtist,
        COMMISSION_RATES: state => state.commissionRates,
    }
}
