const initialState = () => ({
    refreshReleases: false,
    afterActionDialogNeedLogin: false,
});

export default {
    state: initialState(),
    mutations: {
        SET_REFRESH_RELEASES: (state, data) => {
            state.refreshReleases = data;
        },
        SET_AFTER_ACTION_DIALOG_NEED_LOGIN: (state, data) => {
            state.afterActionDialogNeedLogin = data;
        }
    },
    actions: {},
    getters: {
        REFRESH_RELEASES: state => state.refreshReleases,
        AFTER_ACTION_DIALOG_NEED_LOGIN: state => state.afterActionDialogNeedLogin,
    }
}