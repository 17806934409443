import axios from 'axios';

const initialState = () => ({
    followsLoader: false,
    followsFilter: false,

    followers: '',
    countFollowers: null,
    currentPageFollowers: 1,

    userFollowing: '',
    needRefreshUserFollowing: false,
    countUserFollowing: null,
    currentPageUserFollowing: 1,

    artistFollowing: '',
    needRefreshArtistFollowing: false,
    countArtistFollowing: null,
    currentPageArtistFollowing: 1,

    customFollows: '',
    hasFollowedUser: false,
});

export default {
    state: initialState(),

    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_CUSTOM_FOLLOWS: (state, data) => {
            state.customFollows = data;
        },
        SET_FOLLOW_LOADER: (state, data) => {
          state.followsLoader = data;
        },
        SET_FOLLOWS_FILTER: (state, data) => {
          state.followsFilter = data;
        },

        // followers
        SET_FOLLOWERS: (state, followers) => {
            state.followers = followers['hydra:member'];
            state.countFollowers = followers['hydra:totalItems'];
        },
        SET_CURRENT_PAGE_FOLLOWERS: (state, page) => {
            state.currentPageFollowers = page;
        },



        // user Followings
        SET_USER_FOLLOWING: (state, following) => {
            state.userFollowing = following.items;
            state.countUserFollowing = following.count_results;
        },
        SET_NEED_REFRESH_USER_FOLLOWING: (state, data) => {
            state.needRefreshUserFollowing = data;
        },
        SET_CURRENT_PAGE_USER_FOLLOWING: (state, page) => {
            state.currentPageUserFollowing = page;
        },


        // artist Followings
        SET_ARTIST_FOLLOWING: (state, following) => {
            state.artistFollowing = following.items;
            state.countArtistFollowing = following.count_results;
        },
        SET_NEED_REFRESH_ARTIST_FOLLOWING: (state, data) => {
            state.needRefreshArtistFollowing = data;
        },
        SET_CURRENT_PAGE_ARTIST_FOLLOWING: (state, page) => {
            state.currentPageArtistFollowing = page;
        },
        SET_HAS_FOLLOWED_USER: (state, data) => {
            state.hasFollowedUser = data;
        }
    },

    actions: {
        GET_FOLLOWERS({commit}, params) {
            commit('SET_FOLLOWERS', []);
            return axios.get(`social/followers`, {params: params})
                .then(response => {
                    commit('SET_FOLLOWERS', response.data);
                })
                .catch(err => console.error(`GET_FOLLOWERS ${err}`));
        },
        GET_USER_FOLLOWING({commit}, params) {
            commit('SET_USER_FOLLOWING', []);
            return axios.get(`social/following`, {params: params})
                .then(response => {
                    commit('SET_USER_FOLLOWING', response.data);
                })
                .catch(err => console.error(`GET_USER_FOLLOWING ${err}`))
                .finally(() => {
                    commit('SET_NEED_REFRESH_USER_FOLLOWING', false);
                })
        },
        GET_HAS_FOLLOWED_USER({commit}, userID) {
            commit('SET_HAS_FOLLOWED_USER', false);
            return axios.get(`/social/${userID}/has-follow`)
                .then(response => {
                    commit('SET_HAS_FOLLOWED_USER', response.data.result);
                })
                .catch(err => console.log(`SET_HAS_FOLLOWED_USER ${err}`));
        },
        GET_ARTIST_FOLLOWING({commit}, params) {
            commit('SET_ARTIST_FOLLOWING', []);
            return axios.get(`social/following/artists`, {params: params})
                .then(response => {
                    commit('SET_ARTIST_FOLLOWING', response.data);
                })
                .catch(err => console.error(`GET_ARTIST_FOLLOWING ${err}`))
                .finally(() => {
                    commit('SET_NEED_REFRESH_ARTIST_FOLLOWING', false);
                })
        },
        GET_CUSTOM_FOLLOWS({commit}, params) {
            return axios.get(`social/following`, {params: params}) // :TODO - change patch
                .then(faqs => {
                    commit('SET_CUSTOM_FOLLOWS', faqs.data['hydra:member']);
                })
                .catch(err => console.error(`GET_CUSTOM_FOLLOWS ${err}`));
        },

        // user follow/unfollow
        FOLLOW({commit}, id) {
            return axios.post(`social/${id}/follow`)
                .then((response) => {
                    commit('SET_NEED_REFRESH_USER_FOLLOWING', true);
                    return response.data.result;
                })
                .catch(err => console.error(`FOLLOW ${err}`))
        },
        UNFOLLOW({commit}, id) {
            return axios.post(`social/${id}/unfollow`)
                .then((response) => {
                    commit('SET_NEED_REFRESH_USER_FOLLOWING', true);
                    return response.data.result;
                })
                .catch(err => console.error(`FOLLOW ${err}`))
        },

        // artist follow/unfollow
        ARTIST_FOLLOW({commit}, id) {
            return axios.post(`artists/${id}/follow`)
                .then(response => {
                    commit('SET_NEED_REFRESH_ARTIST_FOLLOWING', true);
                    return response.data.result;
                })
                .catch(err => {
                    console.error(`ARTIST_FOLLOW ${err}`);
                    throw err;
                })
        },
        ARTIST_UNFOLLOW({commit}, id) {
            return axios.post(`artists/${id}/unfollow`)
                .then(response => {
                    commit('SET_NEED_REFRESH_ARTIST_FOLLOWING', true);
                    return response.data.result;
                })
                .catch(err => {
                    console.error(`ARTIST_UNFOLLOW ${err}`)
                    throw err;
                })
        },

    },
    getters: {
        FOLLOW_LOADER: state => state.followsLoader,
        FOLLOWS_FILTER: state => state.followsFilter,

        FOLLOWERS: state => state.followers,
        COUNT_FOLLOWERS: state => state.countFollowers,
        CURRENT_PAGE_FOLLOWERS: state => state.currentPageFollowers,

        USER_FOLLOWING: state => state.userFollowing,
        NEED_REFRESH_USER_FOLLOWING: state => state.needRefreshUserFollowing,
        COUNT_USER_FOLLOWING: state => state.countUserFollowing,
        CURRENT_PAGE_USER_FOLLOWING: state => state.currentPageUserFollowing,

        ARTIST_FOLLOWING: state => state.artistFollowing,
        NEED_REFRESH_ARTIST_FOLLOWING: state => state.needRefreshArtistFollowing,
        COUNT_ARTIST_FOLLOWING: state => state.countArtistFollowing,
        CURRENT_PAGE_ARTIST_FOLLOWING: state => state.currentPageArtistFollowing,

        CUSTOM_FOLLOWS: state => state.customFollows,
        HAS_FOLLOWED_USER: state => state.hasFollowedUser,
    }
}
