import axios from 'axios';

const initialState = () => ({
    profileUser: {},
    userUploadedAlbums: [],
    userRecentlyLikedAlbums: [],
    profileUserLogo: {},
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState);
        },
        SET_PROFILE_USER: (state, data) => {
            // Set profile user data
            state.profileUser = data;

            // Reset logo state first
            state.profileUserLogo = {};

            // Only set logo if we have valid thumbnail data
            if (data?.photo?.thumbnail) {
                state.profileUserLogo = data.photo.thumbnail;
            }
        },
        SET_USER_UPLOADED_ALBUMS: (state, data) => {
            state.userUploadedAlbums = data;
        },
        SET_USER_RECENTLY_LIKED_ALBUMS: (state, data) => {
            state.userRecentlyLikedAlbums = data;
        },
    },
    actions: {
        GET_PROFILE_USER({commit}, userID) {
            commit('SET_PROFILE_USER', {});
            return axios.get(`users/${userID}`)
                .then(user => {
                    commit('SET_PROFILE_USER', user.data)
                })
                .catch(err => {});
        },
        GET_USER_UPLOADED_ALBUMS({commit}, params) {
            commit('SET_USER_UPLOADED_ALBUMS', []);
            return axios.get('music_releases', {params: params})
                .then(response => {
                    commit('SET_USER_UPLOADED_ALBUMS', response.data['hydra:member']);
                })
                .catch(err => {});
        },
        GET_USER_RECENTLY_LIKED_ALBUMS({commit}, params) {
            commit('SET_USER_RECENTLY_LIKED_ALBUMS', []);
            return axios.get(`public_favorite_song/${params.user}`, {params: params})
                .then(response => {
                    commit('SET_USER_RECENTLY_LIKED_ALBUMS', response.data.items);
                })
                .catch(err => {})
        },
    },

    getters: {
        PROFILE_USER: state => state.profileUser,
        PROFILE_USER_LOGO: state => state.profileUserLogo,
        USER_UPLOADED_ALBUMS: state => state.userUploadedAlbums,
        USER_RECENTLY_LIKED_ALBUMS: state => state.userRecentlyLikedAlbums,
    }
}
