import axios from "axios";

const initialState = () => ({
    activeReq: null,

    topFilter: 'all',
    searchReleases: '',

    releases: [],
    releasesLoader: false,
    orderBy: {
        sort: 'releasedAt',
        order: 'desc'
    },
    countReleases: '',
    currentPage: 1
});

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_TOP_FILTER: (state, data) => {
            state.topFilter = data;
        },
        SET_SEARCH_RELEASES: (state, data) => {
          state.searchReleases = data;
        },
        SET_RELEASES: (state, data) => {
            state.releases = data.items;
            state.countReleases = data.count_results;
        },
        SET_RELEASES_LOADER: (state, data) => {
            state.releasesLoader = data;
        },
        SET_ORDER_BY: (state, data) => {
            state.orderBy = data;
        },
        SET_CURRENT_PAGE: (state, currentPage) => {
          state.currentPage = currentPage;
        }
    },
    actions: {
        GET_RELEASES({commit, state}, params) {
            commit('SET_RELEASES_LOADER', true);
            commit('SET_RELEASES', []);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get(`optimized/music_releases`, {signal, params: params})
                .then(response => {
                    commit('SET_RELEASES', response.data);
                })
                .catch(error => {
                    commit('SET_RELEASES', {items: []});
                    console.log(`GET_RELEASES error ${error}`);
                })
                .finally(() => {
                    if (!signal.aborted) commit('SET_RELEASES_LOADER', false);
                })
        },
    },
    getters: {
        TOP_FILTER: state => state.topFilter,
        SEARCH_RELEASES: state => state.searchReleases,
        RELEASES: state => state.releases,
        RELEASES_LOADER: state => state.releasesLoader,
        ORDER_BY: state => state.orderBy,
        COUNT_RELEASES: state => state.countReleases,
        CURRENT_PAGE: state => state.currentPage,
    }
}