import axios from 'axios';

const initialState = () => ({
    tempCreatedAlbumID: '',

    releaseInfoErrorMessage: '',
    albumImageStatus: '',
    prepareTrack: {},
    tempPlaylistID: '',
    currentStep: 1,
    changeAlbum: false,
    warningChangeAlbumStatus: false,
    musicReleaseCommerce: '',
    musicReleaseCommerceLoader: false,

    createdAlbumLoading: false,
    createdAlbum: '',
    selectArtist: null,
    selectLabel: null,

    variousArtists: false,
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_CURRENT_STEP: (state, step) => {
          state.currentStep = step;
        },
        SET_TEMP_CREATED_ALBUMS_ID: (state, data) => {
            state.tempCreatedAlbumID = data;
        },
        SET_RELEASE_INFO_ERROR_MESSAGE: (state, message) => {
            state.releaseInfoErrorMessage = message;
        },
        SET_ALBUM_IMAGE_STATUS: (state, data) => {
            state.albumImageStatus = data;
        },
        SET_PREPARE_TRACK: (state, data) => {
            state.prepareTrack = data;
        },
        SET_CHANGE_ALBUM: (state, data) => {
            state.changeAlbum = data;
        },
        SET_WARNING_CHANGE_STATUS: (state, data) => {
            state.warningChangeAlbumStatus = data;
        },
        SET_MUSIC_RELEASE_COMMERCE: (state, data) => {
            state.musicReleaseCommerce = data;
        },
        SET_MUSIC_RELEASE_COMMERCE_LOADER: (state, data) => {
            state.musicReleaseCommerceLoader = data;
        },
        SET_CREATED_ALBUM_LOADING: (state, data) => {
            state.createdAlbumLoading = data;
        },
        SET_CREATED_ALBUM: (state, data) => {
            state.createdAlbum = data;
        },
        SET_SELECT_ARTIST: (state, data) => {
            state.selectArtist = data;
        },
        SET_SELECT_LABEL: (state, data) => {
            state.selectLabel = data;
        },
        SET_VARIOUS_ARTISTS: (state, data) => {
            state.variousArtists = data;
        }
    },
    actions: {
        GET_CREATED_ALBUM({commit}, id) {
            commit('SET_CREATED_ALBUM_LOADING', true);
            return axios.get(`music_releases/${id}`)
                .then((album) => {
                    commit('SET_CREATED_ALBUM', album.data);
                })
                .catch(error => console.log(`GET_CREATED_ALBUM ${error}`))
                .finally(() => commit('SET_CREATED_ALBUM_LOADING', false))
        },
        ADD_MUSIC_RELEASE({commit}, data) {
            return axios.post(`music_releases`, data)
                .then((response) => {
                    if (response.data.id) {
                        commit('SET_TEMP_CREATED_ALBUMS_ID', response.data.id);
                    } else {
                        commit('SET_RELEASE_INFO_ERROR_MESSAGE', 'error');
                    }
                })
                .catch(err => {
                    console.error(`ADD_MUSIC_RELEASE ${err}`)
                    commit('SET_RELEASE_INFO_ERROR_MESSAGE', err.response.data.violations[0].message);
                })
        },
        EDIT_MUSIC_RELEASE({commit}, data) {
            return axios.patch(`music_releases/${data.id}`, data,  {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then((response) => {
                    commit('SET_CREATED_ALBUM',response.data);
                    commit('SET_TEMP_CREATED_ALBUMS_ID', response.data.id);
                })
                .catch(err => {
                    commit('SET_RELEASE_INFO_ERROR_MESSAGE', err.response.data.violations[0].message ? err.response.data.violations[0].message : 'Error');
                    console.error(`EDIT_MUSIC_RELEASE ${err}`);
                });
        },
        ADD_ALBUM_IMAGE({commit}, data) {
            return axios.post(`music_releases/${data.id}/cover`, data, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    if (response.status === 200) {
                        commit('SET_ALBUM_IMAGE_STATUS', 'success');
                    } else {
                        commit('SET_ALBUM_IMAGE_STATUS', 'error');
                    }
                })
                .catch(err => {
                    commit('SET_ALBUM_IMAGE_STATUS', 'error');
                    console.error(`ADD_ALBUM_IMAGE ${err}`);
                })
        },
        ADD_SONGS({commit}, data) {
            commit('SET_PREPARE_TRACK', {});
            return axios.post(`music_releases/${data.id}/add_song`, data)
                .then(response => {
                    let prepareTrack = {
                        url: response.data.result.preSignedUploadUrl,
                        id:  response.data.result.id
                    }
                    commit('SET_PREPARE_TRACK', prepareTrack);
                })
                .catch(err => console.error(`ADD_SONGS ${err}`))
        },

        UPDATE_SONG({commit}, data) {
            return axios.post(`optimized/music_releases/${data.releaseId}/update_song`, data)
                .catch(err => console.error(`UPDATE_SONG ${err}`))
        },

        EDIT_SONG({commit}, data) {
            return axios.patch(`songs/${data.id}`, data,  {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then((response) => {
                    // commit('SET_TEMP_CREATED_ALBUMS_ID', response.data.id);
                })
                .catch(err => console.error(`EDIT_SONG ${err}`));
        },
        REMOVE_SONG({commit}, trackID) {
            return axios.delete(`track_file_delete/${trackID}`)
                .then(() => console.log(`track ${trackID} removed`))
                .catch(err => console.error(`REMOVE_SONG ${err}`));
        },
        CHANGE_ALBUM_STATUS({commit}, params) { // Available statuses: [ "draft", "pending", "rejected", "approved", "need_review" ]
            commit('SET_CHANGE_ALBUM', true);
            let data = {
                status: params.status,
                // comment: 'test comment (change album status)'
            }
            return axios.post(`music_releases/${params.id}/set_review_status`, data)
                .then(response => {
                    console.log(`response CHANGE_ALBUM_STATUS, ${response}`);
                })
                .catch(err => console.error(`CHANGE_ALBUM_STATUS ${err}`))
                .finally(() =>  commit('SET_CHANGE_ALBUM', false));
        },
        POST_MUSIC_RELEASE_COMMERCE({commit}, data) {
            return axios.post(`music_releases/${data.id}/commerce`, data)
                .then(() => {

                })
                .catch(err => {
                    console.error(`POST_COMMERCE, ${err}`);
                })
        },
        GET_MUSIC_RELEASE_COMMERCE({commit}, params) {
            commit('SET_MUSIC_RELEASE_COMMERCE_LOADER', true);
            return axios.get(`music_releases/${params.id}/commerce`, params)
                .then((response) => {
                    commit('SET_MUSIC_RELEASE_COMMERCE', response.data.result);
                })
                .catch(err => {
                    console.error(`GET_MUSIC_RELEASE_COMMERCE, ${err}`);
                })
                .finally(() => {
                    commit('SET_MUSIC_RELEASE_COMMERCE_LOADER', false);
                })
        }
    },
    getters: {
        CURRENT_STEP: state => state.currentStep,
        TEMP_CREATED_ALBUMS_ID: state => state.tempCreatedAlbumID,
        RELEASE_INFO_ERROR_MESSAGE: state => state.releaseInfoErrorMessage,
        ALBUM_IMAGE_STATUS: state => state.albumImageStatus,
        PREPARE_TRACK: state => state.prepareTrack,
        TEMP_PLAYLIST_ID: state => state.tempPlaylistID,
        UPLOAD_TRACK: state => state.uploadTrack,
        CHANGE_ALBUM: state => state.changeAlbum,
        WARNING_CHANGE_STATUS: state => state.warningChangeAlbumStatus,
        MUSIC_RELEASE_COMMERCE: state => state.musicReleaseCommerce,
        MUSIC_RELEASE_COMMERCE_LOADER: state => state.musicReleaseCommerceLoader,

        CREATED_ALBUM: state => state.createdAlbum,
        CREATED_ALBUM_LOADING: state => state.createdAlbumLoading,

        SELECT_ARTIST: state => state.selectArtist,
        SELECT_LABEL: state => state.selectLabel,
        VARIOUS_ARTISTS: state => state.variousArtists,
    }
}
