import axios from 'axios';

const initialState = () => ({
    headerNotifications: false,
    headerAdminMenu: false,
    headerUserMenu: false,
    headerShoppingCart: false,

    playerAlbumsList: [],
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_HEADER_NOTIFICATIONS: (state, data) => {
            state.headerNotifications = data;
        },
        SET_HEADER_ADMIN_MENU: (state, data) => {
            state.headerAdminMenu = data;
        },
        SET_HEADER_USER_MENU: (state, data) => {
            state.headerUserMenu = data;
        },
        SET_HEADER_SHOPPING_CART: (state, data) => {
            state.headerShoppingCart = data;
        },

        SET_PLAYED_ALBUMS_LIST: (state, data) => {
            state.playerAlbumsList = data;
        },

    },
    actions: {

        // REMOVE_RELEASE_FROM_COLLECTIONS({commit}, albumID) {
        //     if (albumID) {
        //         console.log('deleteAlbum music release id', albumID);
        //         console.log(this.getters['RELEASES_COLLECTION']);
        //         // for (let collection of this.RELEASES_COLLECTION) {
        //         //     let getter = this.$store.getters[collection];
        //         //     if (getter && getter.length) {
        //         //         console.log('deleteAlbum music release getter', collection);
        //         //         let albumIndex = getter.findIndex((album) => +album.id === albumID);
        //         //         if (albumIndex !== -1) {
        //         //             console.log('deleteAlbum music release albumIndex', albumIndex);
        //         //             getter.splice(albumIndex, 1);
        //         //         }
        //         //     }
        //         // }
        //     } else {
        //         console.log('deleteAlbum need music release id', albumID);
        //     }
        // }
    },
    getters: {
        HEADER_NOTIFICATIONS: state => state.headerNotifications,
        HEADER_ADMIN_MENU: state => state.headerAdminMenu,
        HEADER_USER_MENU: state => state.headerUserMenu,
        HEADER_SHOPPING_CART: state => state.headerShoppingCart,

        PLAYER_ALBUMS_LIST: state => state.playerAlbumsList,

        RELEASES_COLLECTION: () => [
            'manageUploadsReleases/RELEASES',
            'collection/ALBUMS',
            'SEARCH_ALBUMS_RESULT',
            'EXPLORE_GRID_ALBUMS', 'FAVOURITE_MUSIC_RELEASE',
            'USER_UPLOADED_ALBUMS', 'USER_RECENTLY_LIKED_ALBUMS',
            'ARTIST_PROFILE_RELEASES_ALBUMS', 'ARTIST_PROFILE_APPEARS_ON_ALBUMS',
            'PRIVATE_PROFILE_UPLOADED_ALBUMS', 'PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS', 'PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS',
        ],
        TRACKS_COLLECTION: () => [
            'TRACKS', 'FAVOURITE_TRACKS',
            'collection/TRACKS',
            'TRACK_LIST', // player
            'SEARCH_TRACKS_RESULT'
        ],
    }
}
