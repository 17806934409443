<template>
    <div class="explore_albums_nav mobile-view" id="explore-albums-nav-id">
      <button type="button" @click="selectionAlbums('explore')" :class="{active: UPPER_FILTER === 'explore'}">Explore</button>

      <template v-if="AUTH_DATA.accessToken">
        <button type="button" @click="selectionAlbums('undiscovered')" :class="{active: UPPER_FILTER === 'undiscovered'}">Discover</button>
      </template>

      <button type="button" @click="selectionAlbums('popular')" :class="{active: UPPER_FILTER === 'popular'}">Popular</button>

      <template v-if="AUTH_DATA.accessToken">
        <button type="button" @click="selectionAlbums('favorite')" :class="{active: UPPER_FILTER === 'favorite'}">Favorite</button>
      </template>

      <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import eventBus from "@/eventBus";

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");

export default {
    name: "albumsNavMobile",
    components: {
        ComingSoonDialog
    },
    data() {
        return {
            showComingSoonDialog: false,
        }
    },
    watch: {
        UPPER_FILTER: {
            handler(current, prev) {
                if (current === 'favorite') {
                    this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
                } else {
                    this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
                    this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
                }
                this.$store.commit('SET_ALBUM_EXPANDED', '');
            }
        },
    },
    computed: {
        ...mapGetters(['isAdmin', 'AUTH_DATA', 'UPPER_FILTER', 'STATUS_FILTER', 'VIEW_ALBUMS', 'ALBUM_EXPANDED', 'ALBUMS_CURRENT_PAGE'])
    },
    created() {
        if (!this.UPPER_FILTER) {
            if (this.AUTH_DATA.accessToken) {
                this.$store.dispatch('UPDATE_UPPER_FILTER', 'undiscovered');
            } else {
                this.$store.dispatch('UPDATE_UPPER_FILTER', 'explore');
            }
        }
      console.log('asdasdads');
      this.$store.commit('SET_STATUS_FILTER', true)
    },
    mounted() {
        if (this.UPPER_FILTER === 'pending') {
            this.$store.commit('SET_VIEW_ALBUMS', 'list');
        }
    },
    methods: {
        selectionAlbums(value) {
            this.$store.dispatch('UPDATE_UPPER_FILTER', value);
        },
        toggleFilter() {
            if (this.STATUS_FILTER === true) {
              this.$store.commit('SET_STATUS_FILTER', false);
              this.$store.dispatch('CLEAN_FILTERS');
              this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
              this.$store.commit('SET_ALBUM_EXPANDED','');
	            this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', null);
	            this.$store.commit('SET_TRACK_SCROLL_ID', null);
            } else {
                this.$store.commit('SET_STATUS_FILTER', true);
            }
        },
        changeVisibleAlbums(value) {
            this.$store.commit('SET_VIEW_ALBUMS', value);
        },
        bookmarks() {
            this.showComingSoonDialog = true;
        }
    }
}
</script>

<style scoped>
@media only screen and (min-width: 360px) and (max-width: 479px){
  .explore_albums_nav.mobile-view button:not(:last-child) {
    margin-right: 30px;
  }
}

.explore_albums_nav {
  &.mobile-view {
    display: flex;
    justify-content: space-between;

    button {
      color: var(--Pure-White, #FFF);
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      letter-spacing: -0.54px;

      &.active {
        &:after {
          width: 100%;
          left: 0;
        }
      }

      &:not(:last-child) {
        margin-right: 0px;
      }
    }
  }
}
</style>
