<template>
	<div class="advertisement" v-if="USER_PERMISSIONS === 'free'">
		<div class="advertisement-title">Advertisement</div>
		<Adsense
			ins-style=""
			:data-ad-client=client
			:data-ad-slot=slot
			data-ad-format="horizontal"
			data-full-width-responsive=true>
		</Adsense>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "googleAdsense",
	props: [],
	mixins: [],

	data() {
		return {
			client: process.env.VUE_APP_ADSENSE_CLIENT,
			slot: process.env.VUE_APP_ADSENSE_SLOT,
		}
	},
    computed: {
        ...mapGetters(['USER_PERMISSIONS'])
    }
}
</script>

<style scoped>

</style>
