<template>
    <div class="wrap_notification_window" id="wrap-notification-window" v-click-outside="closeWindowNotifications">
        <button type="button" 
                class="notifications" 
                id="header-notifications" 
                v-ripple 
                @click="showWindowNotifications"
                v-if="isAdmin">
        </button>
        <!-- v-if="isAdmin"currently available to admin only, v-if="IS_TOKEN_ACTIVE"> to enable for all users -->

        <transition name="slide-y-transition">
            <div class="notification_window" 
                 v-if="HEADER_NOTIFICATIONS === true" 
                 :class="{is_player: PLAYER_STATUS}">
                <div class="head">
                    <h2>Notifications</h2>
	                <router-link :to="{name: 'notifications'}" class="edit"></router-link>
                </div>
                <div class="filters">
                    <button type="button" v-for="(filter, index) of filters" @click="setFilter(filter)" :key="index" :class="{active: filter === activeFilter}">
                        {{ filter }}
                    </button>
                </div>

                <div class="list_notifications">
                    <div class="item" v-for="item of NOTIFICATIONS" :key="item.id" :class="item.type_value"> <!-- music || events || social || system-->
                        <div class="wrap_ava">
                            <div class="ava default" >

                            </div>
                            <div class="label"></div>
                        </div>

                        <div class="info_notification">
	                        <div class="this_notify">
                                {{item.message}}
<!--                                <a>Siasma Records</a> added a new release-->
<!--                                <a>Petty looks</a>-->
	                        </div>
							<div class="ago">- hours ago</div>
                        </div>
                    </div>
                    <div class="overlay custom_style_loader" v-if="loader">
                        <div class="progress_border">
                            <v-progress-circular
                                :size="250"
                                :width="38"
                                bg-color="rgba(255, 255, 255, 1)"
                                color="#20C4F5"
                                indeterminate
                            ></v-progress-circular>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "notifications",
    data: () => ({
        filters: ['Music', 'Events', 'Social', 'Commerce', 'System'],
        show: false,
        activeFilter: 'Music',
        loader: false,
    }),
    watch: {
	    '$route'(to, from) {
		    this.closeWindowNotifications();
	    },
        activeFilter() {
            // this.closeWindowNotifications();
        }
    },
    computed: {
      ...mapGetters([
          'NOTIFICATIONS', 
          'HEADER_NOTIFICATIONS', 
          'PLAYER_STATUS', 
          'IS_TOKEN_ACTIVE',
          'isAdmin'
      ])
    },
    created() {
      this.getNotification();
    },
    methods: {
        ...mapActions(['GET_NOTIFICATIONS']),
        getNotification() {
            this.loader = true;
            let filters = {
				// type: this.activeFilter
	            // type: 'all' // TEMP
            }
            // if (this.activeFilter !== 'all') {
            //     filters.type = this.activeFilter;
            // }
            this.GET_NOTIFICATIONS(filters)
	            .then(() => {
		            // date sort
		            this.NOTIFICATIONS.sort((a, b) => new Date(a.date) - new Date(b.date));


		            const groupedData = this.NOTIFICATIONS.reduce((acc, item) => {
			            const dateKey = item.date;

			            if (!acc[dateKey]) {
				            acc[dateKey] = [];
			            }

			            acc[dateKey].push(item);

			            return acc;
		            }, {});

					// groupedData
		            console.log(groupedData);
	            })
	            .finally(() => {
                this.loader = false;
            });
        },
        setFilter(el) {
            this.activeFilter = el;
        },
        showWindowNotifications() {
            if (this.HEADER_NOTIFICATIONS === false) {
                this.$store.commit('SET_HEADER_NOTIFICATIONS', true);
            } else {
                this.$store.commit('SET_HEADER_NOTIFICATIONS', false);
            }
        },
        closeWindowNotifications() {
            this.$store.commit('SET_HEADER_NOTIFICATIONS', false);
        }
    }
}
</script>

<style lang="scss">
.wrap_notification_window {
    display: flex;
    //position: relative;
}
</style>