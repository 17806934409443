<template>
	<div
	  class="item_grid_album"
	  @click="redirectToAlbum(musicRelease)"
	  :class="{
		'playing_album': isCurrentPlaying || playing,
		'loadingAlbum': LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id,
		'is_listened': musicRelease.isListened,
		'deleted': currentlyUnavailable,
		'small': small
	  }"
	  :ref="`album-${musicRelease.id}`"
	>
	  <div class="album_cover" :class="{'no_img': !musicRelease.smallImage}">
		<!-- Skeleton Loader for Album Image -->
		<div v-show="!albumImageLoaded[musicRelease.id]" class="skeleton-loader default_grid_albums">
		  <div class="v-skeleton-loader__image"></div>
		</div>
  
		<!-- Image element with @load handler -->
		<img
		  :src="musicRelease.smallImage || musicRelease.image"
		  alt=""
		  @load="onImageLoad(musicRelease.id)"
		  loading="lazy"
		  :class="{ 'image-loaded': albumImageLoaded[musicRelease.id] }"
		>
  
		<!-- Active overlay appears after image load -->
		<div
		  v-show="albumImageLoaded[musicRelease.id]"
		  class="active_overlay"
		  :class="{
			'active_like': musicRelease.isFavorite === true,
			'purchased': musicRelease.isPurchased && musicRelease.isPurchased === true
		  }"
		>
		  <template v-if="!musicRelease.isPurchased || musicRelease.isPurchased !== true">
			<template v-if="!hideLike">
			  <!-- Empty heart (not liked) -->
			  <button 
				type="button" 
				class="like"
				v-if="!musicRelease.isFavorite" 
				@click.stop="handleLikeClick"
			  ></button>

			  <!-- Filled heart (liked) -->
			  <button 
				type="button" 
				:class="['like active', { 
				  'removing': isRemoving,
				  'animate-like': isLikeAnimating 
				}]" 
				v-else
				@click.stop="handleLikeClick"
			  >
				<div class="particles" v-if="isLikeAnimating">
				  <div class="particle" v-for="n in 8" :key="n"></div>
				</div>
				<div class="break-particles" v-if="isRemoving">
				  <div class="break-particle" v-for="n in 2" :key="n"></div>
				</div>
			  </button>
			</template>
		  </template>
		  <template v-else>
			<div class="release_purchased"></div>
		  </template>
  
		  <template v-if="!currentlyUnavailable">
			<div class="mobile_actions">
			  <div class="play" v-if="!isCurrentPlaying && !FULL_ALBUM_LOADING" @click.stop="playAlbum">
				<span class="material-icons">play_arrow</span>
			  </div>
			  <div class="play" v-if="isCurrentPlaying && !playing" @click.stop="play">
				<span class="material-icons">play_arrow</span>
			  </div>
			  <div class="pause" v-if="isCurrentPlaying && playing || LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id" @click.stop="pause"> 
				<span class="material-icons">volume_up</span> Playing
			  </div>
			</div>
  
			<button class="play_album" v-if="!isCurrentPlaying" @click.stop="playAlbum" :class="[isCurrentPlaying ? 'current' : 'not_current']">
			  <span class="material-icons">play_arrow</span>
			</button>
			<button class="play_album" v-if="isCurrentPlaying && !playing" @click.stop="play" :class="[isCurrentPlaying ? 'current' : 'not_current']">
			  <span class="material-icons">play_arrow</span>
			</button>
  
			<button type="button" class="pause_album" v-if="isCurrentPlaying && playing || LOADING_RELEASE_ID && LOADING_RELEASE_ID === musicRelease.id"
					@click.stop="pause">
			  <span></span>
			  <v-progress-circular
				v-if="FULL_ALBUM_LOADING && !isCurrentPlaying"
				:size="55"
				:width="7"
				indeterminate
				color="#20C4F5">
			  </v-progress-circular>
			</button>
		  </template>
		  <template v-if="currentlyUnavailable === true">
			<div class="currently_unavailable">Unavailable</div>
			<button type="button" class="btn_currently_unavailable" @click.stop="removeRelease(musicRelease.id)" v-ripple>Remove</button>
		  </template>
		</div>
	  </div>
  
	  <div class="album_name">{{ musicRelease.title }}</div>
	  <div class="album_artist" v-if="!hideArtist" @click.stop="redirectToArtistPage(musicRelease)">
		<template v-if="musicRelease.artist">
		  <span>by</span>
		  {{ musicRelease.artist.name }}
		</template>
	  </div>
  
	  <dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible" :action="needLoginDialog.action"></dialogNeedLogin>
	</div>
  </template>
  
  <script>
  import {mapActions, mapGetters} from 'vuex';
  import addAlbumToFavorites from "@/mixins/musicLike/addAlbumToFavorites";
  import removeAlbumFromFavorites from "@/mixins/musicLike/removeAlbumFromFavorites";
  
  const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
  
  export default {
	name: "albumGrid",
	props: ['album', 'small', 'discoveryStatus', 'isFavouriteSong', 'hideArtist', 'playOnlyLikedTracks', 'hideLike', 'playZone'],
	components: {
	  dialogNeedLogin,
	},
	mixins: [
	  addAlbumToFavorites,
	  removeAlbumFromFavorites
	],
	data() {
	  return {
		musicRelease: this.album,
		albumImageLoaded: {}, // Object to track image loading state for each album
		showAllTracks: false,
		needLoginDialog: {
		  action: null,
		  visible: false,
		},
		playListLoading: false,
		currentlyUnavailable: false,
		isRemoving: false,
		isLikeAnimating: false
	  }
	},
	computed: {
	  ...mapGetters({
		authData: 'AUTH_DATA',
		playing: 'PLAYING',
		currentAlbum: 'CURRENT_ALBUM',
		albumExpanded: 'ALBUM_EXPANDED',
	  }),
	  ...mapGetters([
		'ALBUM', 'EXPLORE_GRID_ALBUMS', 'DISCOVERY', 'CURRENT_PAGE', 'PLAYED_ONLY_FAV_TRACKS',
		'UPPER_FILTER', 'ALBUM_LOADING', 'FULL_ALBUM_LOADING', 'FULL_ALBUM', 'LOADING_RELEASE_ID',
		'GRID_ALBUM_SCROLL_ID'
	  ]),
	  isCurrentPlaying() {
		return !!this.currentAlbum && this.currentAlbum.id === this.album.id;
	  },
	},
	created() {
	  let status = null;
  
	  if (this.musicRelease.status.value) {
		status = this.musicRelease.status.value;
	  } else {
		status = this.musicRelease.status;
	  }
	  if (this.musicRelease.deletedAt && this.musicRelease.havePurchased === false || (status !== 'published' && status !== 'grandfathered')) {
		this.currentlyUnavailable = true;
	  }
	  if (this.UPPER_FILTER === 'favorite' && this.CURRENT_PAGE.name === 'explore') {
		this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', true);
	  }
	},
	mounted() {
	  const currentAlbum = this.$refs[`album-${this.GRID_ALBUM_SCROLL_ID}`];
	  let currentRoute = this.$router.history.current.name;
  
	  if (currentAlbum && (currentRoute === 'explore' || currentRoute === 'subgenre' || currentRoute === 'searchDetails')) {
		this.$nextTick(() => {
		  currentAlbum.scrollIntoView({
			block: 'center'
		  });
		})
	  }
	},
	methods: {
	  // Handle image loading for each album
	  onImageLoad(albumId) {
		// Use nextTick to ensure DOM update before changing the state
		this.$nextTick(() => {
		  this.$set(this.albumImageLoaded, albumId, true);
		});
	  },
	  ...mapActions({
		play: 'SET_PLAY',
		pause: 'SET_PAUSE',
		getAlbum: 'GET_ALBUM',
		getFullAlbum: 'GET_FULL_ALBUM',
		removeReleaseFromFavorites: 'REMOVE_ALBUM_FROM_FAVORITES',
	  }),
	  redirectToArtistPage(musicRelease) {
		let artistID = musicRelease.artist.id;
		let artistName = musicRelease.artist.identifier;
		let musicReleaseID = musicRelease.id;
  
		this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', musicReleaseID);
		this.$router.push({
		  name: 'artist-profile', params: {
			id: artistID,
			name: window.toHumanFormat(artistName)
		  }
		});
	  },
	  redirectToAlbum(musicRelease) {
		if (!musicRelease.deletedAt && musicRelease.status !== 'draft' || (musicRelease.deletedAt && musicRelease.havePurchased === true)) {
		  let musicReleaseID = musicRelease.id;
  
		  this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', musicReleaseID);
		  this.$router.push({
			name: 'albumDetails', params: {
			  id: musicReleaseID,
			  name: window.toHumanFormat(musicRelease.identifier),
			  favourite: this.isFavouriteSong
			}
		  });
		}
	  },
	  playAlbum() {
		let params = { id: this.musicRelease.id, title: this.musicRelease.title };
		this.getFullAlbum(params).then(() => {
		  this.musicRelease = this.FULL_ALBUM;
		  this.runPlay();
		});
	  },
	  runPlay() {
		if (!this.isCurrentPlaying) {
		  let albumToPlay;
  
		  if (this.playOnlyLikedTracks === true && !this.musicRelease.isPurchased) {
			albumToPlay = this.musicRelease.playList.filter(track => track.song.isFavorite === true);
			this.$store.commit('SET_PLAYED_ONLY_FAV_TRACKS', true);
		  } else {
			albumToPlay = this.musicRelease.playList.filter(track => !track.song.deletedAt && track.song.link);
			this.$store.commit('SET_PLAYED_ONLY_FAV_TRACKS', false);
		  }
  
		  this.$store.dispatch('SET_PLAYED_ZONE', this.playZone);
  
		  this.play({
			fromPlay: 'album_default',
			track: albumToPlay[0].song,
			index: 0,
			album: this.musicRelease,
			isDiscoveryStatus: this.discoveryStatus
		  });
		  this.playListLoading = false;
		}
	  },
	  removeRelease(albumID) {
		this.$store.dispatch('REMOVE_RELEASE_FROM_COLLECTIONS', albumID);
		this.removeReleaseFromFavorites({ 'music_release_id': albumID }).then(() => {
		  // Handle any post-removal actions here
		});
	  },
	  async handleLikeClick() {
		if (this.musicRelease.isFavorite) {
		  this.isRemoving = true;
		  await new Promise(resolve => setTimeout(resolve, 400));
		  await this.removeAlbumFromFavorites(this.musicRelease);
		  this.isRemoving = false;
		} else {
		  await this.addAlbumToFavorites(this.musicRelease);
		  this.$nextTick(() => {
			this.isLikeAnimating = true;
			setTimeout(() => {
			  this.isLikeAnimating = false;
			}, 800);
		  });
		}
	  },
	  onLikeAnimationEnd() {
		this.isLikeAnimating = false;
	  }
	}
  }
  </script>
  
  <style scoped>
  .album_cover {
	position: relative;
	width: 100%;
	padding-bottom: 100%; /* Creates a 1:1 aspect ratio */
	overflow: hidden;
  }
  
  .skeleton-loader.default_grid_albums {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    #1a2235 0%,
    #243049 30%,
    #1a2235 50%,
    #1a2235 100%
  );
  background-size: 300% 100%;
  animation: shimmer 2s infinite;
  border-radius: 12px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}



  
  img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0;
	transition: opacity 0.3s ease;
	z-index: 1; /* Ensure image is below the skeleton loader */
  }
  
  img.image-loaded {
	opacity: 1;
  }
  
  .active_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3; /* Ensure overlay is above both loader and image */
	/* Add any other styles for your overlay */
  }
  
  @media (max-width: 479px) {
	.v-skeleton-loader__image {
	  height: 170px;
	}
  }
  </style>
  