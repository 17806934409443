import axios from "axios";

const initialState = () => ({
    // manageArtists: [],
    // manageArtistsLoader: false,
    topFilter: 'current',
    // searchArtist:
});
export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        // SET_MANAGE_ARTISTS: (state, data) => {
        //     state.manageArtists = data;
        // },
        SET_TOP_FILTER: (state, data) => {
            state.topFilter = data;
        },
        // SET_MANAGE_ARTIST_LOADER: (state, data) => {
        //     state.manageArtistsLoader = data;
        // }
    },
    actions: {
        // GET_MANAGE_ARTISTS({commit}, params) {
        //     commit('SET_MANAGE_ARTIST_LOADER', true);
        //     return axios.get(`artists`, {params: params})
        //         .then(artists => {
        //             commit('SET_MANAGE_ARTISTS', artists.data['hydra:member']);
        //         })
        //         .catch(err => console.log(`GET_MANAGE_ARTISTS, ${err}`))
        //         .finally(() => {
        //             commit('SET_MANAGE_ARTIST_LOADER', false);
        //         })
        // }
    },
    getters: {
        // MANAGE_ARTISTS: state => state.manageArtists,
        // MANAGE_ARTIST_LOADER: state => state.manageArtistsLoader,
        TOP_FILTER: state => state.topFilter,
    }
}