import axios from 'axios';
import createMultiTabState from 'vuex-multi-tab-state';
import AccountM from "@/models/accountM";
import PaginationM from "@/models/paginationM";

const initialState = () => ({
    profile: null,
    profileLoader: false,
    activeReq: null,

    user: {},
    changePasswordStatus: '',
    changeFilterStatus: '',
    changeUserAva: false,
    userPermissions: null,
    userAccounts: PaginationM,
    profileLogo: {},
    userLogo: {},
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_PROFILE: (state, data) => {
            state.profile = data;

            if (state.profile && state.profile.photo && state.profile.photo.thumbnail) {
                state.profileLogo = state.profile.photo.thumbnail;
            }
        },
        SET_PROFILE_LOADER: (state, data) => {
          state.profileLoader = data;
        },
        SET_USER: (state, data) => {
            state.user = data;
            if (state.user && state.user.photo && state.user.photo.thumbnail) {
                state.userLogo = state.user.photo.thumbnail;
            }
        },
        SET_CHANGE_PASSWORD_STATUS(state, status) {
            state.changePasswordStatus = status;
        },
        SET_CHANGE_FILTERS_STATUS(state, data) {
            state.changeFilterStatus = data;
        },
        SET_CHANGE_USER_AVA: (state, data) => {
            state.changeUserAva = data;
        },
        SET_USER_PERMISSIONS: (state, data) => {
            state.userPermissions = data;
        },
        SET_USER_ACCOUNTS: (state, data) => {
            state.userAccounts = new PaginationM<AccountM>(data, AccountM)
        },
        SET_USER_ACCOUNTS_LOADER: (state, status) => {
            state.userAccountsLoader = status
        },
    },
    actions: {
        GET_PROFILE({commit, state}) {
            commit('SET_PROFILE_LOADER', true);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get('get_profile', {signal})
                .then(response => {
                    let profile = response.data.result;
                    this.commit('SET_PROFILE', profile);

                    if (profile.subscriptionPlan === 'free') {
                        commit('SET_USER_PERMISSIONS', 'free');
                    }
                    if (profile.subscriptionPlan === 'premium' && profile.isFounder === false) {
                        commit('SET_USER_PERMISSIONS', 'premium');
                    }
                    if (profile.subscriptionPlan === 'premium' && profile.isFounder === true) {
                        commit('SET_USER_PERMISSIONS', 'founder');
                    }
                })
                .catch(err => console.warn(`GET_PROFILE ${err}`))
                .finally(() => {
                        if (!signal.aborted) {
                            commit('SET_PROFILE_LOADER', false);
                        }
                });
        },
        GET_USER({commit}, userID) {
            commit('SET_USER', []);
            return axios.get(`users/${userID}`)
                .then(user => {
                    commit('SET_USER', user.data)
                })
                .catch(err => console.log(`GET_USER ${err}`));
        },
        CHANGE_USER_DATA({commit}, userData) {
            return axios.patch(`users/${userData.id}`, userData, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then(() => {

                })
                .catch((err) => {
                    console.log(`CHANGE_USER_DATA ${err}`);
                });
        },
        CHANGE_USER_AVA({commit}, data) {
            return axios.patch(`users/5`, data, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(() => {
                    console.log('CHANGE_USER_AVA success');
                })
                .catch(err => {
                    console.log(`CHANGE_USER_AVA ${err}`);
                })
        },
        CHANGE_PASSWORD({commit}, data) {
            return axios.patch(`change_password`, data, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then((response) => {
                    console.log('CHANGE_PASSWORD', response);

                    if (response && response.status === 204) {
                        commit('SET_CHANGE_PASSWORD_STATUS', 'success');
                    } else {
                        commit('SET_CHANGE_PASSWORD_STATUS', 'failed');
                    }
                })
                .catch((err) => {
                    console.log(`CHANGE_PASSWORD ${err}`);
                    commit('SET_CHANGE_PASSWORD_STATUS', 'failed');
                });
        },
        CHANGE_FILTERS({commit}, data) {
            return axios.patch(`users/${data.userID}`, {'searchFilters': data.filters}, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then((response) => {
                    commit('SET_CHANGE_FILTERS_STATUS', 'success');
                })
                .catch((err) => {
                    commit('SET_CHANGE_FILTERS_STATUS', 'failed');
                    console.log(`CHANGE_FILTERS ${err}`);
                })
        },
        GET_USER_ACCOUNTS({commit}, params) {
            return axios.get(`users/${params.owner}/accounts`, {params: params})
                .then(accounts => commit('SET_USER_ACCOUNTS', accounts.data))
                .catch(err => console.log(`SET_USER_ACCOUNTS ${err}`))
        },

    },

    getters: {
        PROFILE: state => state.profile,
        PROFILE_LOADER: state => state.profileLoader,
        PROFILE_LOGO: state => state.profileLogo,

        USER: state => state.user,
        USER_LOGO: state => state.userLogo,
        CHANGE_PASSWORD_STATUS: state => state.changePasswordStatus,
        CHANGE_FILTERS_STATUS: state => state.changeFilterStatus,
        CHANGE_USER_AVA: state => state.changeUserAva,
        USER_PERMISSIONS: state => state.userPermissions,
        USER_ACCOUNTS: state => state.userAccounts,
    },
    plugins: [
        createMultiTabState({
            statesPaths: ['profile', 'profileLoader', 'userPermissions'],
            onBeforeSave: (state) => {
                // Modify state here
                return state;
            },
            onBeforeReplace: (state) => {
                // Modify state here
                return state;
            }
        }),
    ]
}
