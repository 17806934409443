// ./directives/outside-click/index.js

const id = 'click-outside';

const definition = {
    bind: function(el, binding, vNode) {
        // Ensure binding value is a function or an object with a handler function
        const handler = typeof binding.value === 'function' 
            ? binding.value 
            : binding.value?.handler;

        if (!handler) {
            const compName = vNode.context?.name;
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function or a valid handler, but it needs to be.`;
            if (compName) { warn += ` Found in component '${compName}'`; }
            console.warn(warn);
            return;
        }

        // Define the click handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const clickHandler = (e) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                handler(e);
            }
        };
        el.__vueClickOutside__ = clickHandler;

        // Add Event Listeners
        document.addEventListener('click', clickHandler);
    },

    unbind: function(el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
    }
};

export { id, definition };
