const createEvent = () => import('@/pages/event/createEvent');
const events = () => import('@/pages/event/events');
const eventDetails = () => import('@/pages/event/eventDetails');

export default [
    {
        path: '/create-event/:id?',
        name: 'createEvent',
        component: createEvent,
        props: false,
        roles: ['ROLE_ADMIN'],
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/events',
        name: 'events',
        component: events,
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
    {
        path: '/event/:id',
        name: 'eventDetails',
        component: eventDetails,
        props: true,
        meta: {
            requiresAuth: true,
            requiresVerify: true,
        }
    },
]
