import axios from 'axios';

const initialState = () => ({
    tempCreatedEventsID: '',

    // events data
    events: [],
    countEvents: '',
    currentPage: 1,

    eventsLoading: false,
    activeReq: null,

    viewedEvents: [],
    upcomingEvents: [],
    popularEvents: [],
    newEvents: [],
    homeEvents: [],

    event: '',

    // filters
    location: '',
    date: '',
    duration: '',

    // others
    viewEvents: 'map',
});

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_TEMP_CREATED_EVENT_ID: (state, id) => {
            state.tempCreatedEventsID = id;
        },
        SET_EVENTS_LOADING: (state, data) => {
            state.eventsLoading = data;
        },

        // events data
        SET_EVENTS: (state, events) => {
            state.events = events['hydra:member'];
            state.countEvents = events['hydra:totalItems'];
        },
        SET_EVENTS_CURRENT_PAGE: (state, data) => {
            state.currentPage = data;
        },

        SET_VIEWED_EVENTS: (state, events) => {
            state.viewedEvents = events;
        },
        SET_UPCOMING_EVENTS: (state, events) => {
            state.upcomingEvents = events;
        },
        SET_POPULAR_EVENTS: (state, events) => {
            state.popularEvents = events;
        },
        SET_NEW_EVENTS: (state, events) => {
            state.newEvents = events;
        },
        SET_HOME_EVENTS: (state, events) => {
            state.homeEvents = events;
        },


        SET_EVENT: (state, event) => {
            state.event = event;
        },


        // filters
        SET_EVENT_LOCATION: (state, data) => {
          state.location = data;
        },
        SET_EVENT_DATE: (state, data) => {
            state.date = data;
        },
        SET_EVENT_DURATION: (state, data) => {
            state.duration = data;
        },

        // others
        SET_VIEW_EVENTS: (state, data) => {
            state.viewEvents = data;
        }
    },
    actions: {
        GET_EVENTS({commit, state}, params) {
            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            commit('SET_EVENTS_LOADING', true);
            return axios.get(`events`, {signal, params: params})
                .then(response => {
                    commit('SET_EVENTS', response.data);
                })
                .catch(err => console.log(`GET_EVENTS ${err}`))
                .finally(() => {
                    if (!signal.aborted) {
                        commit('SET_EVENTS_LOADING', false);
                    }
                })
        },
        GET_VIEWED_EVENTS({commit}, params) {
            return axios.get(`events/viewed`, {params: params})
                .then(response => {
                    commit('SET_VIEWED_EVENTS', response.data);
                })
                .catch(err => console.log(`GET_VIEWED_EVENTS ${err}`));
        },
        GET_UPCOMING_EVENTS({commit}, params) {
            return axios.get(`events/upcoming`, {params: params})
                .then(response => {
                    commit('SET_UPCOMING_EVENTS', response.data);
                })
                .catch(err => console.log(`GET_UPCOMING_EVENTS ${err}`));
        },
        GET_POPULAR_EVENTS({commit}, params) {
            return axios.get(`events/popular`, {params: params})
                .then(response => {
                    commit('SET_POPULAR_EVENTS', response.data);
                })
                .catch(err => console.log(`GET_POPULAR_EVENTS ${err}`));
        },
        GET_NEW_EVENTS({commit}, params) {
            return axios.get(`events/new`, {params: params})
                .then(response => {
                    commit('SET_NEW_EVENTS', response.data);
                })
                .catch(err => console.log(`GET_NEW_EVENTS ${err}`));
        },
        GET_HOME_EVENTS({commit}, params) {
            return axios.get(`events/home`, {params: params})
                .then(response => {
                    commit('SET_HOME_EVENTS', response.data);
                })
                .catch(err => console.log(`GET_HOME_EVENTS ${err}`));
        },



        GET_EVENT({commit}, id) {
            return axios.get(`events/${id}`)
                .then(response => {
                    commit('SET_EVENT', response.data)
                })
                .catch(err => console.log(`GET_EVENT ${err}`));
        },
        ADD_EVENT({commit}, event) {
            return axios.post(`events`, event)
                .then((response) => {
                    commit('SET_TEMP_CREATED_EVENT_ID', response.data.id);
                })
                .catch(err => console.log(`ADD_EVENT ${err}`));
        },
        CHANGE_EVENT({commit}, event) {
            return axios.patch(`events/${event.id}`).catch(err => console.log(`CHANGE_EVENT ${err}`));
        },
        DELETE_EVENT({commit}, event) {
            return axios.delete(`events/${event.id}`).catch(err => console.log(`DELETE_EVENT ${err}`));
        },

        // others
        UPDATE_VIEW_EVENTS({commit}, data) {
            commit('SET_VIEW_EVENTS', data);
        }
    },
    getters: {
        TEMP_CREATED_EVENT_ID: state => state.tempCreatedEventsID,
        EVENTS_LOADING: state => state.eventsLoading,

        EVENTS: state => state.events,
        COUNT_EVENTS: state => state.countEvents,
        CURRENT_PAGE: state => state.currentPage,

        VIEWED_EVENTS: state => state.viewedEvents,
        UPCOMING_EVENTS: state => state.upcomingEvents,
        POPULAR_EVENTS: state => state.popularEvents,
        NEW_EVENTS: state => state.newEvents,
        HOME_EVENTS: state => state.homeEvents,


        EVENT: state => state.event,

        // filters
        LOCATION: state => state.location,
        DATE: state => state.date,
        DURATION: state => state.duration,

        // others
        VIEW_EVENTS: state => state.viewEvents,
    }
}
