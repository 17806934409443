import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {}
    },
    watch: {
        // addAlbumToFavorites(newVal, oldVal) {
        //   console.log('addAlbumToFavorites', newVal, oldVal);
        // },
        // afterActionDialogNeedLogin() {
        //     console.log('this.afterActionDialogNeedLogin', this.afterActionDialogNeedLogin);
        //     if (this.afterActionDialogNeedLogin === false) {
        //         this.removeMarkAlbum();
        //     }
        // }
    },
    computed: {
        ...mapGetters({
            authData: 'AUTH_DATA',
            currentAlbum: 'CURRENT_ALBUM',
            fullAlbum: 'FULL_ALBUM',
            releasesCollection: 'RELEASES_COLLECTION',
            afterActionDialogNeedLogin: 'AFTER_ACTION_DIALOG_NEED_LOGIN'
        }),
    },
    methods: {
        ...mapActions(['ADD_ALBUM_TO_FAVORITES']),

        // addLikeAlbumFromCollections(albumID) {
        //     if (albumID) {
        //         for (let collection of this.releasesCollection) {
        //             let getter = this.$store.getters[collection];
        //             if (getter && getter.length) {
        //                 let albumIndex = getter.findIndex((album) => +album.id === albumID);
        //                 if (albumIndex !== -1) {
        //                     getter[albumIndex].isFavorite = true;
        //                     if (getter[albumIndex].playList && getter[albumIndex].playList.length) {
        //                         for (let i = 0; i < getter[albumIndex].playList.length; i++) {
        //                             getter[albumIndex].playList[i].song.isFavorite = true;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     } else {
        //         console.log('addLikeAlbumFromCollections need music release id', albumID);
        //     }
        // },

        addAlbumToFavorites(album) {
            if (this.authData.accessToken) {
                let albumID = album.id;
                this.markAlbumAsSelected(album);
                this.sendSelectedAlbumsToServer(albumID);
            } else {
                this.markAlbumAsSelected(album);
                localStorage.setItem('add_album_to_favorites', album.id);
                this.needLoginDialog = {
                    visible: true,
                    action: 'add_album_to_favorites'
                };
            }
        },
        markAlbumAsSelected(album) {
            let albumID = album.id;


            album.isFavorite = true;
            if (album.playList && album.playList.length) {
                for (let i = 0; i < album.playList.length; i++) {
                    album.playList[i].song.isFavorite = true;
                }
            }
            if (this.fullAlbum && this.fullAlbum.id === albumID) {
                this.fullAlbum.isFavorite = true;
                if (this.fullAlbum.playList && this.fullAlbum.playList.length) {
                    for (let i = 0; i < this.fullAlbum.playList.length; i++) {
                        this.fullAlbum.playList[i].song.isFavorite = true;
                    }
                }
            }

            if (this.currentAlbum && this.currentAlbum.id === albumID) {
                this.currentAlbum.isFavorite = true;

                this.currentTrack.isFavorite = true;
                for (let i = 0; i < this.currentAlbum.playList.length; i++) {
                    this.currentAlbum.playList[i].song.isFavorite = true;
                }
            }
        },
        sendSelectedAlbumsToServer(albumID) {
            this.$store.dispatch('SET_PLAYED_ONLY_FAV_TRACKS', false);
            this.$store.commit('SET_REFRESH_RELEASES', true);

            this.ADD_ALBUM_TO_FAVORITES({'music_release_id': albumID})
                .catch(err => console.log(`addAlbumToFavorites, ${err}`));
        }
    }
}