import axios from 'axios';

const initialState = () => ({
    basketList: [],
    updateBasket: false,
    loaderBasket: false,
    purchaseClientSecret: '',
    countItems: 0,
    updateBasketCounter: false,
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_BASKET_LIST: (state, data) => {
            state.basketList = data;
        },
        SET_UPDATE_BASKET: (state, data) => {
            state.updateBasket = data;
        },
        SET_LOADER_BASKET: (state, data) => {
            state.loaderBasket = data;
        },
        SET_PURCHASE_CLIENT_SECRET: (state, data) => {
            state.purchaseClientSecret = data;
        },
        SET_BASKET_COUNT_ITEMS: (state, data) => {
            state.countItems = data;
        },
        SET_UPDATE_BASKET_COUNTER: (state, data) => {
            state.updateBasketCounter = data;
        },
    },
    actions: {
        // params: {productId: Int, type: 'music_release' || 'song'}
        ADD_TO_BASKET({ commit }, data) {
            return axios.post(`cart/add-product`, data)
                .then(() => {
                    commit('SET_UPDATE_BASKET_COUNTER', true)
                })
                .catch(err => console.error(`ADD_TO_BASKET ${err}`));
        },
        REMOVE_FROM_BASKET({ commit, getters}, data) {
            return axios.delete(`cart/remove-product`, {data: data})
                .then(() => {
                    for (let collection of this.getters['RELEASES_COLLECTION']) {
                        let getter = this.getters[collection];
                        if (getter && getter.length) {
                            let albumIndex = getter.findIndex((album) => +album.id === data.productId);
                            if (albumIndex !== -1) {
                                let album = getter[albumIndex];
                                album.inCart = false;
                            }
                        }
                    }
                    commit('SET_BASKET_COUNT_ITEMS', getters.BASKET_ITEMS_COUNTER - 1);
                })
                .catch(err => console.error(`REMOVE_FROM_BASKET ${err}`));
        },
        GET_BASKET({ commit }, params) {
            commit('SET_LOADER_BASKET', true);

            return axios.get(`cart/summary`, {params: params})
                .then(response => {
                    commit('SET_BASKET_LIST', response.data.result);
                    commit('SET_UPDATE_BASKET', false);
                })
                .catch(err => console.error(`GET_BASKET_LIST ${err}`))
                .finally(() => commit('SET_LOADER_BASKET', false));
        },
        GET_BASKET_COUNTER({ commit }) {
            commit('SET_LOADER_BASKET', true);

            return axios.get(`cart/summary/count`)
                .then(response => {
                    commit('SET_UPDATE_BASKET_COUNTER', false);
                    commit('SET_BASKET_COUNT_ITEMS', response.data.result);
                })
                .catch(err => console.error(`GET_BASKET_LIST ${err}`))
                .finally(() => commit('SET_LOADER_BASKET', false));
        },
        CLEAR_BASKET({ commit }) {
            return axios.delete(`cart/clear`)
                .then(response => {

                })
                .catch(err => console.error(`CLEAR_BASKET ${err}`));
        },
        GET_PURCHASE_CLIENT_SECRET({commit}) {
            return axios.post(`cart/purchase`)
                .then(response => {
                    console.log('GET_PURCHASE_CLIENT_SECRET', response.data.result);
                    commit('SET_PURCHASE_CLIENT_SECRET', response.data.result);
                })
                .catch(err => console.error(`PURCHASE ${err}`));
        }
    },
    getters: {
        BASKET_LIST: state => state.basketList,
        UPDATE_BASKET: state => state.updateBasket,
        LOADER_BASKET: state => state.loaderBasket,
        UPDATE_BASKET_COUNTER: state => state.updateBasketCounter,
        BASKET_ITEMS_COUNTER: state => state.countItems,
        PURCHASE_CLIENT_SECRET: state => state.purchaseClientSecret,
    }
}
