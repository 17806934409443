export default class SubscriptionM {
  id: number
  status: string
  canActivate: boolean
  checkPaymentMethod: boolean
  issue: { key: string; value: any }[];
  createdAt: Date
  updatedAt: Date
  currentPeriodEnd: Date
  currentPeriodStart: Date

  _prevStatus: string | null

  constructor (data: any) {
    this.id = data.id;
    this.status = data.status;
    this.canActivate = data.canActivate;
    this.checkPaymentMethod = data.checkPaymentMethod;
    this.issue = data.issue;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.currentPeriodEnd = data.currentPeriodEnd;
    this.currentPeriodStart = data.currentPeriodStart;

    this._prevStatus = this.status
  }

  isActive(): boolean {
    return this.status === 'active';
  }

  isCanceled(): boolean {
    return this.status === 'canceled';
  }

  isExpired(): boolean {
    return ['incomplete', 'incomplete_expired', 'unpaid'].includes(this.status)
  }

  isExpires(): boolean {
    return ['past_due'].includes(this.status)
  }

  expiredDate(): Date {
    if ("date" in this.issue && !isNaN(Date.parse(this.issue["date"]))) {
      return this.issue["date"];
    }

    return this.updatedAt;
  }

  currentPeriodIsEnded(): boolean {
    return this.currentPeriodEnd && new Date() > new Date(this.currentPeriodEnd);
  }

  hasChangeStatus(): boolean {
    return this.status !== this._prevStatus;
  }
}

