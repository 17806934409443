<template>
    <div>
        <!-- USER -->
        <v-menu v-if="!isAdmin && !isOwner"
                transition="slide-y-transition"
                :close-on-content-click="true"
                :nudge-width="120"
                offset-y
                nudge-left="60"
                nudge-bottom="15"
                origin="top center"
                content-class="menu-pointer charcoal">

            <template v-slot:activator="{ on, attrs }">
                <button type="button" v-ripple class="more_btn" v-bind="attrs" v-on="on"></button>
            </template>

            <v-list>
                <v-list-item v-ripple>
                    <button type="button">Add to queue</button>
                </v-list-item>
                <v-list-item v-ripple>
                    <button type="button">Play next</button>
                </v-list-item>
                <v-list-item v-ripple disabled>
                    <button type="button">Report</button>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- ADMIN & !OWNER & PENDING ALBUM -->
        <v-menu v-if="isAdmin && !isOwner && albumStatus === 'pending'"
                transition="slide-y-transition"
                :close-on-content-click="true"
                :nudge-width="120"
                offset-y
                nudge-left="60"
                nudge-bottom="15"
                origin="top center"
                content-class="">

            <template v-slot:activator="{ on, attrs }">
                <button type="button" v-ripple class="more_btn" v-bind="attrs" v-on="on"></button>
            </template>

            <v-list class="album_statuses">
                <!-- "draft", "pending", "rejected", "approved", "need_review" ] -->
                <v-list-item>
                    <button type="button" class="approve" @click="changeAlbumStatus(albumID, 'published')">Approve</button>
                </v-list-item>
                <v-list-item v-ripple>
                    <button type="button" class="rejected" @click="changeAlbumStatus(albumID, 'rejected')">Reject</button>
                </v-list-item>
                <v-list-item v-ripple>
                    <button type="button" class="requestReview" @click="changeAlbumStatus(albumID, 'need_review')">Request Review</button>
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- ADMIN || OWNER && ALBUM !=== PENDING -->
        <v-menu v-if="isOwner || isAdmin && albumStatus !== 'pending'"
                transition="slide-y-transition"
                :close-on-content-click="true"
                :nudge-width="120"
                offset-y
                nudge-left="60"
                nudge-bottom="10"
                origin="top center"
                content-class="">

            <template v-slot:activator="{ on, attrs }">
                <button type="button" v-ripple class="more_btn" v-bind="attrs" v-on="on"></button>
            </template>

            <v-list class="album_statuses">
                <!-- "draft", "pending", "rejected", "approved", "need_review" ] -->
                <v-list-item v-ripple>
                    <button type="button" class="edit" @click="edit(albumID)">Edit</button>
                </v-list-item>
                <v-list-item v-ripple v-if="albumStatus === 'published'">
                    <button type="button" class="un_publish" @click="changeAlbumStatus(albumID, 'draft')">Unpublish</button>
                </v-list-item>
<!--                <v-list-item v-ripple v-if="isAdmin && albumStatus === 'draft'">-->
<!--                    <button type="button" class="publish" @click="changeAlbumStatus(albumID, 'pending')">Publish</button>-->
<!--                </v-list-item>-->
<!--                <v-list-item v-ripple>-->
<!--                    <button type="button" class="delete" @click="deleteRelease(albumID)">Delete</button>-->
<!--                </v-list-item>-->
            </v-list>
        </v-menu>

        <deleteRelease v-if="modalRemoveRelease" v-model="modalRemoveRelease" :deleteRelease="prepareDeleteRelease"></deleteRelease>
        <comingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></comingSoonDialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import deleteRelease from "@/components/dialogs/deleteRelease.vue";
import comingSoonDialog from "@/components/dialogs/comingSoonDialog.vue";

export default {
    name: "albumActions",
    props: ['albumID', 'albumStatus', 'isOwner', 'albumLocation'],
    components: {
        deleteRelease,
        comingSoonDialog,
    },
    data() {
        return {
            modalRemoveRelease: false,
            prepareDeleteRelease: {},
            showComingSoonDialog: false,
        }
    },

    computed: {
        ...mapGetters(['isAdmin', 'AUTH_DATA', 'ALBUM', 'FULL_ALBUM']),
    },
    created() {
		console.log('isOwner', this.isOwner);
      // console.log('this.albumStatus', this.albumStatus);
    },

    methods: {
        ...mapActions(['CHANGE_ALBUM_STATUS', 'REMOVE_RELEASE_FROM_COLLECTIONS', 'CHANGE_ALBUM_STATUS_FROM_COLLECTIONS', 'GET_ALBUM']),

        changeAlbumStatus(albumID, newStatus) {
            // if (this.isAdmin) { // TODO: temp blocking
                //  [ "draft", "pending", "rejected", "approved", "need_review" ]
                if (this.AUTH_DATA.accessToken) {
                    let params = {
                        id: albumID,
                        status: newStatus,
                        // comment: !comment ? `album ${this.albumID} change status to pending` : comment,
                    }
                    if (newStatus !== 'draft' && newStatus !== 'pending') {
                        this.REMOVE_RELEASE_FROM_COLLECTIONS(albumID);
                    }

                    // UnPublish
                    if (newStatus === 'draft' || newStatus === 'pending'){
                        this.CHANGE_ALBUM_STATUS_FROM_COLLECTIONS([albumID, newStatus]);
                    }

					if (this.FULL_ALBUM && this.FULL_ALBUM.id === albumID) {
						this.FULL_ALBUM.status.value = newStatus;
					}

                    this.CHANGE_ALBUM_STATUS(params)
                        .catch(err => {
                            console.error(`albumActions, changeAlbumStatus, ${err}`);
                        })
                }
            // } else {
            //     this.showComingSoonDialog = true;
            // }
        },
        edit(albumID) {
	        this.$store.commit('SET_CURRENT_STEP', 1);
	        this.$router.push({name: 'createMusicRelease', params: {id: albumID, editRelease: true}});
            // if (this.isAdmin) { // TODO: temp blocking
            //     this.$store.commit('SET_CURRENT_STEP', 1);
	        //     this.$router.push({name: 'createMusicRelease', params: {id: albumID}});
            // } else {
            //     this.showComingSoonDialog = true;
            // }
        },
        deleteRelease(deleteReleaseID) {
            this.modalRemoveRelease = true;
            this.GET_ALBUM(deleteReleaseID)
                .then(() => {
                    this.prepareDeleteRelease = this.ALBUM;
                })
                .catch(err => console.error(`deleteRelease - GET ALBUM, ${err}`));
            // if (this.isAdmin) { // TODO: temp blocking
            //     this.modalRemoveRelease = true;
            //     this.GET_ALBUM(deleteReleaseID)
            //         .then(() => {
            //             this.prepareDeleteRelease = this.ALBUM;
            //         })
            //         .catch(err => console.error(`deleteRelease - GET ALBUM, ${err}`));
            // } else {
            //     this.showComingSoonDialog = true;
            // }
        },
    }
}
</script>

<style lang="scss">

</style>