<template>
  <div class="wrap_mobile_filter_bar" :class="{'full': filterName === 'explore'}" style="width: 100%" id="mobile-filter-bar-id" ref="filterBar">
    <div class="mobile_filter_wrap" style="overflow: scroll">
      <div class="mobile_filter_item" style="border-left: none; padding-left: 0;">
        <span class="top">Genre</span>
        <span class="bottom">Psychedelic Trance</span>
      </div>
      
      <SubgenreFilterCard
        v-model="SUBGENRE"
        :dialog.sync="subGenresDialog"
        :subgenres="SUBGENRES"
        :filterName="filterName"
        @select="handleSubgenreSelect"
      />

      <BpmFilterCard
        v-model="BPM"
        :dialog.sync="bpmDialog"
        @input="handleBpmChange"
      />

      <v-bottom-sheet :content-class="'sub_genres_wrap'" v-model="keysDialog">
        <template v-slot:activator="{ on }">
          <div class="mobile_filter_item" v-on="on">
            <span class="top">KEY</span>
            <span class="bottom">{{ KEYS.length > 0 ? KEYS.map(key => key.code).join(', ') : '-' }}</span>
          </div>
        </template>
        <v-sheet class="text-left subGenresDialog" @click.stop>
          <mobile-keys 
            v-model="keysDialog" 
            :filterName="filterName"
            @keys-applied="handleKeysApplied"
          ></mobile-keys>
        </v-sheet>
      </v-bottom-sheet>

      <div class="mobile_filter_item">
        <button 
          @click="clearFilter" 
          :disabled="!isAnyFilterApplied" 
          :class="['clear-btn', { 'disabled': !isAnyFilterApplied }]"
        >
          Clear Filter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import eventBus from "@/eventBus";
import mobileKeys from "@/components/keyFilterCard.vue";
import BpmFilterCard from '@/components/BpmFilterCard.vue';
import SubgenreFilterCard from '@/components/SubgenreFilterCard.vue';

export default {
  name: "albumsFilterMobile",
  components: {
    mobileKeys,
    BpmFilterCard,
    SubgenreFilterCard
  },
  props: ['actions', 'subgenreFilter', 'keysFilter', 'filterName', 'subgenreClear'],
  data() {
    return {
      GENRE: 'Psychedelic Trance',
      tags: [],
      newTag: '',
      actionsButtons: false,
      showTagsInput: false,
      filters: [],
      userID: null,
      dialogSaveFilterVisible: false,
      dialogNeedLogin: false,
      viewKeysFilter: false,
      bookmarkStatus: false,
      menuSaveFilterVisible: false,
      newFilterName: '',
      activeSavedFilter: '',
      deleteSavedFilterStatus: false,
      saveSavedFilterStatus: true,
      discovery: false,
      subGenresDialog: false,
      bpmDialog: false,
      keysDialog: false,
      activePath: null
    };
  },
  watch: {
    TAGS() {
      if (!this.TAGS.length) {
        this.tags = [];
      }
    }
  },
  computed: {
    ...mapGetters([
      'AUTH_DATA', 'PROFILE',
      'UPPER_FILTER',
      'GENRES', 'SUBGENRE', 'BPM', 'APPLY_KEYS', 'KEYS', 'TAGS',
      'CHANGE_FILTERS_STATUS', 'DISCOVERY',
      'STATUS_FILTER', 'ACTIVE_SAVED_FILTER', 'SAVED_FILTERS', 'SUBGENRES'
    ]),
    ...mapGetters({
      librarySubgenre: 'collection/SUBGENRE',
      libraryBPM: 'collection/BPM',
      libraryKeys: 'collection/KEYS',
      libraryTags: 'collection/TAGS',
    }),
    combinedKeys() {
      return this.KEYS.map(key => key.code).join(', ');
    },
    combinedLibraryKeys() {
      return this.libraryKeys.map(key => key.code).join(', ');
    },
    currentFilters() {
      return {
        subgenre: this.SUBGENRE,
        bpm: this.BPM,
        keys: this.KEYS,
        // Add any other filters you're using
      };
    },
    isAnyFilterApplied() {
      return (
        Object.keys(this.SUBGENRE).length > 0 ||
        this.BPM.length > 0 ||
        this.KEYS.length > 0 ||
        this.APPLY_KEYS.length > 0 ||
        this.TAGS.length > 0 ||
        !!this.ACTIVE_SAVED_FILTER
      );
    }
  },
  mounted() {
    if (!this.SUBGENRES.length && this.filterName === 'library') {
      this.getSubgenres();
    }

    if (this.filterName === 'explore') {
      if (this.TAGS.length) {
        this.tags = this.TAGS;
        this.$store.commit('SET_STATUS_FILTER', true);
      }
    }
    if (this.filterName === 'library') {
      if (this.libraryTags.length) {
        this.tags = this.libraryTags;
      }
    }
  },
  methods: {
    ...mapActions(['GET_PROFILE', 'CHANGE_FILTERS', 'UPDATE_DISCOVERY', 'GET_GENRES', 'GET_SUBGENRES', 'CLEAN_EXPLORE_GRID_ALBUMS', 'GET_TRACKS']),
    ...mapMutations([
      'SET_BPM',
      'SET_KEYS',
      'SET_APPLY_KEYS',
      'SET_TAGS',
      'SET_ACTIVE_SAVED_FILTER',
      'SET_TRACKS_CURRENT_PAGE',
      'SET_FAVORITE_TRACKS_CURRENT_PAGE',
      'SET_SUBGENRE',
      'SET_TRACK_SCROLL_ID',
      'SET_ALBUMS_CURRENT_PAGE',
      'SET_ALBUM_EXPANDED',
      'SET_GRID_ALBUM_SCROLL_ID'
    ]),
    readonly(evt) {
      evt.preventDefault();
    },

    getSubgenres() {
      const subgenresParams = {
        genre: 2, // Psychedelic Trance
        'order[musicReleasesCount]': 'DESC'
      }
      this.GET_SUBGENRES(subgenresParams);
    },

    handleSubgenreSelect(subgenre) {
      if (this.filterName === 'explore') {
        this.$store.dispatch('UPDATE_SUBGENRE', subgenre);
        if (subgenre.name) {
          const subgenreName = subgenre.name.trim().replace(/ /g, '-');
          this.$router.replace({path: `/explore/${subgenreName}`});
        } else {
          this.$router.push({path: '/'});
        }
      } else if (this.filterName === 'library') {
        this.$store.commit('collection/SET_SUBGENRE', subgenre);
      }
    },

    handleBpmChange(newBpm) {
      if (this.filterName === 'explore') {
        this.$store.commit('SET_BPM', newBpm);
      } else if (this.filterName === 'library') {
        this.$store.commit('collection/SET_BPM', newBpm);
      }
      this.$nextTick(() => {
        eventBus.emit('customEvent');
      });
    },

    handleKeysApplied(appliedKeys) {
      console.log('Keys applied, current filters:', this.currentFilters);
      if (this.filterName === 'explore' || this.filterName === 'library') {
        const updatedFilters = {
          ...this.currentFilters,
          keys: appliedKeys
        };
        this.GET_TRACKS(updatedFilters);
      }
      this.$nextTick(() => {
        eventBus.emit('customEvent');
      });
    },

    clearFilter() {
      if (this.isAnyFilterApplied) {
        this.handleSubgenreSelect({}); // Select "All" subgenres
        this.SET_BPM([]);
        this.SET_KEYS([]);
        this.SET_APPLY_KEYS([]);
        this.SET_TAGS([]);
        this.SET_ACTIVE_SAVED_FILTER('');
        this.SET_TRACKS_CURRENT_PAGE(1);
        this.SET_FAVORITE_TRACKS_CURRENT_PAGE(1);
        this.SET_TRACK_SCROLL_ID(null);
        
        // Additional resets from the original clearFilter method
        this.SET_ALBUMS_CURRENT_PAGE(1);
        this.SET_ALBUM_EXPANDED('');
        this.SET_GRID_ALBUM_SCROLL_ID(null);

        // Animate the scroll back to the initial position
        this.scrollToStart();

        // Optionally, you might want to trigger any necessary updates or re-fetches here
        this.$nextTick(() => {
          eventBus.emit('filtersCleared');
        });
      }
    },

    scrollToStart() {
      const filterBar = this.$refs.filterBar;
      if (filterBar) {
        filterBar.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      }
    },
  }
};
</script>

<style>
.wrap_mobile_filter_bar {
  width: 100%;
  overflow-x: scroll; /* Allows horizontal scrolling */
  overflow-y: hidden; /* Hides vertical scroll */
  -ms-overflow-style: none; /* Hides scrollbar in IE */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  margin: 20px 0 15px;
  border-bottom: 1px solid rgba(95, 105, 134, 0.5);
  padding-bottom: 15px;
}

.mobile_filter_wrap::-webkit-scrollbar {
  display: none;  /* Hides scrollbar in Safari and Chrome */
}

.mobile_filter_wrap {
  width: fit-content; /* Ensures the container shrinks to fit content */
  display: flex; /* Aligns filter items horizontally */
  justify-content: space-between; /* Distributes space between items */
}

.mobile_filter_item {
  width: calc((100vw - 40px) / 3); /* Adjusts item width for responsiveness */
  border-left: 1px solid #2E3548;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile_filter_item .top {
  color: #8591B1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile_filter_item .bottom {
  color: #FFF;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
}

.mobile_filter_item button.clear-btn {
  color: #fff;
  background-color: #39404c;
  width: fit-content;
  padding: 5px 30px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.mobile_filter_item button.clear-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Increase the base z-index for all overlays */
.v-overlay {
  z-index: 199 !important;
}

/* Set a high z-index for all dialogs */
.v-dialog,
.v-dialog__content {
  z-index: 200001 !important;
}

/* Add this to ensure the bottom sheet content is above the overlay */
.v-bottom-sheet.v-dialog.v-bottom-sheet--active {
  z-index: 200002 !important;
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .mobile_filter_item {
    width: calc((100vw - 40px) / 2) !important;
  }

  .clear-btn {
    color: #fff;
    background-color: #39404c;
    width: fit-content;
    padding: 5px 30px;
    border-radius: 20px;
  }
}
</style>
