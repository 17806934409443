
export default class SongKey {
  id = 0
  name = ''
  code = ''
  group = ''

  constructor (data) {
    if (data) {
      this.id = data.id;
      this.name = data.name.toUpperCase();
      this.code = data.code.toUpperCase();
      this.group = data.group.toUpperCase();
    }
  }
}

