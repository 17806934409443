import axios from 'axios';


const initialState = () => ({
    musicRelease: [],
    recentlyPlayed: [],
    appearsOn: [],
    countMusicRelease: '',
    albumsCurrentPage: 1,
    artistMusicReleases: [],
    playedMusicRelease: [],

    // Download music-release
    checkMusicReleaseDownload: '',
    downloadMusicReleaseLink: '',
    musicReleaseDownloadData: '',

    // checkCanPurchase
    checkCanPurchaseMusicRelease: null,
    checkCanPurchaseTrack: null
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        // GET_MUSIC_RELEASE
        SET_MUSIC_RELEASE: (state, data) => {
            state.countMusicRelease = data['hydra:totalItems'];
            state.musicRelease = data['hydra:member'];
        },
        SET_RECENTLY_PLAYED: (state, data) => {
            state.recentlyPlayed = data;
        },
        SET_APPEARS_ON: (state, data) => {
            state.appearsOn = data;
        },
        SET_ALBUMS_CURRENT_PAGE: (state, data) => {
            state.albumsCurrentPage = data;
        },

        SET_PLAYED_MUSIC_RELEASE: (state, data) => {
            state.playedMusicRelease = data;
        },

        // Download music-release
        SET_CHECK_MUSIC_RELEASE_DOWNLOAD: (state, data) => {
            state.checkMusicReleaseDownload = data;
        },
        SET_DOWNLOAD_MUSIC_RELEASE_LINK: (state, data) => {
            state.downloadMusicReleaseLink = data;
        },
        SET_MUSIC_RELEASE_DOWNLOAD_DATA: (state, data) => {
            state.musicReleaseDownloadData = data;
        },

        // checkCanPurchase
        SET_CHECK_CAN_PURCHASE_MUSIC_RELEASE: (state, data) => {
            state.checkCanPurchaseMusicRelease = data;
        },
        SET_CHECK_CAN_PURCHASE_TRACK: (state, data) => {
            state.checkCanPurchaseTrack = data;
        }
    },
    actions: {
        REMOVE_RELEASE_FROM_COLLECTIONS({commit}, albumID) {
            for (let collection of this.getters['RELEASES_COLLECTION']) {
                let getter = this.getters[collection];
                if (getter && getter.length) {
                    console.log('deleteAlbum music release getter', collection);
                    let albumIndex = getter.findIndex((album) => +album.id === albumID);
                    if (albumIndex !== -1) {
                        getter.splice(albumIndex, 1);
                    }
                }
            }
        },
        REMOVE_TRACK_FROM_COLLECTIONS({commit}, trackID) {
            for (let collection of this.getters['TRACKS_COLLECTION']) {
                let getter = this.getters[collection];
                if (getter && getter.length) {

                    let trackSongIndex = getter.findIndex((track) => +track.song.id === trackID);
                    let trackIndex = getter.findIndex((track) => +track.id === trackID);

                    if (trackIndex !== -1 ) {
                        getter.splice(trackIndex, 1);
                    }
                    if (trackSongIndex !== -1 ) {
                        getter.splice(trackSongIndex, 1);
                    }
                }
            }
        },
        CHANGE_ALBUM_STATUS_FROM_COLLECTIONS({commit}, [albumID, newStatus]) {
            for (let collection of this.getters['RELEASES_COLLECTION']) {
                let getter = this.getters[collection];
                if (getter && getter.length) {
                    console.log('change status music release getter', collection);
                    let albumIndex = getter.findIndex((album) => +album.id === albumID);
                    if (albumIndex !== -1) {
                        getter[albumIndex].status = newStatus;
                        // getter.splice(albumIndex, 1);
                    }
                }
            }
        },
        CLEAN_MUSIC_RELEASE({commit}) {
            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_MUSIC_RELEASE', empty);
        },
        GET_MUSIC_RELEASE({commit}, params) {
            return axios.get('music_releases', {params: params})
                .then(response => {
                    commit('SET_MUSIC_RELEASE', response.data);
                })
                .catch(error => console.log(`GET_MUSIC_RELEASE ${error}`));
        },
        DELETE_MUSIC_RELEASE({commit}, albumID) {
            this.dispatch('REMOVE_RELEASE_FROM_COLLECTIONS', albumID);
            return axios.delete(`music_releases/${albumID}`)
                .then(() => {

                })
                .catch(err => {
                    console.error(`DELETE_MUSIC_RELEASE ${err}`);
                })
        },
        GET_RECENTLY_PLAYED({commit}, params) {
            return axios.get(`music_releases`, {params: params})
                // return axios.get(`played_music_releases`, {params: params})
                .then(response => {
                    commit('SET_RECENTLY_PLAYED', response.data['hydra:member']);
                })
                .catch(err => console.log(`GET_RECENTLY_PLAYED ${err}`));
        },
        GET_APPEARS_ON({commit}, params) {
            return axios.get(`music_releases`, {params: params})
                .then(response => {
                    commit('SET_APPEARS_ON', response.data['hydra:member']);
                })
                .catch(err => console.log(`GET_APPEARS_ON ${err}`));
        },

        ADD_MUSIC_RELEASED_STATISTIC({commit}, params) {
            // Available values : downloaded, purchased, listened_to
            return axios.post(`add_music_release_statistic/${params.value}`, {'music_release_id': params.id})
                .then(response => {

                })
                .catch(err => {
                    console.log(`ADD_MUSIC_RELEASED_STATISTIC, ${err}`);
                })
        },

        INCREASE_PLAYED_MUSIC_RELEASE({commit}, params) {
            return axios.post(`increase_played_music_release`, {'musicReleaseID': params.musicReleaseID}).catch(err => {
                console.log(`INCREASE_PLAYED_MUSIC_RELEASE ${err}`);
            })
        },
        GET_PLAYED_MUSIC_RELEASE({commit}, params) {
            return axios.get(`played_music_releases`, {params: params})
                .then(response => {
                    commit('SET_PLAYED_MUSIC_RELEASE', response.data['hydra:member']);
                })
                .catch(err => {
                    console.log(`GET_PLAYED_MUSIC_RELEASE ${err}`)
                })
        },

        // Download music-release
        GET_CHECK_MUSIC_RELEASE_DOWNLOAD({commit}) {
            commit('SET_CHECK_MUSIC_RELEASE_DOWNLOAD', '');
            return axios.get(`music_releases/download-check`)
                .then(response => {
                    commit('SET_CHECK_MUSIC_RELEASE_DOWNLOAD', response.data.data);
                })
                .catch(err => console.error(`CHECK_MUSIC_RELEASE_DOWNLOAD, ${err}`))
        },
        GET_DOWNLOAD_MUSIC_RELEASE_LINK({commit}, musicReleaseID) {
            commit('SET_DOWNLOAD_MUSIC_RELEASE_LINK', '');
            return axios.get(`music_releases/${musicReleaseID}/download`)
                .then(response => {
                    commit('SET_DOWNLOAD_MUSIC_RELEASE_LINK', response.data.link);
                })
                .catch(err => console.error(`GET_DOWNLOAD_LINK, ${err}`))
        },

        // Check Can_Purchase music-release
        CHECK_CAN_PURCHASE_MUSIC_RELEASE({commit}, musicReleaseID) {
            return axios.get(`music_releases/${musicReleaseID}/can-purchase`)
                .then(response => {
                    commit('SET_CHECK_CAN_PURCHASE_MUSIC_RELEASE', response.data);
                    return response.data;
                })
                .catch(err => console.error(`CHECK_CAN_PURCHASE_MUSIC_RELEASE, ${err}`))
        },
        CHECK_CAN_PURCHASE_TRACK({commit}, trackID) {
            return axios.get(`songs/${trackID}/can-purchase`)
                .then(response => {
                    commit('SET_CHECK_CAN_PURCHASE_TRACK', response.data);
                })
                .catch(err => console.error(`CHECK_CAN_PURCHASE_TRACK, ${err}`))
        }

    },
    getters: {
        MUSIC_RELEASE: state => state.musicRelease,
        RECENTLY_PLAYED: state => state.recentlyPlayed,
        APPEARS_ON: state => state.appearsOn,
        COUNT_MUSIC_RELEASE: state => state.countMusicRelease,
        ALBUMS_CURRENT_PAGE: state => state.albumsCurrentPage,
        ARTIST_MUSIC_RELEASES: state => state.artistMusicReleases,
        PLAYED_MUSIC_RELEASE: state => state.playedMusicRelease,

        CHECK_MUSIC_RELEASE_DOWNLOAD: state => state.checkMusicReleaseDownload,
        DOWNLOAD_MUSIC_RELEASE_LINK: state => state.downloadMusicReleaseLink,
        MUSIC_RELEASE_DOWNLOAD_DATA: state => state.musicReleaseDownloadData,

        CAN_PURCHASE_MUSIC_RELEASE: state => state.checkCanPurchaseMusicRelease,
        CAN_PURCHASE_TRACK: state => state.checkCanPurchaseTrack
    }
}
